import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Common_Event_Hit } from "../../Actions/UserEvents";

function ResponseScreen({
  creditData,
  setchangeResponse,
  setShowReponseScreen,
}) {
  const navigate = useNavigate();

  const handleChangeResponse = () => {
    // call change response event API
    callApiEvents("change_response");
    setchangeResponse(true);
    setShowReponseScreen(false);
    // navigate('/apply-credit')
  };

  const handleGoBack = () => {
    // call go_back_tap event API
    callApiEvents("go_back_tap");
    // navigate home later
    navigate("/home");
  };

  //Event Api handler
  const callApiEvents = (eventName) => {
    let props = {
      eventName: eventName,
      message: creditData.message,
    };
    if (eventName === "credit_info_dialog") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "change_response") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "go_back_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  //onLoad set the respective screen name
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "credit_info_dialog");
    //call credit info dialog onLoad
    callApiEvents("credit_info_dialog");
  }, []);

  return (
    <>
      <div className="credit-reponse-parent-container font_ubuntu">
        <h5 className="status-info">{creditData.statusMessage}</h5>
        <div className="response-button-container">
          {creditData.editable && (
            <button
              type="button"
              className="response-button"
              onClick={handleChangeResponse}
            >
              Change Response
            </button>
          )}
          <button
            type="button"
            className="response-button"
            onClick={handleGoBack}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
}

export default ResponseScreen;
