import React, { useEffect } from "react";
import "../Sliders/Slider.css";
import "../HomePage/HomePage.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import ProductCard from "../ProductCard/ProductCard";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { useLocation, useNavigate } from "react-router";

const Slider3 = ({
  ProductRes,
  widgetData,
  footerColor,
  id,
  darkColor,
  lightColor,
  screen,
  shimmer,
}) => {
  const location = useLocation();
  let navigate = useNavigate();

  const rightSroll = () => {
    let scrollStep = 400;
    let divId = id ? id : "products_slider";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;
    let cw = scrollDiv.scrollWidth;

    if (sl + scrollStep >= cw) {
      scrollDiv.scrollTo(cw, 0);
    } else {
      scrollDiv.scrollTo(sl + scrollStep, 0);
    }
  };

  const leftScroll = () => {
    let scrollStep = 400;
    let divId = id ? id : "products_slider";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;

    if (sl - scrollStep <= 0) {
      scrollDiv.scrollTo(0, 0);
    } else {
      scrollDiv.scrollTo(sl - scrollStep, 0);
    }
  };

  useEffect(() => {
    // console.log({ ProductRes });
  }, [ProductRes, shimmer]);
  return (
    <div>
      <div id={id ? id : "products_slider"} className="products_slide_view">
        {shimmer && (
          <>
            {[...Array(5)].map((_, index) => {
              return (
                <div className="padding_5" key={index}>
                  <ProductCard shimmer={shimmer} />
                </div>
              );
            })}
          </>
        )}

        {!shimmer &&
          ProductRes &&
          ProductRes?.medicinesResponsesList?.length > 0 &&
          ProductRes?.medicinesResponsesList?.map((medData, index) => {
            return (
              <div className="padding_5" key={"p4_" + index}>
                <ProductCard
                  screen={screen}
                  medData={medData}
                  widgetData={widgetData}
                />
              </div>
            );
          })}
      </div>

      <div className="center-con slider_footer">
        <div className="center-con">
          <div className="round" onClick={leftScroll}>
            <div id="cta">
              <span className="arrowLeft primera next ">
                {" "}
                <FaChevronLeft
                  className="arrow_dark"
                  style={{ paddingRight: "5px", color: darkColor }}
                />
                &nbsp;&nbsp;&nbsp;
              </span>
              <span className="arrowLeft segunda next ">
                <FaChevronLeft
                  className="arrow_light"
                  style={{ color: lightColor }}
                />
              </span>
            </div>
          </div>
        </div>
        {ProductRes ? (
          <div
            className="arrow_dark font_ubuntu see_all_btn cursor_pointer font_weight_md"
            style={{ padding: "0px 60px", marginTop: "-2px", color: darkColor }}
            onClick={async () => {
              Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "see_more_tap",
                screen_name: screen,
              });
              if (location.pathname.split("/")[1] === "home")
                navigate(`/homeProducts/silvercrossTrending`);
              else navigate(`/products/silvercrossTrending`);
            }}
          >
            SEE ALL DETAILS
          </div>
        ) : (
          <div
            className="arrow_dark font_ubuntu see_all_btn cursor_pointer font_weight_md"
            style={{ padding: "0px 60px", marginTop: "-2px", color: darkColor }}
          ></div>
        )}

        <div iv className="center-con">
          <div className="round" onClick={rightSroll}>
            <div id="cta">
              <span className="arrow primera next ">
                {" "}
                <FaChevronRight
                  className="arrow_light"
                  style={{ paddingRight: "5px", color: lightColor }}
                />
                &nbsp;&nbsp;&nbsp;
              </span>
              <span className="arrow segunda next ">
                <FaChevronRight
                  className="arrow_dark"
                  style={{ color: darkColor }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;
