import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./RaiseIssue.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getRetailerCodesOnBehalf } from "../../../Actions/OnBehalf";
import { Box, Button, Chip, FormControl, IconButton } from "@mui/material";
import useDebounce from "../../../CustomHooks/useDebounce";
import { MdDelete } from "react-icons/md";
import cx from "classnames";
import { BiAddToQueue } from "react-icons/bi";
import {
  createIssue,
  getIssueTypes,
  searchByOrderId,
  sendMsg,
  uploadMultiImages,
} from "../../../Actions/Support";
import InputChange from "../../../CustomHooks/InputChange";
import { usersData } from "../../../App";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessBg from "../../../Images/svg/raise_issue_success.svg";
import { useNavigate } from "react-router";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const RaiseIssueModal = (props) => {
  const navigate = useNavigate();
  const issueTypesCheckList=["Gift Item Not Delivered","Expiry Settlement","Ledger Balance","Others"]
  const [retCodeInput, setRetailerCodeInput] = useState(null);
  const [retailerSuggestions, setRetailerSuggestions] = useState([]);
  const [selectedIssueType, setIssueType] = useState(null);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [description, setDescription] = useState(null);
  const { setErrorHandler, userData } = useContext(usersData);
  const [loader, setLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [issueTypes, setIssueTypes] = useState([]);
  const [orderId,setOrderId]=useState()
  const [invoiceNumber,setInvoiceNumber]=useState()
  const searchProducts = useDebounce(
    (value) => {
      const params = { searchKeyword: value };
      getRetailerCodesOnBehalf(params).then((res) => {
        setRetailerSuggestions(res.data.data);
      });
    },
    500,
    []
  );

  const issueTypeSelect=(event)=>{
    setIssueType(event.target.value)
  }
  const addImages = (event) => {
    setImages([...images, ...event.target.files]);
  };
  const deleteImage = (file) => {
    let index = images.findIndex((ele) => file.name === ele.name);
    if (index >= 0) {
      let data = [...images];
      data.splice(index, 1);
      setImages(data);
    }
  };

  const closeModal = (refresh = false) => {
    props.onHide(refresh);
  };

  const raiseIssue = () => {
    let errorList = [];
    !retCodeInput && errorList.push("retCode");
    !selectedIssueType && errorList.push("issueType");
    !description && errorList.push("description");
    !images.length && errorList.push("images");
    errorList.length && setErrors(errorList);
    if (!errorList.length) {
      setLoader(true);
      let body = new FormData();
      images.forEach((file) => {
        body.append("file", file);
      });
      uploadMultiImages(body)
        .then((res) => {
          const body = {
            attachmentLink: res.data.data,
            description: description,
            issueType: selectedIssueType,
            retailerCode: retCodeInput.retailerCode,
            retailerName: retCodeInput.shopName,
            source: "pharmacyWebOnbehalf",
            status: "Open",
            userId: userData.id,
            ...(orderId?.length && {invoiceNumber:invoiceNumber})
          };

          createIssue(body)
            .then((res) => {
              setLoader(false);
              setShowSuccess(true);
              // let msgbody = {
              //   attachement: false,
              //   conversation: description,
              //   identifier: "RETAILER",
              //   userId: userData.id,
              //   userIssueId: props.data.id,
              // };
              // sendMsg(msgbody)
            })
            .catch((error) => {
              setErrorHandler({
                show: true,
                screen: "create_issue_screen",
                errorList: [{ error: error, code: "" }],
                fn: function () {
                  createIssue(body);
                },
              });
            });
        })
        .catch((error) => {
          setErrorHandler({
            show: true,
            screen: "create_issue_screen",
            errorList: [{ error: error, code: "" }],
            fn: function () {
              uploadMultiImages(body);
            },
          });
        });
    }
  };

  const getIssueType = () => {
    getIssueTypes().then((res) => {
      let data=res.data.data
      let index=data.findIndex((item)=>item==='Ledger Updation')
      index>=0 && data.splice(index,1)
      setIssueTypes(data);
    });
  };


 const textAreaKeyup=(e)=>{
   if(e.keyCode===13){
    let text=description+"\r\n"
      setDescription(text)
   }
 }

 const searchByOrder=()=>{
  searchByOrderId({orderId:orderId}).then((res)=>{
      let data=res?.data?.data[0]
      setInvoiceNumber(data?.invoiceNumber)
      setRetailerCodeInput({enabled:true,retailerCode:data?.retailerCode,shopName:data?.shopName})
  }).catch((error) => {
    setRetailerCodeInput(null)
    setErrorHandler({
      show: true,
      screen: "create_issue_screen",
      errorList: [{ error: error, code: "" }],
      fn: function () {
        searchByOrder();
      },
    });
  });
 }
 
  useEffect(() => {
    getIssueType();
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
      backdrop="static"
    >
      <div className="text_center raise_issue_header">
        <h5 className="color_primary">
          {" "}
          <b>Raise Issue</b>
        </h5>
      </div>
      {!showSuccess ? (
        <>
          <div className="raise_issue_body">
          <h6>
              <u>
                {" "}
                <b>Select Issue Type</b>
              </u>
              {errors.includes("issueType") && (
                <b className="color_warn errorAnime font_12">
                  &nbsp; &nbsp; &nbsp; ( Please select issue type )
                </b>
              )}
            </h6>
            <div className="display_flex flex_wrap">
              {issueTypes.length &&
                (<FormControl required variant="standard" sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="issueTypeSelect">Select Issue Type</InputLabel>
                <Select
                  labelId="issueTypeSelect"
                  id=""
                  value={selectedIssueType}
                  onChange={issueTypeSelect}
                  label="Select Issue Type"
                >
                  {
                    issueTypes.map((type)=>{
                      return <MenuItem value={type}>{type}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
                )}
              {!issueTypes.length && (
                <Chip
                  className={cx("issueType_chip","animateShimmer")}
                  variant="outlined"
                  label="Loading..."

                />
              )}
            </div>
             <br></br>
            <h6>
              <u>
                {" "}
                <b>Retailer Details</b>
              </u>
            </h6>
            <br />
            <div className="display_flex flex_wrap space_btw align_center">
             <div className="display_flex align_center">
             <TextField
                required={!issueTypesCheckList.includes(selectedIssueType)}
                className="ret_order_id_inp"
                id="standard-basic"
                label="Order Id"
                variant="standard"
                onKeyUp={(event)=>{setOrderId(event.target.value);event?.keyCode===13 && searchByOrder()}}
              />
               <div className="orderSearchBtn">
                  <Button onClick={searchByOrder} disabled={!orderId?.length} variant="contained" size="small">
                     Search
                  </Button>
               </div>
             </div>
            
               
              <div>
                <Autocomplete
                  disabled={!issueTypesCheckList.includes(selectedIssueType)}
                  value={retCodeInput}
                  onChange={(event, newValue) => {
                    setRetailerCodeInput(newValue);
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={retailerSuggestions}
                  getOptionLabel={(option) => option.retailerCode}
                  size="small"
                  sx={{ width: 200 }}
                  onInputChange={(event, newInputValue) => {
                    searchProducts(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <div>
                        <div>{option.retailerCode}</div>
                        <div className="font_12 color_grey">
                          {option.shopName}
                        </div>
                      </div>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                     required
                      variant="filled"
                      {...params}
                      label="Enter Retailer Code"
                      error={errors.includes("retCode")}
                      helperText={
                        errors.includes("retCode") &&
                        "Select Retailer from list"
                      }
                    />
                  )}
                />
              </div>

              <TextField
                value={retCodeInput?.shopName || ""}
                disabled
                className="ret_shp_nm"
                id="standard-basic"
                label="Retailer Name"
                variant="standard"
              />
            </div>
           
            <br />
            <TextField
              required
              id="filled-multiline-static"
              label="Describe Issue"
              multiline
              rows={4}
              className="issue_desc_input"
              defaultValue=""
              variant="filled"
              value={description}
              onChange={(e) => InputChange(setDescription, e.target.value)}
              onKeyUp={textAreaKeyup}
              error={errors.includes("description")}
              helperText={
                errors.includes("description") && "Select Retailer from list"
              }
            />
            <br />
            <br />
            <div className="display_flex align_center">
              Attach Images{" "}
              <IconButton
                aria-label="upload picture"
                component="label"
                className="color_primary"
              >
                <input
                  hidden
                  multiple
                  onChange={addImages}
                  accept="image/*,.csv,.xlsx,.pdf"
                  type="file"
                />
                <BiAddToQueue />
              </IconButton>
              {errors.includes("images") && (
                <b className="color_warn errorAnime font_12">
                  &nbsp; &nbsp; &nbsp; ( Please attach atleast one image )
                </b>
              )}
            </div>
            <div className="display_flex align_center flex_wrap">
              {images.map((file) => {
                return (
                  <div
                    className="display_flex align_center brdr_grey"
                    style={{ marginRight: "10px" }}
                  >
                    <div className="issue_attach_image font_12">
                      {file.name}{" "}
                    </div>{" "}
                    <MdDelete
                      className="cursor_pointer"
                      onClick={() => deleteImage(file)}
                    />{" "}
                    &nbsp;
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text_center raise_issue_footer">
            <Button
              variant="outlined"
              onClick={()=>closeModal(false)}
              className="font_12"
              color="error"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="bg_primary send_btn_issue_details color_white font_12"
              onClick={raiseIssue}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress
                  className="color_secondary"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={cx("success_case")}>
            <br />
            <div>
              <h6 style={{ color: "#474747" }}>
                <b>Issue raised succesfully !</b>
              </h6>
              <br />
              <img src={SuccessBg} alt="issue image" />

              <div className="success_case_desc">
                Our team is on it and we'll keep you informed. <br />
                Thanks for bringing this to our attention !
              </div>
            </div>
          </div>
          <div className="text_center raise_issue_footer success_case">
            <Button
              variant="outlined"
              onClick={() => closeModal(true)}
              className="font_12"
              color="primary"
            >
              Go to Open Issues
            </Button>

            <Button
              variant="contained"
              className="bg_primary color_white font_12 no_wrap"
              onClick={() => navigate("/home")}
            >
              Back to Home
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default React.memo(RaiseIssueModal);
