import React, { Fragment, useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./categories.css";
import "../../App.css";
import DeafultLogo from "../../Images/svg/500_companies.svg";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { getCatgoriesData } from "../../Actions/Categories";
import { refreshToken } from "../../Actions/Auth";
import { Link } from "react-router-dom";
import { common_product_widget } from "../../Actions/Widgets";
import { usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";

const Companies = ({ getProductListHeader }) => {
  const [productListHeader, setproductListHeader] = useState("500+ Companies");
  const [companies, setCompanies] = useState();
  const [pathname, setPathname] = useState();
  const [shimmer, setShimmer] = useState(false);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);
  const sampleArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    let userData = localStorage.getItem("userData");

    if (userData && userData.length) {
      setShimmer(true);
      localStorage.setItem("currentScreen", "manufacturer_screen");

      if (location.pathname.split("/")[3] == "regularScheme") {
        setproductListHeader("Regular Schemes");
        setPathname("/companies/regularScheme");
        const getCompanies = () => {
          let api = common_product_widget(
            "home/v2/getPopularRegularSchemeCompanies",
            {}
          );
          api
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              console.log(error);
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      } else if (location.pathname.split("/")[3] == "surgical") {
        setproductListHeader("Surgical");
        setPathname("/companies/surgical");
        const getCompanies = () => {
          let api = common_product_widget(
            "home/v2/company/viewAllCompaniesByCategory",
            {
              category: "SURGICAL",
            }
          );
          api
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              console.log(error);
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      } else if (location.pathname.split("/")[3] == "generic") {
        setproductListHeader("Generic");
        setPathname("/companies/generic");
        const getCompanies = () => {
          let api = common_product_widget(
            "home/v2/company/viewAllCompaniesByCategory",
            {
              category: "GENERIC",
            }
          );
          api
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              console.log(error);
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      } else if (location.pathname.split("/")[3] == "ayurvedic") {
        setproductListHeader("Ayurvedic");
        setPathname("/companies/ayurvedic");
        const getCompanies = () => {
          let api = common_product_widget(
            "home/v2/company/viewAllCompaniesByCategory",
            {
              category: "AYURVEDIC",
            }
          );
          api
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              console.log(error);
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      } else if (location.pathname.split("/")[3] == "allopathy") {
        setproductListHeader("Allopathy");
        setPathname("/companies/allopathy");
        const getCompanies = () => {
          let api = common_product_widget(
            "home/v2/company/viewAllCompaniesByCategory",
            {
              category: "ALLOPATHY",
            }
          );
          api
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              console.log(error);
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      } else if (location.pathname.split("/")[3] == "speciality") {
        setproductListHeader("Speciality");
        setPathname("/companies/speciality");
        const getCompanies = () => {
          let api = common_product_widget(
            "home/v2/company/viewAllCompaniesByCategory",
            {
              category: "SPECIALTY",
            }
          );
          api
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              console.log(error);
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      } else {
        setPathname("/companies");
        localStorage.setItem("currentScreen", "company_screen");
        const params = {
          sortByVolume: true,
        };

        const getCompanies = () => {
          getCatgoriesData("COMPANIES", params)
            .then((res) => {
              setCompanies(res.data.data);
              setShimmer(false);
            })
            .catch((error) => {
              if (error?.response?.status == 401) {
                refreshToken().then((res) => {
                  getCompanies();
                });
              } else if (
                error?.response?.status == 502 ||
                error?.response?.status == 503
              ) {
                setShowMaintenance({ status: true, apiData: error?.response });
              } else if (error?.response?.status == 500) {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: "Oops something went wrong !",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  apiData: error?.response,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    getCompanies();
                  },
                });
              }
            });
        };

        getCompanies();
      }
    } else {
      navigate("/home");
    }
  }, []);
  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="companiesList">
        <Container className="companies_parent_tile">
          {productListHeader.length > 0 && (
            <Container fluid className="companyListHeader font_ubuntu">
              <HiOutlineArrowNarrowLeft
                className="cursor_pointer goBack"
                onClick={() => navigate(-1)}
              />
              &nbsp; {productListHeader} &nbsp; &nbsp;
              <span className="sub_header_companies">
                {companies && companies.length} Companies
              </span>
            </Container>
          )}
          <br></br>
          <Container className="companies_inner_div">
            {shimmer && (
              <>
                {sampleArray.map((item, i) => {
                  return (
                    <div
                      className="company_tile cursor_pointer animateShimmer"
                      key={i}
                    >
                      <div className="display_flex">
                        <img
                          src={DeafultLogo}
                          className="company_list_logo"
                          alt="🏥"
                        />
                        <div
                          className="font_ubuntu animateShimmer"
                          style={{
                            padding: "3px 20px 0px 20px",
                            width: "280px",
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {companies &&
              companies.map((company) => {
                return (
                  <div
                    onClick={async () => {
                      Common_Event_Hit("DYNAMIC_EVENT", {
                        eventName: "manufacturer_tap",
                        manufacturer: company.manufacturer,
                        screen_name: localStorage.getItem("currentScreen"),
                      });
                      navigate(`${pathname}/${company.manufacturer}`);
                    }}
                    className="text_decoration_none"
                  >
                    <div className="company_tile cursor_pointer">
                      <div className="display_flex">
                        <img
                          src={DeafultLogo}
                          className="company_list_logo"
                          alt="🏥"
                        />
                        <div
                          className="font_ubuntu"
                          style={{
                            padding: "3px 20px 0px 20px",
                            lineHeight: "8px",
                          }}
                        >
                          <h6 className="font_weight_lg">
                            {company.manufacturer}
                          </h6>

                          <p className="color_grey font_12">
                            <span className="font_weight_lg">
                              {company.noOfMedicine}
                            </span>{" "}
                            <span className="color_grey">SKU'S</span>{" "}
                          </p>
                        </div>
                      </div>
                      <FaChevronRight
                        className="color_secondary font_18 cursor_pointer"
                        style={{ marginTop: "4%" }}
                      />
                    </div>
                  </div>
                );
              })}
          </Container>
        </Container>
      </Container>
    </Fragment>
  );
};

export default React.memo(Companies);
