import React, { Fragment, useEffect, useState, useContext } from "react";
import "../HomePage/HomePage.css";
import {  Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DialyFlashSale from "./DialyFlashSale";
import Categories from "./Categories";
import DealOfDay from "./DealOfDay";
import MyAccount from "./MyAccount";
import { getScreen } from "../../Actions/Screens";
import { usersData, BannerData } from "../../App";
import {
  app_message_widget,
  app_section_widget,
  category_widget,
} from "../../Actions/Widgets";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import AcceptCreditAgreementModal from "../Modals/AcceptCreditAgreementModal";
import { creditOnboardingPopup } from "../../Actions/ApplyCredit";
import CommonWidget from "./CommonWidget";
import LandingBanner from "../Modals/LandingBanner";
import { GamificationBanner } from "./GamificationBanner";
import BannerWidget from "./BannerWidget";

const HomePage = ({ getCatgories }) => {
  const [widgets, setWidgets] = useState([]);
  const [renderStatus, setRenderStatus] = useState([]);
  const [appMessageRes, setAppMessageRes] = useState(null);
  const [bannerRes, setBannerRes] = useState(null);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [appSectionRes, setAppSectionRes] = useState(null);
  const [categoryRes, setCategoryRes] = useState(null);
  const [shimmerHomePage, setShimmerHomepage] = useState(false);
  const [shimmerBanner, setShimmerBanner] = useState(false);
  const [shimmerCategories, setShimmerCategories] = useState(false);
  const [shimmerMyAccount, setShimmerMyAccount] = useState(false);
  const { userData, setErrorHandler } = useContext(usersData);
  const { setBannerData } = useContext(BannerData);
  let navigate = useNavigate();
  const [agreementPopUpData, setAgreementPopUpData] = useState({});
  const [agreementPopUp, setAgreementPopUp] = useState({ show: false });
  const [upadateWidgets, setUpadateWidgets] = useState(false);
  const [renderWidgetData, setRenderWidgetData] = useState();
  
  const [landingModal, setLandingModal] = useState({
    show: false,
    content: "",
  });
  //credit onboard popup API call
  const getCreditUserOnboardingPopUp = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerId: data?.id,
      source: "home_screen",
    };
    creditOnboardingPopup(params, {}).then((res) => {
      setAgreementPopUpData(res?.data?.data);
      if (res?.data?.data?.showPopUp) setAgreementPopUp({ show: true });
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("userData");
    if (!JSON.parse(userData)?.businessType) {
      navigate("/onboarding");
      return;
    }
    if (JSON.parse(userData)?.businessType[0] !== "PHARMACY") {
      navigate("/wholesale/home");
      return;
    }
    localStorage.setItem("currentScreen", "home_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      //check credit status
      getCreditUserOnboardingPopUp();

      let data = JSON.parse(userData);
      // console.log("userData from HOMESCREEN", userData);
      // if (data?.businessType && data?.businessType[0] === "PHARMACY") {
      const params = {
        retailerId: JSON.parse(userData).id,
        screenName: "home_screen",
      };
      setShimmerHomepage(true);
      let home_screen = () =>
        getScreen("HOME_SCREEN", params, {})
          .then((res) => {
            setShimmerHomepage(false);
            setWidgets(res.data.data);
          })
          .catch((error) => {
            setErrorHandler({
              show: true,
              screen: "home_screen",

              errorList: [{ error: error, code: "" }],
              fn: function () {
                home_screen();
              },
            });
          });
      home_screen();
      // } else {
      //   navigate("/onboarding");
      // }
    } else {
      navigate("/login");
    }
  }, []);

  const getWidgetsData = () => {
    let userData = localStorage.getItem("userData");
    let errorList = [];
    if (widgets && widgets.length > 0 && userData) {
      console.log(widgets);
      setErrorModal({ show: false, content: "" });
      let promises = widgets.map((widget, index) => {
        if (widget.widgetName === "app_message_widget" && !appMessageRes) {
          let obj;
          obj = app_message_widget(widget.data.apiEndPoint);
          obj
            .then((res) => {
              setAppMessageRes(res.data);
              renderWidget(widget, index, false, null);
            })
            .catch((error) => {
              errorList.push(obj);
              renderWidget(widget, index, true, error);
            });

          return obj;
        } else if (
          widget.widgetName === "app_section_widget" &&
          !appSectionRes
        ) {
          console.log(appSectionRes);
          let obj;
          setShimmerCategories(true);
          obj = app_section_widget(widget.data.apiEndPoint);
          obj
            .then((res) => {
              setAppSectionRes(res.data.data.appSectionList);
              getCatgories(res.data.data.appSectionList);

              setShimmerCategories(false);
              renderWidget(widget, index, false, null);
            })
            .catch((error) => {
              errorList.push(obj);
              renderWidget(widget, index, true, error);
            });

          return obj;
        } else if (widget.widgetName === "category_widget" && !categoryRes) {
          console.log(categoryRes);
          let obj;
          setShimmerMyAccount(true);
          obj = category_widget(
            widget.data.apiEndPoint,
            JSON.parse(localStorage.getItem("userData")).id
          );

          obj
            .then((res) => {
              console.log({ res });
              setCategoryRes(res.data);

              setShimmerMyAccount(false);
              renderWidget(widget, index, false, null);
            })
            .catch((error) => {
              errorList.push(obj);
              renderWidget(widget, index, true, error);
            });

          return obj;
        }
      });

      function getData(promisesArray) {
        Promise.all(promisesArray)
          .then((values) => {})
          .catch((error) => {});
      }

      getData(promises);
    }
  };

  // setting rendered widget data in state
  const renderWidget = (widget, widgetIndex, errorStatus, error) => {
    setRenderWidgetData({ widget, widgetIndex, errorStatus, error });
    // setW;
  };

  // on dependency of renderedwidget state , pushing data into render status array
  useEffect(() => {
    if (widgets?.length) {
      let arr = [...renderStatus];
      arr.push(renderWidgetData);

      setRenderStatus(arr);
    }
  }, [renderWidgetData]);

  //  on error tryagain to hit same error widgets again
  const reRenderWidgets = () => {
    let widgetsData = widgets;
    let arr = [...renderStatus];
    let sliceIndexes = [];
    renderStatus.forEach((data, i) => {
      if (data?.errorStatus) {
        widgetsData[data.widgetIndex].renderStatus =
          !widgetsData[data.widgetIndex].renderStatus;
        sliceIndexes.push(i);
      } else {
        delete widgetsData[data.widgetIndex].renderStatus;
      }
    });
    arr = arr.filter((data, i) => !sliceIndexes.includes(i));
    setRenderStatus(arr);
    setWidgets(widgetsData);
    setUpadateWidgets(!upadateWidgets);
  };

  useEffect(() => {
    getWidgetsData();
  }, [widgets, upadateWidgets]);

  //  if all widgets rendered then checking for any error widget
  useEffect(() => {
    if (renderStatus.length === widgets.length - 1) {
      let errorList = [];
      let errorState = false;
      for (let i = 0; i < renderStatus.length; i++) {
        if (renderStatus[i].errorStatus) {
          errorState = true;
          errorList.push({
            error: renderStatus[i].error,
            code: renderStatus[i]?.widget?.data?.apiIdentifier,
          });
        }
      }
      if (errorState) {
        setErrorHandler({
          show: true,
          screen: "home_screen",
          error: "",
          errorList: errorList,
          fn: function () {
            reRenderWidgets();
          },
        });
      }
    }
  }, [renderStatus]);

  useEffect(() => {
    // landing page banner logic for 24 hrs
    let timeRecord = localStorage.getItem("UPIBanner");
    if (!timeRecord) {
      setLandingModal({ show: true, content: "" });
      let date = new Date().getTime();
      localStorage.setItem("UPIBanner", date);
    } else {
      let currDate = new Date().getTime();
      let timeDiff = currDate - timeRecord;
      if (timeDiff > 86400000) {
        setLandingModal({ show: true, content: "" });
        localStorage.setItem("UPIBanner", currDate);
      }
    }

    // console.log(userData,userData?.onlinePaymentEnabled)
  }, []);
  return (
    <Fragment>
      <LandingBanner
        show={landingModal.show && userData?.onlinePaymentEnabled}
        onHide={() => {
          setLandingModal({ show: false, content: "" });
        }}
      />
      <AcceptCreditAgreementModal
        show={agreementPopUp.show}
        creditUserData={agreementPopUpData}
        onHide={() => {
          setAgreementPopUp({ show: false });
        }}
      />
      {shimmerHomePage ? (
        <div className="homepage">
          {[...Array(5)].map((_, index) => {
            return (
              <Container>
                <div className="skeletal_div animateShimmer"></div>
              </Container>
            );
          })}
        </div>
      ) : userData ? (
        <div className={"homepage"}>
          {widgets &&
            widgets.map((widget, index) => {
              return widget.widgetName === "app_message_widget" ? (
                <></>
              ) :  (
                <Fragment>
                  {/* Gamification Banner */}
                  
                  {widget.widgetName === "banner_widget" && (
                    <BannerWidget
                      key={widget?.data?.title+index}
                      renderWidget={renderWidget}
                      widgetIndex={index}
                      widgetData={widget}
                    />
                  )}
                  {widget.widgetName === "gamification_banner_widget" && (
                    <GamificationBanner />
                  )}

                  {widget.widgetName === "timer_product_widget" && (
                    <DialyFlashSale
                      widgetIndex={index}
                      renderWidget={renderWidget}
                      widgetData={widget}
                      key={widget.widgetName+index}
                    />
                  )}

                  {widget.widgetName === "timer_product_widget_two" && (
                    <DealOfDay
                      widgetIndex={index}
                      renderWidget={renderWidget}
                      key={widget.widgetName+index}
                      widgetData={widget}
                    />
                  )}

                  {!shimmerCategories &&
                    widget.widgetName === "app_section_widget" &&
                    appSectionRes &&
                    appSectionRes.length > 0 && (
                      <Categories appSectionRes={appSectionRes} key={widget.widgetName+index} />
                    )}

                  {shimmerCategories &&
                    widget.widgetName === "app_section_widget" && (
                      <Categories shimmer={shimmerCategories} key={widget.widgetName+index} />
                    )}

                  {widget.widgetName === "product_list_widget" && (
                    <CommonWidget
                      key={widget?.data?.title+index}
                      renderWidget={renderWidget}
                      widgetIndex={index}
                      widgetData={widget}
                    />
                  )}

                  {widget.widgetName === "category_widget" &&
                    categoryRes &&
                    categoryRes.length !== 0 && (
                      <MyAccount key={widget.widgetName+index}  categoryRes={categoryRes} />
                    )}
                </Fragment>
              );
            })}

          {/* shimmer divs  */}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default React.memo(HomePage);
