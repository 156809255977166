import axios from "axios";
import { variables } from "../environments";


let config = {
    headers: {
      "Content-Type": "application/json",
      "source":"pharmacyWeb",
      appVersionCode: variables.appVersionCode,
  
    },
  };
  const EagleUrl = variables.eagleUrl;


  export const getSuggestions = async (body) => {
      let headers = JSON.parse(JSON.stringify(config.headers))
      headers.locale = "en";
      return axios.post(`${EagleUrl}/chatGpt/chat`, body, {
        headers: headers,
      });
  };

  export const translateText = async (body) => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    return axios.post(`${EagleUrl}/chatGpt/translate`, body, {
      headers: headers,
    });
}

    export const textToSpeech = async (body) => {
        let headers = JSON.parse(JSON.stringify(config.headers))
        return axios.post(`${EagleUrl}/chatGpt/tts`, body, {
          headers: headers,
        });  
    }
