import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,

  },
};
const ADMIN_URL = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;

  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="pharmacyWebOnbehalf"
  }
  return true;
};

export const getCatgoriesData = async (type, params) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "COMPANIES":
        return axios.get(`${ADMIN_URL}/home/company/viewAllCompanies`, {
          params: params,
          headers: config.headers,
        });
      case "SPECIAL_OFFERS_SKU_COUNT":
        return axios.get(`${ADMIN_URL}/home/v2/getSpecialOfferLiveSKUCount`, {
          params: params,
          headers: config.headers,
        });
      case "REGULAR_SCHEME_SKU_COUNT":
        return axios.get(`${ADMIN_URL}/home/v2/getRegularSchemeLiveSKUCount`, {
          params: params,
          headers: config.headers,
        });
      case "CORONA_SKU_COUNT":
        return axios.get(`${ADMIN_URL}/home/v2/getCoronaLiveSKUCount`, {
          params: params,
          headers: config.headers,
        });
      case "CATEGORY_SKU_COUNT":
        return axios.get(
          `${ADMIN_URL}/home/v2/getMedicineByCategoryLiveSKUCount`,
          {
            params: params,
            headers: config.headers,
          }
        );
    }
  });
  return widgets;
};
