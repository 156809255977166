import { high_margin_substitute_products } from "../../Actions/Widgets";
import { composition_products } from "../../Actions/Widgets";

export const navWidget = [{
    screenName: "High Margin Substitute",
    subHeading: "GENERICS SUBSTITUTE FOR ",
    pageId: "substitute",
    api: "/home/getHighMarginAlternative/productPage",
    apiService: high_margin_substitute_products
  },{
    screenName: "Substitute Products",
    pageId: "composition",
    api: "/home/getCompositionProducts",
    apiService: composition_products
  },{
    screenName: "Deals SE Products",
    pageId: "nearExpiry",
    api: "home/getNearExpiryProducts",
    params:[{key:'searchKey',value:'searchString'}],
    apiService: composition_products
  }]