import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Carousel, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "../../App.css";
import "../ProductList/ProductList.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./categories.css";
import CompaniesSlider from "../Sliders/CompaniesSlider";
import Slider from "../Sliders/Slider";
import { getScreen } from "../../Actions/Screens";
import { refreshToken } from "../../Actions/Auth";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCatgoriesData } from "../../Actions/Categories";
import { BannerData, usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import BannerSlider from "../BannerRedirects/BannerSlider";

const Speciality = ({ getProductListHeader }) => {
  let navigate = useNavigate();
  const [widgetsData, setWidgetsData] = useState();
  const [offersWidget, setOffersWidget] = useState();
  const [bannerWidget, setBannerWidget] = useState();
  const [productList, setProductList] = useState();
  const [productListNew, setProductListNew] = useState();
  const [bannerImages, setBannerImages] = useState([]);

  const [allProductList, setAllProductList] = useState();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [manufacturers, setManufacturers] = useState();
  const [dataLength, setDataLength] = useState(0);
  const [skuData, setSkuData] = useState();
  const { setBannerData } = useContext(BannerData);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);
  const [homeShimmer, setHomeShimmer] = useState(false);
  const [manufShimmer, setManfShimmer] = useState(false);
  const [topSellShimmer, setTopSellShimmer] = useState(false);
  const [newlyAddShimmer, setNewlyAddShimmer] = useState(false);
  const [allShimmer, setAllShimer] = useState(false);
  const getAllProducts = () => {
    const params = {
      category: "SPECIALITY",
      filterCondition: "all",
      page: page,
    };
    let api = common_product_widget("home/v2/getMedicineByCategory", params);
    api
      .then((res) => {
        let obj =
          allProductList && allProductList.medicinesResponsesList
            ? allProductList
            : { medicinesResponsesList: [] };
        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(
            ...res.data.data.medicinesResponsesList
          );
          setDataLength(obj.medicinesResponsesList.length);
        }

        if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
          fetchMoreData();
        } else if (
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length === 0
        ) {
          fetchMoreData();
        }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }
        setAllProductList(obj);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getAllProducts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getAllProducts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getAllProducts();
            },
          });
        }
      });
  };

  const fetchMoreData = () => {
    setPage(+page + 1);
  };
  const getSkuCount = () => {
    getCatgoriesData("CATEGORY_SKU_COUNT", { category: "SPECIALTY" })
      .then((res) => {
        setSkuData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getSkuCount();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getSkuCount();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getSkuCount();
            },
          });
        }
      });
  };
  const homeBannerSlide = (event) => {
    localStorage.setItem(
      "currentScreenBannerData",
      JSON.stringify({ index: +event + 1, data: bannerWidget[event] })
    );
  };
  useEffect(() => {
    // /home/scheme
    // regular_scheme_screen

    let userData = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "specialty_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      setHomeShimmer(true);
      const params = {
        retailerId: JSON.parse(userData).id,
        screenName: "specialty_screen",
      };

      let schemes_screen = () => {
        getScreen("HOME_SCREEN", params, {})
          .then((res) => {
            setWidgetsData(res.data.data);
            setHomeShimmer(false);
            res.data.data.forEach((item) => {
              let getWidgetData = () => {
                let params;
                if (item.widgetName === "banner_widget") {
                  params = {
                    pageId: "speciality",
                  };
                } else if (
                  item.widgetName === "product_list_widget" &&
                  item.data.pageId === "specialtyTrending"
                ) {
                  params = {
                    category: "SPECIALTY",
                    filterCondition: "popular",
                    page: 0,
                  };
                  setTopSellShimmer(true);
                } else if (
                  item.widgetName === "product_list_widget" &&
                  item.data.pageId === "specialtyNewlyAdded"
                ) {
                  params = {
                    category: "SPECIALTY",
                    filterCondition: "new",
                    page: 0,
                  };
                  setNewlyAddShimmer(true);
                } else if (item.widgetName === "manufacturer_list_widget") {
                  params = {
                    category: "SPECIALTY",
                  };
                  setManfShimmer(true);
                }

                if (
                  item &&
                  item.widgetName !== "vertical_product_list_widget"
                ) {
                  let api = common_product_widget(
                    item.data.apiEndPoint,
                    params
                  );
                  api
                    .then((res) => {
                      if (item.widgetName === "offer_category_widget") {
                        setOffersWidget(res.data.data);
                      } else if (item.widgetName === "banner_widget") {
                        setBannerWidget(res.data.data);
                        localStorage.setItem(
                          "currentScreenBannerData",
                          JSON.stringify({ index: 1, data: res.data.data[0] })
                        );
                        let arr = [];
                        res.data.data.forEach((ele, i) => {
                          arr.push(
                            <img
                              onClick={async () => {
                                Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "banner_tapped",
                                  screen_name:
                                    localStorage.getItem("currentScreen"),
                                });
                                setBannerData(ele);
                                navigate("/bannerRedirect");
                              }}
                              className="banner_img"
                              src={ele.url}
                              alt={i}
                            />
                          );
                        });
                        setBannerImages(arr);
                      } else if (
                        item.widgetName === "product_list_widget" &&
                        item.data.pageId === "specialtyTrending"
                      ) {
                        setProductList(res.data.data);
                        setTopSellShimmer(false);
                      } else if (
                        item.widgetName === "product_list_widget" &&
                        item.data.pageId === "specialtyNewlyAdded"
                      ) {
                        setProductListNew(res.data.data);
                        setNewlyAddShimmer(false);
                      } else if (
                        item.widgetName === "manufacturer_list_widget"
                      ) {
                        setManufacturers(res.data.data);
                        setManfShimmer(false);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      if (error?.response?.status === 401) {
                        refreshToken().then((res) => {
                          getWidgetData();
                        });
                      } else if (
                        error?.response?.status === 502 ||
                        error?.response?.status === 503
                      ) {
                        setShowMaintenance({
                          status: true,
                          apiData: error?.response,
                        });
                      } else if (error?.response?.status === 500) {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: "Oops something went wrong !",
                          tryAgain: function () {
                            getWidgetData();
                          },
                        });
                      } else {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: error?.response?.data?.error?.error
                            ? error.response.data.error.error
                            : "Something went wrong!",
                          tryAgain: function () {
                            getWidgetData();
                          },
                        });
                      }
                    });
                }
              };

              return getWidgetData();
            });
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken()
                .then((res) => {
                  schemes_screen();
                })
                .catch((error) => {
                  localStorage.removeItem("userData");
                  localStorage.removeItem("userAuth");
                  navigate("/login");
                });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  schemes_screen();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  schemes_screen();
                },
              });
            }
          });
      };
      schemes_screen();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    widgetsData && getSkuCount();
  }, [widgetsData]);
  useEffect(() => {
    widgetsData && getAllProducts();
  }, [page, widgetsData]);

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <Container fluid className="productList">
        <Container fluid className="productListHeader font_ubuntu display_flex">
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate("/home")}
          />{" "}
          &nbsp;&nbsp;&nbsp;{" "}
          <div className="head_specialOffers">
            Speciality
            {skuData && (
              <span className="sub_head">
                {skuData.companyCount} Companies • {skuData.skuCount} SKU'S
              </span>
            )}
          </div>
        </Container>
        {homeShimmer && (
          <Container>
            <Fragment>
              <Container>
                <div className="skeletal_div animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div animateShimmer"></div>
              </Container>
            </Fragment>
          </Container>
        )}
        <Container>
          {widgetsData &&
            widgetsData.map((widget, i) => {
              if (widget.widgetName === "manufacturer_list_widget") {
                return (
                  <Container key={i}>
                    {manufShimmer && (
                      <Fragment>
                        <p
                          className=" font_18 font_weight_md font_ubuntu"
                          style={{ color: widget.data.titleColor }}
                        >
                          {widget.data.title}
                        </p>
                        <CompaniesSlider
                          shimmer={manufShimmer}
                          data={manufacturers}
                          scheme="allopathy"
                          getProductListHeader={getProductListHeader}
                          footerColor={"#F4F6F8"}
                          id={"trendingProducts"}
                          darkColor={"#4B4495"}
                          lightColor={"#a49cf598"}
                        />
                      </Fragment>
                    )}
                    {manufacturers && manufacturers.length > 0 && (
                      <Fragment>
                        <p className="color_grey font_18 font_weight_md font_ubuntu">
                          {widget.data.title}
                        </p>
                        <CompaniesSlider
                          data={manufacturers}
                          scheme="speciality"
                          getProductListHeader={getProductListHeader}
                          footerColor={"#F4F6F8"}
                          id={"trendingProducts"}
                          darkColor={"#4B4495"}
                          lightColor={"#a49cf598"}
                        />
                      </Fragment>
                    )}
                  </Container>
                );
              } else if (widget.widgetName === "banner_widget") {
                return (
                  bannerWidget?.length > 0 && (
                    <>
                      <br></br>
                      <Container fluid className="web_carousel">
                        <div className="banner_section">
                          <BannerSlider
                            slides={bannerImages}
                            slideChange={homeBannerSlide}
                          />
                        </div>
                      </Container>

                      <Carousel
                        className="banner_section mobile_carousel"
                        onSlide={homeBannerSlide}
                      >
                        {bannerWidget.map((ele, ind) => (
                          <Carousel.Item key={ind}>
                            <div
                              onClick={async () => {
                                Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "banner_tapped",
                                  screen_name:
                                    localStorage.getItem("currentScreen"),
                                });
                                setBannerData(ele);
                                navigate("/bannerRedirect");
                              }}
                            >
                              <img
                                className="d-block banner_img"
                                src={ele.url}
                                alt={ele.fileName}
                              />
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </>
                  )
                );
              } else if (
                widget.widgetName === "product_list_widget" &&
                widget.data.pageId === "specialtyTrending"
              ) {
                return (
                  <>
                    {topSellShimmer && (
                      <Container>
                        <br></br>
                        <p
                          className="font_18 font_weight_md font_ubuntu"
                          style={{ color: widget.data.titleColor }}
                        >
                          {widget.data.title}
                        </p>
                        <Slider
                          shimmer={topSellShimmer}
                          screen={"allopathy_screen"}
                          widgetData={widget}
                          ProductRes={productList}
                          footerColor={"#F4F6F8"}
                          id={"allopathyPopular"}
                          darkColor={"#4B4495"}
                          lightColor={"#a49cf598"}
                        />
                      </Container>
                    )}
                    {productList &&
                      productList.medicinesResponsesList.length > 0 && (
                        <Container>
                          <br></br>
                          <p className="color_grey font_18 font_weight_md font_ubuntu">
                            {widget.data.title}
                          </p>
                          <Slider
                            screen={"specialty_screen"}
                            widgetData={widget}
                            ProductRes={productList}
                            footerColor={"#F4F6F8"}
                            id={"specialityPopular"}
                            darkColor={"#4B4495"}
                            lightColor={"#a49cf598"}
                          />
                        </Container>
                      )}
                  </>
                );
              } else if (
                widget.widgetName === "product_list_widget" &&
                widget.data.pageId === "specialtyNewlyAdded"
              ) {
                return (
                  <>
                    {newlyAddShimmer && (
                      <Container>
                        <br></br>
                        <p
                          className="font_18 font_weight_md font_ubuntu"
                          style={{ color: widget.data.titleColor }}
                        >
                          {widget.data.title}
                        </p>
                        <Slider
                          shimmer={newlyAddShimmer}
                          screen={"allopathy_screen"}
                          widgetData={widget}
                          ProductRes={productListNew}
                          footerColor={"#F4F6F8"}
                          id={"allopathyNew"}
                          darkColor={"#4B4495"}
                          lightColor={"#a49cf598"}
                        />
                      </Container>
                    )}
                    {productListNew &&
                      productListNew.medicinesResponsesList.length > 0 && (
                        <Container>
                          <br></br>
                          <p className="color_grey font_18 font_weight_md font_ubuntu">
                            {widget.data.title}
                          </p>
                          <Slider
                            screen={"specialty_screen"}
                            widgetData={widget}
                            ProductRes={productListNew}
                            footerColor={"#F4F6F8"}
                            id={"specialityNew"}
                            darkColor={"#4B4495"}
                            lightColor={"#a49cf598"}
                          />
                        </Container>
                      )}
                  </>
                );
              } else if (widget.widgetName === "vertical_product_list_widget") {
                return (
                  allProductList &&
                  allProductList.medicinesResponsesList.length > 0 && (
                    <Fragment>
                      <Container>
                        <br></br>

                        <p className="color_grey font_18 font_weight_md font_ubuntu">
                          {widget.data.title}
                        </p>
                      </Container>
                      <div
                        id="scrollableDiv"
                        className="productList"
                        style={{ paddingTop: "0rem" }}
                      >
                        <InfiniteScroll
                          dataLength={dataLength}
                          next={fetchMoreData}
                          hasMore={hasMore}
                          scrollableTarget="scrollableDiv"
                          scrollThreshold="90%"
                          className="infiniteScroll"
                          loader={
                            <Spinner
                              animation="border"
                              className="color_secondary spinner"
                              size="lg"
                            />
                          }
                        >
                          {allProductList &&
                            allProductList.medicinesResponsesList.map(
                              (med, index) => (
                                <ProductCard2
                                  position={index}
                                  screen={"specialty_screen"}
                                  key={index}
                                  medData={med}
                                />
                              )
                            )}
                        </InfiniteScroll>
                      </div>
                    </Fragment>
                  )
                );
              }
            })}

          {widgetsData &&
            !manufShimmer &&
            !topSellShimmer &&
            !newlyAddShimmer &&
            !allShimmer &&
            productList?.medicinesResponsesList?.length === 0 &&
            productListNew?.medicinesResponsesList?.length === 0 &&
            allProductList?.medicinesResponsesList?.length === 0 && (
              <Container className="no_data">
                No Specialty Medicines are available at this moment
              </Container>
            )}
        </Container>
      </Container>
    </Fragment>
  );
};

export default React.memo(Speciality);
