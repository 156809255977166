import React, { useContext, useEffect, useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import BannerSlider from "../BannerRedirects/BannerSlider";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import {BannerData } from "../../App";
import { useNavigate } from "react-router";
import { banner_widget } from "../../Actions/Widgets";

const BannerWidget = ({ widgetData,widgetIndex,renderWidget }) => {
  const [shimmer, setShimmer] = useState(false);
  const [banners, setBanner] = useState(null);
  const { setBannerData } = useContext(BannerData);
  const [bannerImages, setBannerImages] = useState([]);

  const navigate=useNavigate()

  const getData = () => {
    let obj;
    setShimmer(true);

    obj = banner_widget(widgetData.data.apiEndPoint);
    obj
      .then((res) => {
        setBanner(res.data.data);
        let arr = [];
        res.data.data.forEach((ele, i) => {
          arr.push(
            <img
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "banner_tapped",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                setBannerData(ele);
                navigate("/bannerRedirect");
              }}
              className="banner_img"
              src={ele.url}
              alt={i}
              loading="lazy"
            />
          );
        });
        setBannerImages(arr);
        localStorage.setItem(
          "currentScreenBannerData",
          JSON.stringify({ index: 1, data: res.data.data[0] })
        );
        setShimmer(false);
      })
      .catch((error) => {
        renderWidget(widgetData,widgetIndex,true,error)

      });
  };
  const homeBannerSlide = (event) => {
    localStorage.setItem(
      "currentScreenBannerData",
      JSON.stringify({ index: +event + 1, data: banners[event] })
    );
  };
  useEffect(() => {
    //hit the api to fetch the products
    console.log(widgetData)
    if (widgetData && !banners) getData();
  }, [widgetData]);
  return (
    <div>
      {shimmer && widgetData.widgetName === "banner_widget" && (
        <Container>
          <div className="skeletal_div animateShimmer"></div>
        </Container>
      )}

      {!shimmer && banners?.length && <>
        <Container fluid className="web_carousel">
                    <div className="banner_section">
                      <BannerSlider
                        slides={bannerImages}
                        slideChange={homeBannerSlide}
                      />
                    </div>
                  </Container>

                  <Carousel
                    key={'banner_section_mobile_carousel'}
                    className="banner_section mobile_carousel"
                    onSlide={homeBannerSlide}
                  >
                    {banners.map((ele, ind) => (
                      <Carousel.Item key={ind}>
                        <div
                          onClick={async () => {
                            Common_Event_Hit("DYNAMIC_EVENT", {
                              eventName: "banner_tapped",
                              screen_name:
                                localStorage.getItem("currentScreen"),
                            });
                            setBannerData(ele);
                            navigate("/bannerRedirect");
                          }}
                        >
                          <img
                            className="d-block banner_img"
                            src={ele.url}
                            alt={ele.fileName}
                            loading="lazy"
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
      </>}
    </div>
  );
};

export default React.memo(BannerWidget);
