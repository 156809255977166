import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Event_Actions } from "./Actions/UserEvents";
import { variables } from "./environments";

const RoutesTrack = () => {
  let location = useLocation();

  //On navigation to an in-scope page.
  //this default update sw behaviour is redundant in SPA as we don't "actually" navigate routes
  //the below update is called explicitly to simulate the same behaviour
  //although, the update also takes place every 24 hours by default
  const updateSW = async () => {
    //get the current scope reg obj
    const registration = await navigator.serviceWorker.getRegistration();
    //calling the update method on the registration object
    registration.update();
  };

  useEffect(() => {
    //invoke the service worker update on every route change
    updateSW();

    // console.log(location);
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (localStorage.getItem("currentScreen")) {
      let time = new Date().getTime();
      let sessionTime = time - localStorage.getItem("currentScreenStartTime");
      let bannerData = JSON.parse(
        localStorage.getItem("currentScreenBannerData")
      );
      const body = {
        deviceId: "",
        eventName: "screen_close",
        eventProperties: {
          timestamp: time,
          session_time: sessionTime,
          user_activity: {
            screen_name: localStorage.getItem("currentScreen"),
            source: "pharmacyWeb",
            locale: "en",
            banner_position: bannerData?.index,
            banner_file_name: bannerData?.data?.fileName,
          },
          screen_name: localStorage.getItem("currentScreen"),
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData?.enabled,
          deviceName: navigator.userAgent,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          userId: userData?.id,
        },
        mobileNo: userData?.mobileNo,
        source: "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
      };

      const moengage_body = {
        deviceId: "",
        eventName: "screen_close",
        timestamp: time,
        session_time: sessionTime,
        banner_position: bannerData?.index,
        banner_file_name: bannerData?.data?.fileName,
        screen_name: localStorage.getItem("currentScreen"),
        screens_visited: localStorage.getItem("screenCount"),
        session_number: localStorage.getItem("sessionCount"),
        enabled: userData?.enabled,
        deviceName: navigator.userAgent,
        phoneNumber: userData?.mobileNo,
        locale: "en",
        mobileNo: userData?.mobileNo,
        source: "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
      };

      if (
        location.pathname.split("/")[1] === "search" &&
        !location.pathname.split("/")[2]
      ) {
        Event_Actions("EVENT", {}, body, moengage_body);
      } else if (location.pathname.split("/")[1] !== "search") {
        Event_Actions("EVENT", {}, body, moengage_body);
      }
    }

    if (localStorage.getItem("screenCount")) {
      let count = localStorage.getItem("screenCount");
      count++;
      localStorage.setItem("screenCount", count);
    } else {
      localStorage.setItem("screenCount", 1);
    }

    window.onunload = () => {
      localStorage.removeItem("screenCount");
    };

    setTimeout(() => {
      const body = {
        deviceId: "",
        eventName: "screen_open",
        eventProperties: {
          timestamp: new Date().getTime(),
          screen_name: localStorage.getItem("currentScreen"),
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData?.enabled,
          deviceName: navigator.userAgent,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          userId: userData?.id,
        },
        mobileNo: userData?.mobileNo,
        source: "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
      };

      const moengage_body = {
        deviceId: "",
        eventName: "screen_open",
        timestamp: new Date().getTime(),
        screen_name: localStorage.getItem("currentScreen"),
        screens_visited: localStorage.getItem("screenCount"),
        session_number: localStorage.getItem("sessionCount"),
        enabled: userData?.enabled,
        deviceName: navigator.userAgent,
        phoneNumber: userData?.mobileNo,
        locale: "en",
        mobileNo: userData?.mobileNo,
        source: "pharmacyWeb",
        userId: userData?.id,
        version: variables.version,
      };

      localStorage.setItem("currentScreenStartTime", new Date().getTime());
      if (
        location.pathname.split("/")[1] === "search" &&
        !location.pathname.split("/")[2]
      ) {
        Event_Actions("EVENT", {}, body, moengage_body);
      } else if (location.pathname.split("/")[1] !== "search") {
        Event_Actions("EVENT", {}, body, moengage_body);
      }
    }, 0);
  }, [location]);

  return <></>;
};

export default RoutesTrack;
