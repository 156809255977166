import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import assistance_logo from "../../Images/json/voice_assistance_fab.json";
import "./VoiceAssistance.css";
import capsi_wave from "../../Images/json/capsi_wave.json";
import { IoMdArrowBack } from "react-icons/io";
import {
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AllLanguages, LanguagesData } from "./LanguagesList";
import { AiFillCaretDown } from "react-icons/ai";
import cx from "classnames";
import VASetupModal from "../Modals/VoiceAssistance/SetupModal";
import { FiMenu, FiSearch } from "react-icons/fi";
import { FaMicrophone } from "react-icons/fa";
import { BsFillKeyboardFill, BsVolumeMute } from "react-icons/bs";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import WaveLoader from "../../Images/json/wave_loader.json";
import {
  getSuggestions,
  textToSpeech,
  translateText,
} from "../../Actions/VoiceAssistance";
import LoadingDots from "../../CustomHooks/LoadingDots/LoadingDots";
import { IoPlayCircleOutline, IoStopCircleOutline } from "react-icons/io5";
import SpeakerIcon from "../../Images/svg/speaker.svg";
import InputChange from "../../CustomHooks/InputChange";
import { BiCheck } from "react-icons/bi";
import {GetBrowserName,isIOS} from "../../CustomHooks/BrowserDetection";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { GrFormClose } from "react-icons/gr";

const VoiceAssistance = () => {
  const browserName = GetBrowserName();
  const iosDevice=isIOS()
  var Languages;
  var LanguageText;
  if (browserName !== "Chrome") {
    Languages = [AllLanguages[0]];
    LanguageText = { English: { ...LanguagesData["English"] } };
  } else {
    Languages = [...AllLanguages];
    LanguageText = { ...LanguagesData };
  }
  const audioRef = useRef(null);
  const [step, setStep] = useState(0);
  const [selectedLanguage, setLanguage] = useState("English");
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [keyboardSelect, setKeyboardSelect] = useState(false);
  const [question, setQuestion] = useState({});
  const [qaList, setQaList] = useState([]);
  const [audioContent, setAudioContent] = useState();
  const [audioControls, setAudioControls] = useState({
    mute: false,
    pause: false,
    ended: false,
  });
  const [inputText, setInputText] = useState();
  const {
    transcript,
    listening,
    resetTranscript,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  //   language menu select states  and fucntions starts //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "menu_tap",
    });
  };
  const languageSelect = (lang) => {
    setLanguage(lang.value);
    setAnchorEl(null);
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "language_tap",
      language: lang.value,
    });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //   language menu select states  and fucntions ends //

  const VoiceAssistanceClick = () => {
    let existingUser = localStorage.getItem("voiceAssistanceSetup");
    if (existingUser === "true") {
      nextStep(3);
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "agent_screen",
        language: selectedLanguage,
      });
    } else {
      nextStep(1);
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "agent_setup_screen",
      });
    }
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "agent_tap",
    });
  };

  const languageChange = (e) => {
    setLanguage(e.target.value);
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const nextStep = (val) => {
    setStep(val);
  };

  const setupMicroPhone = () => {
    setShowSetupModal(true);
    localStorage.setItem("voiceAssistanceSetup", "true");
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "continue_to_setup_tap",
    });
  };

  const startAssistance = () => {
    nextStep(3);
    setShowSetupModal(false);
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "agent_screen",
      language: selectedLanguage,
    });
  };
  const exitVA = () => {
    setStep(0);
    setShowSetupModal(false);
    setKeyboardSelect(false);
    setQaList([]);
    setQuestion({});
    SpeechRecognition.abortListening();
    resetTranscript();
    handlePause();
    setAudioContent();
    setAudioControls({
      mute: false,
      pause: false,
      ended: false,
    });
    setInputText();
  };

  const startListening = () => {
    if (!browserSupportsSpeechRecognition) {
      window.alert(
        "Your Browser doesnt support voice search, please upgrade or use keyboard search"
      );
    } else if (!isMicrophoneAvailable) {
      window.alert("Please give permission to access microphone");
    } else {
      handlePause();
      !listening &&
        SpeechRecognition.startListening({
          language: LanguageText[selectedLanguage].languageCode,
        }) &&
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "mic_tap",
        });

      listening &&
        SpeechRecognition.stopListening() &&
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "stop_mic_tap",
        });
    }
  };

  const getAnswrs = () => {
    let questiondata = JSON.parse(JSON.stringify(question));

    if (questiondata.language !== "en") {
      let body = {
        text: questiondata.text,
        targetLanguage: "en",
      };
      //   translating text to english
      translateText(body).then((res) => {
        //    pass translated english text to openai to get suggestions
        getSuggestions({ ask: res.data.data }).then((res) => {
          let body = {
            text: res.data.data,
            targetLanguage: questiondata.language,
          };
          //   translate openai suggestions back to user selected language to display in chat bot
          translateText(body).then((res) => {
            let data = [...qaList];
            data[questiondata.index].answer = res.data.data;
            setQaList(data);
            scrollToBottom("qa_list");
            resetTranscript();
            speak(res.data.data);
          });
        });
      });
    } else {
      getSuggestions({ ask: questiondata.text }).then((res) => {
        let data = [...qaList];
        data[questiondata.index].answer = res.data.data;
        setQaList(data);
        scrollToBottom("qa_list");
        resetTranscript();
        speak(res.data.data);
      });
    }
  };

  const speak = async (data) => {
    if (browserName === "Safari" || iosDevice) {
      SpeechRecognition.startListening();
      setTimeout(() => {
        SpeechRecognition.stopListening();
      }, 200);
    }
    const body = {
      text: data,
      languageCode: LanguageText[selectedLanguage].languageCode,
    };
    const response = await textToSpeech(body);
    const url = URL.createObjectURL(
      new Blob([new Uint8Array(response.data.data.data).buffer], {
        type: "audio/mpeg",
      })
    );
    setAudioContent(url);
  };

  const handlePlay = () => {
    if (audioContent) {
      let controls = { ...audioControls };
      controls.pause = false;
      controls.ended = false;
      setAudioControls(controls);
      try {
        audioRef?.current?.play();
      } catch (err) {
        console.log("audio play error", err);
      }
    }
  };

  const handlePause = () => {
    if (audioContent) {
      let controls = { ...audioControls };
      controls.pause = true;
      setAudioControls(controls);
      audioRef.current.pause();
    }
  };

  const audioEnd = () => {
    let controls = { ...audioControls };
    controls.ended = true;
    setAudioControls(controls);
  };

  const muteAudio = () => {
    let controls = { ...audioControls };
    controls.mute = true;
    setAudioControls(controls);
  };
  const unMuteAudio = () => {
    let controls = { ...audioControls };
    controls.mute = false;
    setAudioControls(controls);
  };

  const scrollToBottom = (id) => {
    let scrollDiv = document.getElementById(id);
    scrollDiv && (scrollDiv.scrollTop = scrollDiv.scrollHeight);
  };

  const slectKeyboard = () => {
    setKeyboardSelect(true);
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "switch_to_keyboard_tap",
    });
  };

  const textInputKeyup = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      inputText?.length && searchByInput(inputText, "en-IN");
    }
  };

  const searchByInput = (text, langCode) => {
    let data = [...qaList];
    data.push({
      question: text,
      answer: "",
      language: langCode,
    });
    setQuestion({
      text: text,
      language: langCode,
      index: data.length - 1,
    });
    setQaList(data);
    setInputText("");
  };

  useEffect(() => {
    if (!listening && transcript?.length) {
      let data = [...qaList];
      data.push({
        question: transcript,
        answer: "",
        language: LanguageText[selectedLanguage].languageCode,
      });
      setQuestion({
        text: transcript,
        language: LanguageText[selectedLanguage].languageCode,
        index: data.length - 1,
      });
      setQaList(data);
    }
  }, [transcript, listening]);

  useEffect(() => {
    question.text?.length && getAnswrs();
    scrollToBottom("qa_list");
  }, [question]);

  return (
    <>
      {step > 0 && (
        <div
          className="voice_assistant_outer_div"
          onClick={() => exitVA()}
        ></div>
      )}

      <div className="voice_assistant" onClick={(e) => e.stopPropagation()}>
        <Lottie
          className="voice_assistant_logo elevation_3"
          loop
          animationData={assistance_logo}
          play
          style={{ width: "60px", height: "60px" }}
          onClick={VoiceAssistanceClick}
        />

        {step > 0 && (
          <div className="voice_assistant_popup">
            {showSetupModal && (
              <div className="va_setup_modal_outer_div">
                <VASetupModal
                  show={showSetupModal}
                  data={LanguageText[selectedLanguage].setUpModalText}
                  startUsing={startAssistance}
                />
              </div>
            )}
            {/* ---------------  step 1,2: onboarding user - language selection -starts ---------------------------------------- */}
            {(step === 1 || step === 2) && (
              <div className="language_select_newuser">
                <div className="capsi_wave_header">
                  <IoMdArrowBack
                    className="color_white cursor_pointer"
                    onClick={prevStep}
                  />
                  <Lottie
                    className="capsi_wave"
                    loop
                    animationData={capsi_wave}
                    play
                  />
                  <div className="txt_center color_white">
                    <b>{LanguageText[selectedLanguage]?.step1.heading1},</b>
                    <p className="font_12">
                      {LanguageText[selectedLanguage]?.step1.subHeading}
                    </p>
                  </div>
                  <br></br>
                </div>
                <div className="capsi_wave_header_curve"></div>
                {step === 1 && (
                  <div className="capsi_wave_laguage_select_body">
                    <p>
                      <b>Select Language</b>
                    </p>
                    <div className="lang_select_div">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedLanguage}
                          onChange={languageChange}
                        >
                          {Languages?.map((lang, i) => {
                            return (
                              <FormControlLabel
                                value={lang.value}
                                control={
                                  <Radio
                                    size="small"
                                    className="lang_radio_btn"
                                  />
                                }
                                label={lang.label}
                                className="lang_radio_div"
                                key={lang.value + i}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="txt_center">
                      <Button
                        variant="contained"
                        className="bg_primary lang_select_btn"
                        onClick={() => {
                          nextStep(2);
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "agent_setup_screen",
                          });
                        }}
                      >
                        {LanguageText[selectedLanguage].buttonText}
                      </Button>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="capsi_wave_laguage_select_body txt_center">
                    <div className="capsi_desc_header">
                      <p>
                        <b>{LanguageText[selectedLanguage].step2.heading1},</b>
                      </p>
                      <p>
                        <b>{LanguageText[selectedLanguage].step2.subHeading}</b>
                      </p>
                    </div>
                    {LanguageText[selectedLanguage].step2.sampleMessages?.map(
                      (msg, i) => {
                        return (
                          <div key={"sampleMessages" + i}>
                            <AiFillCaretDown
                              className={cx(
                                { capsi_msg_dec_arrow_rightend: i % 2 === 0 },
                                { capsi_msg_dec_arrow_leftend: i % 2 !== 0 }
                              )}
                            />
                            <div className="capsi_msg_dec">{msg}</div>
                            {i <
                              LanguageText[selectedLanguage].step2
                                .sampleMessages?.length -
                                1 && <p className="font_12">OR</p>}
                          </div>
                        );
                      }
                    )}
                    <hr></hr>
                    <p className="font_10 color_grey">
                      {LanguageText[selectedLanguage].step2.terms}
                    </p>

                    <div className="txt_center">
                      <Button
                        variant="contained"
                        className="bg_primary lang_select_btn"
                        onClick={setupMicroPhone}
                      >
                        {LanguageText[selectedLanguage].setUpButtonText}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* ---------------  step 1,2: onboarding user - language selection - ends ---------------------------------------- */}

            {/*----------------- step 3 : existing user view : landing page starts  ------------------------------------*/}

            {step === 3 && (
              <div className="va_welcome_page">
                <div className="vs_close_btn">
                  <GrFormClose onClick={exitVA} />
                </div>
                {audioContent && (
                  <audio
                    ref={audioRef}
                    className="display_none"
                    controls
                    muted={audioControls.mute}
                    onEnded={audioEnd}
                    onLoadedData={handlePlay}
                    src={audioContent}
                  />
                )}

                {!qaList?.length && (
                  <>
                    <div className="va_welocome_page_header">
                      <Lottie
                        className="capsi_wave_lg"
                        loop
                        animationData={capsi_wave}
                        play
                      />
                      <p className="padding_top_10">
                        <b>{LanguageText[selectedLanguage].step3.header}</b>
                      </p>
                    </div>

                    <div className="va_welocome_page_body">
                      {LanguageText[selectedLanguage].step3.questionsList.map(
                        (msg, i) => {
                          return (
                            <div
                              key={"questionsList" + i}
                              className="va_welocome_page_qsn"
                              onClick={() => {
                                searchByInput(
                                  msg,
                                  LanguageText[selectedLanguage].languageCode
                                );

                                Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "pretext_tap",
                                  pretext: msg,
                                  language: selectedLanguage,
                                });
                              }}
                            >
                              {msg}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </>
                )}

                {qaList?.length > 0 && (
                  <div className="qa_list_div" id="qa_list">
                    {qaList.map((data, i) => {
                      return (
                        <div className="convo_div" key={"qaList" + i}>
                          <div>
                            {" "}
                            <p>{data.question}</p>
                          </div>
                          <div>
                            <div className="answer_div">
                              {data.answer?.length > 0 ? (
                                data.answer
                              ) : (
                                <LoadingDots />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="va_welocome_page_footer">
                  {!keyboardSelect && (
                    <div>
                      <div
                        className="va_menu_btn elevation_4"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickLanguageMenu}
                      >
                        <FiMenu />
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {Languages?.map((lang) => {
                          return (
                            <MenuItem onClick={() => languageSelect(lang)}>
                              {lang.label} &nbsp;{" "}
                              {selectedLanguage === lang.value && (
                                <BiCheck className="font_18" />
                              )}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                      <div className="audio_controls_div">
                        {qaList.length > 0 && qaList[0].answer?.length > 0 && (
                          <div className="audio_controls">
                            {audioControls.pause || audioControls.ended ? (
                              <IoPlayCircleOutline onClick={handlePlay} />
                            ) : (
                              <IoStopCircleOutline onClick={handlePause} />
                            )}
                          </div>
                        )}

                        <div
                          className="va_microphone_btn elevation_4"
                          onClick={startListening}
                        >
                          {!listening && <FaMicrophone />}
                          {listening && (
                            <Lottie
                              className="wave_loader"
                              loop
                              animationData={WaveLoader}
                              play
                            />
                          )}
                        </div>
                        {qaList.length > 0 && qaList[0].answer?.length > 0 && (
                          <div className="audio_controls">
                            {audioControls.mute ? (
                              <BsVolumeMute onClick={unMuteAudio} />
                            ) : (
                              <img
                                src={SpeakerIcon}
                                alt="speaker"
                                onClick={muteAudio}
                              />
                            )}
                          </div>
                        )}
                      </div>

                      <div
                        className="va_keyboard_btn elevation_4"
                        onClick={slectKeyboard}
                      >
                        <BsFillKeyboardFill />
                      </div>
                    </div>
                  )}

                  {keyboardSelect && (
                    <div className="va_keyboard_footer elevation_4">
                      <div
                        className="va_menu_btn "
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickLanguageMenu}
                      >
                        {" "}
                        <FiMenu />
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {Languages?.map((lang) => {
                          return (
                            <MenuItem onClick={() => languageSelect(lang)}>
                              {lang.label} &nbsp;{" "}
                              {selectedLanguage === lang.value && (
                                <BiCheck className="font_18" />
                              )}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                      <div className="va_input_box ">
                        <input
                          className=""
                          type="text"
                          placeholder="Enter pharma queries.."
                          value={inputText}
                          autoFocus
                          onKeyDown={textInputKeyup}
                          onChange={(e) =>
                            InputChange(setInputText, e.target.value)
                          }
                        />
                        <FiSearch
                          className="va_key_search"
                          onClick={() =>
                            inputText?.length &&
                            searchByInput(inputText, "en-IN")
                          }
                        />
                      </div>
                      <div
                        className="va_keyboard_btn "
                        onClick={() => {
                          setKeyboardSelect(false);
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "switch_to_mic_tap",
                          });
                        }}
                      >
                        <FaMicrophone />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(VoiceAssistance);
