import React, { useContext, useEffect, useState } from "react";
import OnBehalf from "../Modals/OnBehalf";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { AiOutlineRight } from "react-icons/ai";
import { RiShieldUserLine } from "react-icons/ri";
import { usersData } from "../../App";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const Onbehalf = () => {
  let navigate = useNavigate();
  let sections = [
    { sectionName: "Add Retailer Code", icon: RiShieldUserLine },
    {
      sectionName: "Issues",
      icon: AiOutlineQuestionCircle,
      url: "/account/issues",
    },
  ];

  const [onBehalf, setShowOnBehalf] = useState(false);
  const { userData } = useContext(usersData);

  const sectionClick = (cat) => {
    if (cat.sectionName === "Add Retailer Code") {
      setShowOnBehalf(true);
    } else {
      navigate(cat.url);
    }
  };

  useEffect(() => {
    if (!userData?.admin && !userData?.salesPerson) {
      navigate("/home");
    }
  }, []);
  return (
    <Container fluid className="onbehalf_parent">
      <OnBehalf
        show={onBehalf}
        content={"Add Retailer Code"}
        onHide={() => {
          setShowOnBehalf(false);
        }}
      />
      <div className="onbehalf_header display_flex">
        <div className="padding_5">
          <HiOutlineArrowNarrowLeft
            className="cursor_pointer goBack"
            onClick={() => navigate(-1)}
            style={{ marginTop: "0.5rem" }}
          />
        </div>

        <div
          className="go_back color_primary font_ubuntu font_weight_md no_wrap  padding_10"
          onClick={() => navigate(-1)}
        >
          On&nbsp;Behalf
        </div>
      </div>

      <div className="onbehalf_body">
        {sections.map((cat) => {
          return (
            <div onClick={() => sectionClick(cat)} className="onbehalf_section_card">
              <div className="account_item bg_white store_account_item display_flex space_btw">
                <div className="">
                  <cat.icon className="onbehalf_section_icon" />
                  <span>&nbsp;&nbsp;{cat.sectionName}</span>
                </div>
                <div className="display_flex store-new-tag-container">
                  {cat.newSection && (
                    <>
                      <span
                        className="new_tag font_ubuntu"
                        style={{ height: "fit-content" }}
                      >
                        NEW
                      </span>
                    </>
                  )}
                  <AiOutlineRight className="margin_10 color_secondary" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Onbehalf;
