import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Carousel, Spinner } from "react-bootstrap";
import ProductCard2 from "../ProductCard/ProductCard2";
import "../../App.css";
import "../ProductList/ProductList.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./categories.css";
import CompaniesSlider from "../Sliders/CompaniesSlider";
import Slider from "../Sliders/Slider";
import { getScreen } from "../../Actions/Screens";
import { refreshToken } from "../../Actions/Auth";
import { common_product_widget } from "../../Actions/Widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCatgoriesData } from "../../Actions/Categories";
import { BannerData, usersData } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import BannerSlider from "../BannerRedirects/BannerSlider";

const RegularSchemes = ({ getProductListHeader }) => {
  let navigate = useNavigate();
  const [widgetsData, setWidgetsData] = useState();
  const [offersWidget, setOffersWidget] = useState();
  const [bannerWidget, setBannerWidget] = useState();
  const [productList, setProductList] = useState();
  const [allProductList, setAllProductList] = useState();
  const [page, setPage] = useState(0);
  const [bannerImages, setBannerImages] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [manufacturers, setManufacturers] = useState();
  const [dataLength, setDataLength] = useState(0);
  const [skuData, setSkuData] = useState();
  const [screenShimmer, setScreenShimmer] = useState(false);
  const [productListShimmer, setProductListShimmer] = useState(false);
  const [bannerWidgetShimmer, setBannerWidgetShimmer] = useState(false);
  const [allProductShimmer, setAllProductShimmer] = useState(false);
  const [manufacturerShimmer, setManufacturerShimmer] = useState(false);
  const { setBannerData } = useContext(BannerData);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState(false);

  const getAllProducts = () => {
    const params = {
      filterCondition: "all",
      page: page,
    };
    page === 0 && setAllProductShimmer(true);
    let api = common_product_widget(
      "home/v2/getRegularSchemeMedicines",
      params
    );
    api
      .then((res) => {
        let obj =
          allProductList && allProductList.medicinesResponsesList
            ? allProductList
            : { medicinesResponsesList: [] };
        console.log(obj.medicinesResponsesList);
        if (res.data.data.medicinesResponsesList.length > 0) {
          obj.medicinesResponsesList.push(
            ...res.data.data.medicinesResponsesList
          );
          setDataLength(obj.medicinesResponsesList.length);
        }
        setAllProductList(obj);

        if (res.data.data.size > 0 && obj.medicinesResponsesList.length < 10) {
          fetchMoreData();
        } else if (
          res.data.data.size > 0 &&
          res.data.data.medicinesResponsesList.length === 0
        ) {
          fetchMoreData();
        }

        if (res.data.data.size === 0) {
          setHasMore(false);
        }
        console.log(obj);
        setAllProductShimmer(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getAllProducts();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getAllProducts();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getAllProducts();
            },
          });
        }
      });
  };

  const getSkuCount = () => {
    getCatgoriesData("REGULAR_SCHEME_SKU_COUNT", {})
      .then((res) => {
        setSkuData(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getSkuCount();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getSkuCount();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getSkuCount();
            },
          });
        }
      });
  };

  const fetchMoreData = () => {
    setPage(+page + 1);
  };
  const homeBannerSlide = (event) => {
    localStorage.setItem(
      "currentScreenBannerData",
      JSON.stringify({ index: +event + 1, data: bannerWidget[event] })
    );
  };
  useEffect(() => {
    // /home/scheme
    // regular_scheme_screen

    let userData = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "regular_scheme_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      setScreenShimmer(true);
      const params = {
        retailerId: JSON.parse(userData).id,
        screenName: "regular_scheme_screen",
      };

      let schemes_screen = () => {
        getScreen("HOME_SCREEN", params, {})
          .then((res) => {
            setWidgetsData(res.data.data);
            setScreenShimmer(false);
            res.data.data.forEach((item, i) => {
              let getWidgetData = () => {
                let params;
                if (item.widgetName === "offer_category_widget") {
                  params = {};
                } else if (item.widgetName === "banner_widget") {
                  params = {
                    pageId: "specialOffers",
                  };
                  setBannerWidgetShimmer(true);
                } else if (item.widgetName === "product_list_widget") {
                  params = {
                    filterCondition: "popular",
                    page: 0,
                  };
                  setProductListShimmer(true);
                } else if (item.widgetName === "manufacturer_list_widget") {
                  params = {};
                  setManufacturerShimmer(true);
                }

                if (
                  item &&
                  item.widgetName !== "vertical_product_list_widget" &&
                  item.widgetName !== "scheme_discount_widget"
                ) {
                  let api = common_product_widget(
                    item.data.apiEndPoint,
                    params
                  );
                  api
                    .then((res) => {
                      if (item.widgetName === "offer_category_widget") {
                        setOffersWidget(res.data.data);
                      } else if (item.widgetName === "banner_widget") {
                        setBannerWidget(res.data.data);
                        setBannerWidgetShimmer(false);
                        localStorage.setItem(
                          "currentScreenBannerData",
                          JSON.stringify({ index: 1, data: res.data.data[0] })
                        );
                        let arr = [];
                        res.data.data.forEach((ele, i) => {
                          arr.push(
                            <img
                              onClick={async () => {
                                Common_Event_Hit("DYNAMIC_EVENT", {
                                  eventName: "banner_tapped",
                                  screen_name:
                                    localStorage.getItem("currentScreen"),
                                });
                                setBannerData(ele);
                                navigate("/bannerRedirect");
                              }}
                              className="banner_img"
                              src={ele.url}
                              alt={i}
                            />
                          );
                        });
                        setBannerImages(arr);
                      } else if (item.widgetName === "product_list_widget") {
                        setProductList(res.data.data);
                        setProductListShimmer(false);
                      } else if (
                        item.widgetName === "manufacturer_list_widget"
                      ) {
                        setManufacturers(res.data.data);
                        setManufacturerShimmer(false);
                      }
                    })
                    .catch((error) => {
                      if (error?.response?.status === 401) {
                        refreshToken().then((res) => {
                          getWidgetData();
                        });
                      } else if (
                        error?.response?.status === 502 ||
                        error?.response?.status === 503
                      ) {
                        setShowMaintenance({
                          status: true,
                          apiData: error?.response,
                        });
                      } else if (error?.response?.status === 500) {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: "Oops something went wrong !",
                        });
                      } else {
                        setErrorModal({
                          show: true,
                          apiData: error?.response,
                          content: error?.response?.data?.error?.error
                            ? error.response.data.error.error
                            : "Something went wrong!",
                        });
                      }
                    });
                }
              };

              return getWidgetData();
            });
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken()
                .then((res) => {
                  schemes_screen();
                })
                .catch((error) => {
                  localStorage.removeItem("userData");
                  localStorage.removeItem("userAuth");
                  navigate("/login");
                });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  schemes_screen();
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  schemes_screen();
                },
              });
            }
          });
      };
      schemes_screen();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    widgetsData && getSkuCount();
  }, [widgetsData]);

  useEffect(() => {
    widgetsData && getAllProducts();
  }, [page, widgetsData]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {screenShimmer ? (
        <Container fluid className="productList">
          {[...Array(4)].map((_, index) => {
            return (
              <div className="skeletal_div animateShimmer" key={index}></div>
            );
          })}
        </Container>
      ) : (
        <Fragment>
          <Container fluid className="productList">
            <Container
              fluid
              className="productListHeader font_ubuntu display_flex"
            >
              <HiOutlineArrowNarrowLeft
                className="cursor_pointer goBack"
                onClick={() => navigate("/home")}
              />{" "}
              &nbsp;&nbsp;&nbsp;{" "}
              <div className="head_specialOffers">
                Regular Schemes
                {skuData && (
                  <span className="sub_head">
                    {skuData.companyCount} Companies • {skuData.skuCount} SKU'S
                  </span>
                )}{" "}
              </div>
            </Container>
            <Container>
              {widgetsData &&
                widgetsData.map((widget, i) => {
                  if (widget.widgetName === "manufacturer_list_widget") {
                    return (
                      <>
                        {manufacturerShimmer ? (
                          <Fragment>
                            <p className="font_18 font_weight_md font_ubuntu">
                              Top Companies
                            </p>
                            <CompaniesSlider
                              scheme="regularScheme"
                              shimmer={manufacturerShimmer}
                              footerColor={"#F4F6F8"}
                              id={"trendingProducts"}
                              darkColor={"#4B4495"}
                              lightColor={"#a49cf598"}
                            />
                          </Fragment>
                        ) : (
                          <>
                            {manufacturers && manufacturers.length > 0 && (
                              <Container key={i}>
                                <Fragment>
                                  <p
                                    className="font_18 font_weight_md font_ubuntu"
                                    style={{ color: widget.data.titleColor }}
                                  >
                                    {widget.data.title}
                                  </p>
                                  <CompaniesSlider
                                    data={manufacturers}
                                    scheme="regularScheme"
                                    getProductListHeader={getProductListHeader}
                                    footerColor={"#F4F6F8"}
                                    id={"trendingProducts"}
                                    darkColor={"#4B4495"}
                                    lightColor={"#a49cf598"}
                                  />
                                </Fragment>
                              </Container>
                            )}
                          </>
                        )}
                      </>
                    );
                  } else if (widget.widgetName === "banner_widget") {
                    return (
                      <>
                        {bannerWidgetShimmer ? (
                          <Container>
                            <div className="skeletal_div animateShimmer"></div>
                          </Container>
                        ) : (
                          <>
                            {bannerWidget && bannerWidget.length > 0 && (
                              <>
                                <br></br>
                                <Container fluid className="web_carousel">
                                  <div className="banner_section">
                                    <BannerSlider
                                      slides={bannerImages}
                                      slideChange={homeBannerSlide}
                                    />
                                  </div>
                                </Container>

                                <Carousel
                                  className="banner_section mobile_carousel"
                                  onSlide={homeBannerSlide}
                                >
                                  {bannerWidget.map((ele, ind) => (
                                    <Carousel.Item key={ind}>
                                      <div
                                        onClick={async () => {
                                          Common_Event_Hit("DYNAMIC_EVENT", {
                                            eventName: "banner_tapped",
                                            screen_name:
                                              localStorage.getItem(
                                                "currentScreen"
                                              ),
                                          });
                                          setBannerData(ele);
                                          navigate("/bannerRedirect");
                                        }}
                                      >
                                        <img
                                          className="d-block banner_img"
                                          src={ele.url}
                                          alt={ele.fileName}
                                        />
                                      </div>
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </>
                            )}
                          </>
                        )}
                      </>
                    );
                  } else if (widget.widgetName === "scheme_discount_widget") {
                    return (
                      <Fragment>
                        <Container>
                          <p
                            className="font_18 font_weight_md font_ubuntu"
                            style={{ color: widget.data.titleColor }}
                          >
                            {widget.data.title}
                          </p>
                        </Container>

                        <Container className="display_flex space_around discount_section">
                          <div
                            onClick={async () => {
                              Common_Event_Hit("DYNAMIC_EVENT", {
                                eventName: "offer_tap",
                                sectionId: "discount_50_100",
                                screen_name:
                                  localStorage.getItem("currentScreen"),
                              });
                              navigate("/categories/schemesByDiscount/50-100");
                            }}
                            className="text_decoration_none"
                          >
                            <div className="discount_card_1 ">
                              {">"} 50 % <br />
                              OFF <br />
                              <span className="bg_brown color_white card_check_1">
                                CHECK NOW
                              </span>
                            </div>
                          </div>
                          <div
                            onClick={async () => {
                              Common_Event_Hit("DYNAMIC_EVENT", {
                                eventName: "offer_tap",
                                sectionId: "discount_10_50",
                                screen_name:
                                  localStorage.getItem("currentScreen"),
                              });
                              navigate("/categories/schemesByDiscount/10-50");
                            }}
                            className="text_decoration_none"
                          >
                            <div className="discount_card_1 bg_cream_lt color_maroon">
                              10 - 50 % <br />
                              OFF <br />
                              <span className="bg_brown color_white card_check_1 bg_maroon ">
                                CHECK NOW
                              </span>
                            </div>
                          </div>
                          <div
                            onClick={async () => {
                              Common_Event_Hit("DYNAMIC_EVENT", {
                                eventName: "offer_tap",
                                sectionId: "discount_0_10",
                                screen_name:
                                  localStorage.getItem("currentScreen"),
                              });
                              navigate("/categories/schemesByDiscount/0-10");
                            }}
                            className="text_decoration_none"
                          >
                            <div className="discount_card_1 bg_pink_lt color_purple">
                              {"<"} 10 % <br />
                              OFF <br />
                              <span className="bg_brown color_white card_check_1 bg_purple ">
                                CHECK NOW
                              </span>
                            </div>
                          </div>
                        </Container>
                      </Fragment>
                    );
                  } else if (widget.widgetName === "product_list_widget") {
                    return (
                      <>
                        {productListShimmer ? (
                          <Container>
                            <br></br>
                            <p className="font_18 font_weight_md font_ubuntu">
                              Top Products
                            </p>
                            <Slider
                              screen={"regular_scheme_screen"}
                              shimmer={productListShimmer}
                              footerColor={"#F4F6F8"}
                              id={"regularSchemePopular"}
                              darkColor={"#4B4495"}
                              lightColor={"#a49cf598"}
                            />
                          </Container>
                        ) : (
                          productList && (
                            <Container>
                              <br></br>
                              <p
                                className="font_18 font_weight_md font_ubuntu"
                                style={{ color: widget.data.titleColor }}
                              >
                                {widget.data.title}
                              </p>
                              <Slider
                                screen={"regular_scheme_screen"}
                                widgetData={widget}
                                ProductRes={productList}
                                footerColor={"#F4F6F8"}
                                id={"regularSchemePopular"}
                                darkColor={"#4B4495"}
                                lightColor={"#a49cf598"}
                              />
                            </Container>
                          )
                        )}
                      </>
                    );
                  } else if (
                    widget.widgetName === "vertical_product_list_widget"
                  ) {
                    return (
                      <>
                        {allProductShimmer ? (
                          <Fragment>
                            <Container>
                              <br></br>

                              <p className="font_18 font_weight_md font_ubuntu">
                                {" "}
                                All Products
                              </p>
                            </Container>
                            <div id="scrollableDiv" className="productList">
                              <ProductCard2 shimmer={allProductShimmer} />{" "}
                              <ProductCard2 shimmer={allProductShimmer} />{" "}
                              <ProductCard2 shimmer={allProductShimmer} />{" "}
                              <ProductCard2 shimmer={allProductShimmer} />
                            </div>
                          </Fragment>
                        ) : (
                          allProductList && (
                            <Fragment>
                              <Container>
                                <br></br>

                                <p
                                  className=" font_18 font_weight_md font_ubuntu"
                                  style={{ color: widget.data.titleColor }}
                                >
                                  {widget.data.title}
                                </p>
                              </Container>
                              <div
                                id="scrollableDiv"
                                className="productList"
                                style={{ paddingTop: "0rem" }}
                              >
                                <InfiniteScroll
                                  dataLength={dataLength}
                                  next={fetchMoreData}
                                  hasMore={hasMore}
                                  scrollableTarget="scrollableDiv"
                                  scrollThreshold="90%"
                                  className="infiniteScroll"
                                  loader={
                                    <Spinner
                                      animation="border"
                                      className="color_secondary spinner"
                                      size="lg"
                                    />
                                  }
                                >
                                  {allProductList &&
                                    allProductList.medicinesResponsesList.map(
                                      (med, index) => (
                                        <ProductCard2
                                          position={index}
                                          screen={"regular_scheme_screen"}
                                          key={index}
                                          medData={med}
                                        />
                                      )
                                    )}
                                </InfiniteScroll>
                              </div>
                            </Fragment>
                          )
                        )}
                      </>
                    );
                  }
                })}

              {/* {
                  widgetsData && !manfShimmer && !productListTrendShimmer && !productListNewShimmer && productList?.medicinesResponsesList?.length===0 && productListNew?.medicinesResponsesList?.length===0 && allProductList?.medicinesResponsesList?.length===0 && 
              <Container className="no_data">
                No RegularScheme Medicines are available at this moment
              </Container>
            } */}
            </Container>
          </Container>
        </Fragment>
      )}
    </>
  );
};

export default React.memo(RegularSchemes);
