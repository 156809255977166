import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import {
  creditOnboardingPopup,
  getCreditData,
  postCreditUserData,
  uploadSupportDocuments,
} from "../../Actions/ApplyCredit";
import { AiFillCloseCircle, AiOutlineUpload } from "react-icons/ai";
import "./ApplyCredit.css";
import ResponseScreen from "./ResponseScreen";
import { refreshToken } from "../../Actions/Auth";
import { usersData } from "../../App";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import OrderMessageModal from "../Modals/orderMessageModal";
import ErrorModal from "../Modals/ErrorModal";
import AcceptCreditAgreementModal from "../Modals/AcceptCreditAgreementModal";
import { useNavigate } from "react-router";

function ApplyCredit() {
  const SupportImages = {
    aadharFront: "",
    aadharBack: "",
    panFront: "",
  };

  const CreditFormData = {
    ownerName: "",
    motherName: "",
    email: "",
    pinCode: "",
    panNumber: "",
    aadhar: "",
  };

  const DocsUploaded = {
    aadharFront: false,
    aadharBack: false,
    panFront: false,
  };
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Store Type", "Basic Details"];
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [imageURL, setImageURL] = useState(SupportImages);
  const [supportDocuments, setSupportDocuments] = useState(SupportImages);
  const [infoMessage, setInfoMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [error, setError] = useState(CreditFormData);
  const [formData, setFormData] = useState(CreditFormData);
  const [userAgreement, setUserAgreement] = useState(false);
  const [showReponseScreen, setShowReponseScreen] = useState(false);
  const [creditData, setCreditData] = useState({});
  const [changeResponse, setchangeResponse] = useState(false);
  const [radioValue, setRadioValue] = useState(null);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [showSpinner, setShowSpinner] = useState(false);
  const [docsUploaded, setDocsUploaded] = useState(DocsUploaded);
  const [uploadedDocumentsLinks, setUploadedDocumentsLinks] =
    useState(SupportImages);
  const uploadedDocumentsLinksRef = useRef();
  uploadedDocumentsLinksRef.current = uploadedDocumentsLinks;
  const [agreementPopUp, setAgreementPopUp] = useState({ show: false });
  const [agreementPopUpData, setAgreementPopUpData] = useState({});
  const navigate = useNavigate();
  const [aadharFrontUrl, setAadharFrontUrl] = useState("");
  const [aadharBackUrl, setAadharBackUrl] = useState("");
  const [panFrontUrl, setPanFrontUrl] = useState("");

  const storeTypes = ["Proprietorship", "Private Ltd.", "Partnership"];

  const handleFileInput = (e) => {
    if (e.target.files[0].size >= 700 * 1024) {
      setInfoMessage({
        show: true,
        content: "file size cannot be greater than 700KB",
        header: "File Size Exceeds Limit",
      });
      e.target.value = "";
      return;
    } else if (
      !(
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("png")
      )
    ) {
      setInfoMessage({
        show: true,
        content: "file type should be jpeg, jpg or png",
        header: "Invalid File Type",
      });
      e.target.value = "";
      return;
    }

    setSupportDocuments({
      ...supportDocuments,
      [e.target.name]: e.target.files[0],
    });

    setImageURL({
      ...imageURL,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = "";
  };

  const removeFileInput = (key) => {
    setSupportDocuments({
      ...supportDocuments,
      [key]: null,
    });
    setImageURL({
      ...imageURL,
      [key]: null,
    });
    setUploadedDocumentsLinks({
      ...uploadedDocumentsLinks,
      [key]: "",
    });
  };

  const handleFormData = (e) => {
    //update the form data
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkFormDataValidation = () => {
    let flag = false;
    let data = JSON.parse(JSON.stringify(error));

    console.table(formData);

    if (formData.ownerName.length > 0) {
      data.ownerName = false;
    } else {
      data.ownerName = true;
      flag = true;
    }

    if (formData.motherName.length > 0) {
      data.motherName = false;
    } else {
      data.motherName = true;
      flag = true;
    }

    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(formData.email)) {
      data.email = false;
    } else {
      data.email = true;
      flag = true;
    }

    if (formData.pinCode.length > 0 && formData.pinCode.length === 6) {
      data.pinCode = false;
    } else {
      data.pinCode = true;
      flag = true;
    }

    let panNumberRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (panNumberRegex.test(formData.panNumber)) {
      // console.log("pan card error", panNumberRegex.test(error.panNumber));
      data.panNumber = false;
    } else {
      data.panNumber = true;
      flag = true;
    }

    let aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    if (aadharRegex.test(formData.aadhar)) {
      data.aadhar = false;
    } else {
      data.aadhar = true;
      flag = true;
    }

    // console.log({ data });

    setError(data);

    return flag;
  };

  const fileUpload = async (item, key) => {
    let body = new FormData();
    body.append("file", item);
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let fileName = "";
    if (key.toString() === "panFront") fileName = "PAN_FRONT";
    else if (key.toString() === "aadharFront") fileName = "AADHAR_FRONT";
    else if (key.toString() === "aadharBack") fileName = "AADHAR_BACK";

    let params = {
      fileName: fileName,
      retCode: data.retailerCode,
    };
    // console.log({ params });
    try {
      const res = await uploadSupportDocuments(params, body);
      console.log(res?.data?.data);
      if (fileName === "AADHAR_FRONT") setAadharFrontUrl(res?.data?.data);
      else if (fileName === "AADHAR_BACK") setAadharBackUrl(res?.data?.data);
      else if (fileName === "PAN_FRONT") setPanFrontUrl(res?.data?.data);
      // setUploadedDocumentsLinks({
      //   ...uploadedDocumentsLinks,
      //   [key]: res?.data?.data,
      // });
      return res;
    } catch (error) {
      if (error?.response?.status === 401) {
        refreshToken().then((res) => {
          fileUpload(item, key);
        });
      } else if (
        error?.response?.status === 502 ||
        error?.response?.status === 503 ||
        error?.response?.status === 504
      ) {
        setShowMaintenance(true);
      } else if (error?.response?.status === 500) {
        setErrorModal({
          show: true,
          content: "Oops something went wrong!",
          tryAgain: function () {
            fileUpload(item, key);
          },
        });
      } else {
        setErrorModal({
          show: true,
          content: error?.response?.data?.error?.error
            ? error.response.data.error.error
            : "Something went wrong!",
          tryAgain: function () {
            fileUpload(item, key);
          },
        });
      }
    }
  };

  const handleFileUpload = async () => {
    let keys = [];
    for (let key in supportDocuments) {
      if (uploadedDocumentsLinks[key]?.length === 0) {
        //if previously uploaded link isn't available
        if (supportDocuments[key]) {
          keys.push(key);
        }
      }
    }

    // let requests = keys.map((key) => fileUpload(key, supportDocuments[key]))
    try {
      const res = await Promise.all(
        keys.map((key) => fileUpload(supportDocuments[key], key))
      );
      return res;
    } catch (error) {}
  };

  const handleApplyCredit = async () => {
    //call apply_tap event
    callApiEvents("apply_tap");

    let noFormDataError = !checkFormDataValidation();
    let attached = false;
    for (let key in supportDocuments) {
      // console.log(supportDocuments[key], uploazdedDocumentsLinks[key], key)
      if (supportDocuments[key] && supportDocuments[key]?.length === 0) {
        if (
          uploadedDocumentsLinks[key] &&
          uploadedDocumentsLinks[key]?.length === 0
        )
          attached = false;
      } else {
        attached = true;
      }
    }
    let requiredDocsAttached = attached;

    if (noFormDataError && userAgreement && requiredDocsAttached) {
      //update spinner state
      setShowSpinner(true);

      const res = await handleFileUpload()
        .then(() => {
          console.log(
            "after uploading images",
            uploadedDocumentsLinksRef.current
          );
          let attachments = Object.values(
            uploadedDocumentsLinksRef.current
          ).filter((item) => item?.length > 0);
          let data = localStorage.getItem("userData");
          data = JSON.parse(data);

          const params = {
            retailerId: data?.id,
            //  statusMessage: '',
          };

          const body = {
            aadhar: formData?.aadhar || "6767676767",
            aadharBackUrl: uploadedDocumentsLinksRef.current?.aadharBack,
            aadharFrontUrl: uploadedDocumentsLinksRef.current?.aadharFront,
            businessType: selectedBusiness,
            editable: creditData?.editable,
            email: formData?.email || "random.superrandom.com",
            mothersName: formData?.motherName || "pink pather",
            name: formData?.ownerName || "m'baku",
            panFrontUrl: uploadedDocumentsLinksRef.current?.panFront,
            panNumber: formData?.panNumber || "ABCDE1234F",
            pinCode: formData?.pinCode || "560017",
          };

          console.table(params);
          postCreditUserData(params, body)
            .then((res) => {
              setShowSpinner(false);
              setCreditData(res?.data?.data);
              setShowReponseScreen(true);
            })
            .catch((error) => {
              // console.log(error.response);
              if (error?.response?.status === 401) {
                refreshToken().then((res) => {
                  handleApplyCredit();
                });
              } else if (
                error?.response?.status === 502 ||
                error?.response?.status === 503 ||
                error?.response?.status === 504
              ) {
                setShowMaintenance(true);
              } else if (error?.response?.status === 500) {
                setErrorModal({
                  show: true,
                  content: "Oops something went wrong!",
                  tryAgain: function () {
                    handleApplyCredit();
                  },
                });
              } else {
                setErrorModal({
                  show: true,
                  content: error?.response?.data?.error?.error
                    ? error.response.data.error.error
                    : "Something went wrong!",
                  tryAgain: function () {
                    handleApplyCredit();
                  },
                });
              }
            });
        })
        .catch((error) => {
          // console.log(error.response);
          if (error?.response?.status === 401) {
            refreshToken().then((res) => {
              handleApplyCredit();
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              content: "Oops something went wrong!",
              tryAgain: function () {
                handleApplyCredit();
              },
            });
          } else {
            setErrorModal({
              show: true,
              content: error?.response?.data?.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                handleApplyCredit();
              },
            });
          }
        });
    } else {
      console.log(
        "noFormDataError && userAgreement && requiredDocsAttached",
        noFormDataError,
        userAgreement,
        requiredDocsAttached
      );
      if (!userAgreement) {
        setInfoMessage({
          show: true,
          content: "Please accept User Agreement to proceed further",
          header: "Accept User Agreement",
        });
      } else if (!requiredDocsAttached) {
        setInfoMessage({
          show: true,
          content: "Please attach the documents to proceed further",
          header: "Attach Documents",
        });
      } else {
        // console.log('noFormDataError && userAgreement && requiredDocsAttached',noFormDataError, userAgreement, requiredDocsAttached)
        window.scrollTo(0, 0);
      }
    }
    //handle if some data is wrong
  };

  //handler that updates the toggle state of the agreement
  const toggleUserAgreement = () => {
    setUserAgreement((prevValue) => !prevValue);
  };

  //business type selection radio button handler
  const handleRadioButton = (e) => {
    // console.log("handle radio called", e.target.value);
    setRadioValue(e.target.value);
  };

  //post selected business type
  const postBusinessType = async () => {
    // console.log("inside post business type");
    setShowSpinner(true);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const params = {
      retailerId: userData.id,
    };

    let BusinessType = "";
    if (radioValue == 1) BusinessType = "PROPRIETORSHIP";
    else if (radioValue == 2) BusinessType = "PRIVATE_LIMITED";
    else if (radioValue == 3) BusinessType = "PARTNERSHIP";

    const body = {
      businessType: BusinessType,
    };

    postCreditUserData(params, body)
      .then((res) => {
        // console.log(res);
        setShowSpinner(false);
        setSelectedBusiness(BusinessType);
        setCreditData(res?.data?.data);
        // console.log({creditData})
        // console.log(res?.data?.data)
        if (res?.data?.data?.status === "NOT_APPLICABLE")
          setShowReponseScreen(true); //only show form under this case
        else setActiveStep(1);
      })
      .catch((error) => {
        // console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            postBusinessType();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong!",
            tryAgain: function () {
              postBusinessType();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              postBusinessType();
            },
          });
        }
      });
  };

  //get the credit user data and handle the routing accordingly
  const getUserCreditData = () => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const params = {
      retailerId: userData.id,
    };
    getCreditData(params, {})
      .then((res) => {
        let status = res?.data?.data?.status;
        // console.log(res?.data?.data);
        //update credit data
        setCreditData(res?.data?.data);

        //update docs uploaded state if there are docs previously uploaded
        let newDocsData = { ...docsUploaded }; //deep copy
        let newUploadedData = { ...uploadedDocumentsLinks }; //deep copy of uploaded links
        if (res?.data?.data?.aadharFrontUrl?.length > 0) {
          newDocsData.aadharFront = true;
          // uploadedDocumentsLinksRef.current.aadharFront = res?.data?.data?.aadharFrontUrl
          newUploadedData.aadharFront = res?.data?.data?.aadharFrontUrl;
        }
        if (res?.data?.data?.aadharBackUrl?.length > 0) {
          newDocsData.aadharBack = true;
          // uploadedDocumentsLinksRef.current.aadharBack = res?.data?.data?.aadharBackUrl
          newUploadedData.aadharBack = res?.data?.data?.aadharBackUrl;
        }
        if (res?.data?.data?.panFrontUrl?.length > 0) {
          newDocsData.panFront = true;
          // uploadedDocumentsLinksRef.current.panFront = res?.data?.data?.panFrontUrl
          newUploadedData.panFront = res?.data?.data?.panFrontUrl;
        }

        //update state of uploaded links
        setUploadedDocumentsLinks(newUploadedData);

        // console.table(newDocsData);
        setDocsUploaded(newDocsData);

        if (status === "APPLIED") {
          if (changeResponse) {
            setShowReponseScreen(false); //donot show response screen
            setchangeResponse(false); //update change response trigger
            setActiveStep(0); //display business selection
            return;
          }
          //show the response screen and allow change response
          setShowReponseScreen(true);
        } else if (status === "NOT_APPLICABLE") {
          // console.log("inside not applicable");
          //show the response screen and allow change response
          setShowReponseScreen(true);
        } else if (status === "PENDING") {
          //check business type
          if (res?.data?.data?.businessType) {
            if (
              res?.data?.data?.businessType === "PRIVATE_LIMITED" ||
              res?.data?.data?.businessType === "PARTNERSHIP"
            ) {
              setShowReponseScreen(true);
            } else if (res?.data?.data?.businessType === "PROPRIETORSHIP") {
              setActiveStep(1);
            }
            //update the selected businessType state
            setSelectedBusiness(res?.data?.data?.businessType);
            //set the step to 1 -> shows the form
            if (changeResponse) {
              setActiveStep(0);
            } else setActiveStep(1);
          } else {
            //set the step to 0 => show business screen
            setActiveStep(0);
          }
        } else if (status === "AGREEMENT_SENT") {
          //show the popup to accept the agreement -> Home Screen and Proceed to Payment -> record IP, timestamp on accept
        } else if (status === "AGREEMENT_ACCEPTED") {
          //do nothing for now
        } else if (status === "SENT_FOR_APPROVAL") {
          setShowReponseScreen(true);
        }
      })
      .catch((error) => {
        // console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getUserCreditData();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getUserCreditData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getUserCreditData();
            },
          });
        }
      });
  };

  //handle docs uploaded toggle
  const handleDocsUploadedToggle = (docType) => {
    let data = { ...uploadedDocumentsLinks }; //deep copy uploaded links
    if (docType === "aadharFront") {
      setDocsUploaded({ ...docsUploaded, aadharFront: false });
      data.aadharFront = "";
    } else if (docType === "aadharBack") {
      setDocsUploaded({ ...docsUploaded, aadharBack: false });
      data.aadharBack = "";
    } else if (docType === "panFront") {
      setDocsUploaded({ ...docsUploaded, panFront: false });
      data.panFront = "";
    }

    //update the state of uploadedLinks
    setUploadedDocumentsLinks(data);
  };

  //Events API handler
  const callApiEvents = (eventName) => {
    let props = {
      eventName: eventName,
    };
    if (
      eventName === "next_tap" ||
      eventName === "name_tap_credit" ||
      eventName === "mothers_name_tap_credit" ||
      eventName === "email_tap_credit" ||
      eventName === "pincode_tap_credit" ||
      eventName === "pan_tap_credit" ||
      eventName === "aadhar_tap_credit" ||
      eventName === "image_aadhar_front_credit" ||
      eventName === "image_aadhar_back_credit" ||
      eventName === "image_pan_front_credit" ||
      eventName === "apply_tap"
    )
      Common_Event_Hit("DYNAMIC_EVENT", props);
  };

  const getCreditUserOnboardingPopUp = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let params = {
      retailerId: data?.id,
      source: "credit_shop_type_screen",
    };
    creditOnboardingPopup(params, {})
      .then((res) => {
        // // console.log(res)
        setAgreementPopUpData(res?.data?.data);
        if (res?.data?.data?.showPopUp) setAgreementPopUp({ show: true });
      })
      .catch((error) => {
        // console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getCreditUserOnboardingPopUp();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getCreditUserOnboardingPopUp();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getCreditUserOnboardingPopUp();
            },
          });
        }
      });
  };

  //onLoad set the screen name
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "credit_shop_type_screen");
    getUserCreditData();
    getCreditUserOnboardingPopUp();
  }, []);

  //need to optimise this
  //bs code - wtf is wrong with you
  // useEffect(() => {
  //   getUserCreditData();
  // }, [selectedBusiness]);

  //reset the error log on active user input
  useEffect(() => {
    // checkFormDataValidation()
    setError(CreditFormData);
  }, [formData]);

  //handle screen name change when active step changes
  useEffect(() => {
    if (activeStep === 1)
      localStorage.setItem("currentScreen", "credit_basic_detail_screen");
    else if (activeStep === 0)
      localStorage.setItem("currentScreen", "credit_shop_type_screen");
  }, [activeStep]);

  //update uploadedDocumentsLinksRef for between renders
  // useEffect(() => {
  //   uploadedDocumentsLinksRef.current = uploadedDocumentsLinks;
  //   console.log(uploadedDocumentsLinksRef.current,'from useEffect after updating image upload links')
  // }, [uploadedDocumentsLinks]);

  //update form data if the form has been touched already
  useEffect(() => {
    let data = { ...formData };
    data.aadhar = creditData?.aadhar || "";
    data.email = creditData?.email || "";
    data.motherName = creditData?.motherName || "";
    data.ownerName = creditData?.name || "";
    data.panNumber = creditData?.panNumber || "";
    data.pinCode = creditData?.pinCode || "";
    //update formData state
    console.table(data);
    setFormData(data);
  }, [creditData]);

  //handle change response when coming from the response screen
  //this is already handled in onLoad
  //but onLoad doesn't run after the initial screen paint because response screen is only conditionally displayed
  useEffect(() => {
    // console.log("////////changeResponse///////", changeResponse);
    if (changeResponse) setActiveStep(0); //active step 0 sets the initial business type selection screen
    setchangeResponse(false);
  }, [changeResponse]);

  //update the ref with respective image url states for persistency between renders
  useEffect(() => {
    if (aadharFrontUrl.length > 0)
      uploadedDocumentsLinksRef.current["aadharFront"] = aadharFrontUrl;
    if (aadharBackUrl.length > 0)
      uploadedDocumentsLinksRef.current["aadharBack"] = aadharBackUrl;
    if (panFrontUrl.length > 0)
      uploadedDocumentsLinksRef.current["panFront"] = panFrontUrl;
  }, [aadharFrontUrl, aadharBackUrl, panFrontUrl]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={infoMessage.show}
        content={infoMessage.content}
        header={infoMessage.header}
        onHide={() => {
          setInfoMessage({ show: false, content: "", header: "" });
        }}
      />
      <AcceptCreditAgreementModal
        show={agreementPopUp.show}
        creditUserData={agreementPopUpData}
        onHide={() => {
          setAgreementPopUp({ show: false });
          navigate("/home");
        }}
      />

      <Container fluid className="apply-credit-parent-container font_ubuntu">
        {showReponseScreen ? (
          <ResponseScreen
            creditData={creditData}
            setchangeResponse={setchangeResponse}
            setShowReponseScreen={setShowReponseScreen}
          />
        ) : (
          <>
            <Container fluid className="stepper-container">
              <Row className="stepper-row">
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, _) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Row>
            </Container>
            <Container fluid className="apply-credit-main-container">
              <Container fluid className="apply-credit-main-wrapper">
                {activeStep === 0 ? (
                  <>
                    <Container fluid className="select-container">
                      <div className="select-business-wrapper">
                        <h3 style={{ fontWeight: "bold" }}>
                          Select Business Type
                        </h3>
                        <Form>
                          {storeTypes.map((item, index) => {
                            return (
                              <Form.Check
                                type="radio"
                                label={item}
                                id={index}
                                key={index}
                                value={+index + 1}
                                className="form-option"
                                checked={
                                  radioValue == +index + 1 ? true : false
                                }
                                onChange={handleRadioButton}
                              />
                            );
                          })}
                        </Form>
                      </div>

                      <button
                        type="submit"
                        className="next-variant"
                        onClick={postBusinessType}
                      >
                        {showSpinner ? <ButtonLoading1 /> : "NEXT"}
                      </button>
                    </Container>
                  </>
                ) : activeStep === 1 ? (
                  <>
                    <InputGroup className="apply-credit-text-input-container">
                      <FormControl
                        onChange={handleFormData}
                        onClick={() => callApiEvents("name_tap_credit")}
                        placeholder="Owner's Name"
                        type="text"
                        name="ownerName"
                        value={formData?.ownerName}
                        className={
                          error.ownerName
                            ? "input-text-apply-credit is-invalid"
                            : "input-text-apply-credit"
                        }
                      />
                    </InputGroup>
                    <p
                      style={{ marginTop: "-20px", color: "red" }}
                      className={error.ownerName ? "" : "bg-hidden"}
                    >
                      {" "}
                      Please enter the owner's name
                    </p>

                    <InputGroup className="apply-credit-text-input-container">
                      <FormControl
                        onChange={handleFormData}
                        onClick={() => callApiEvents("mothers_name_tap_credit")}
                        placeholder="Mother's Name"
                        type="text"
                        name="motherName"
                        value={formData?.motherName}
                        className={
                          error.motherName
                            ? "input-text-apply-credit is-invalid"
                            : "input-text-apply-credit"
                        }
                      />
                    </InputGroup>
                    <p
                      style={{ marginTop: "-20px", color: "red" }}
                      className={error.motherName ? "" : "bg-hidden"}
                    >
                      {" "}
                      Please enter the mother's name
                    </p>

                    <InputGroup className="apply-credit-text-input-container">
                      <FormControl
                        onChange={handleFormData}
                        onClick={() => callApiEvents("email_tap_credit")}
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={formData?.email}
                        className={
                          error.email
                            ? "input-text-apply-credit is-invalid"
                            : "input-text-apply-credit"
                        }
                      />
                    </InputGroup>
                    <p
                      style={{ marginTop: "-20px", color: "red" }}
                      className={error.email ? "" : "bg-hidden"}
                    >
                      {" "}
                      Invalid email format
                    </p>

                    <InputGroup className="apply-credit-text-input-container">
                      <FormControl
                        onChange={handleFormData}
                        onClick={() => callApiEvents("pincode_tap_credit")}
                        placeholder="Pin Code"
                        type="text"
                        name="pinCode"
                        value={formData?.pinCode}
                        className={
                          error.pinCode
                            ? "input-text-apply-credit is-invalid"
                            : "input-text-apply-credit"
                        }
                      />
                    </InputGroup>
                    <p
                      style={{ marginTop: "-20px", color: "red" }}
                      className={error.pinCode ? "" : "bg-hidden"}
                    >
                      {" "}
                      Invalid Pincode
                    </p>

                    <InputGroup className="apply-credit-text-input-container">
                      <FormControl
                        onChange={handleFormData}
                        onClick={() => callApiEvents("pan_tap_credit")}
                        placeholder="PAN Number"
                        type="text"
                        value={formData?.panNumber}
                        name="panNumber"
                        className={
                          error.panNumber
                            ? "input-text-apply-credit is-invalid"
                            : "input-text-apply-credit"
                        }
                      />
                    </InputGroup>
                    <p
                      style={{ marginTop: "-20px", color: "red" }}
                      className={error.panNumber ? "" : "bg-hidden"}
                    >
                      {" "}
                      Invalid PAN Number
                    </p>

                    <InputGroup className="apply-credit-text-input-container">
                      <FormControl
                        onChange={handleFormData}
                        onClick={() => callApiEvents("aadhar_tap_credit")}
                        placeholder="Aadhar Number"
                        type="number"
                        name="aadhar"
                        value={formData?.aadhar}
                        className={
                          error.aadhar
                            ? "input-text-apply-credit is-invalid"
                            : "input-text-apply-credit"
                        }
                      />
                    </InputGroup>
                    <p
                      style={{ marginTop: "-20px", color: "red" }}
                      className={error.aadhar ? "" : "bg-hidden"}
                    >
                      {" "}
                      Invalid aadhar Number{" "}
                    </p>

                    {/* aadhar front input start */}
                    <InputGroup className="document-upload-section1-group">
                      {docsUploaded.aadharFront ? (
                        <div className="apply-credit-input-document-preview-container apply-credit-input-document-container">
                          <div
                            className="preview-close-container"
                            onClick={() =>
                              handleDocsUploadedToggle("aadharFront")
                            }
                          >
                            <AiFillCloseCircle className="preview-close" />
                          </div>

                          <img
                            src={creditData?.aadharFrontUrl}
                            alt="aadhar front"
                            className="preview-image-credit"
                          />
                        </div>
                      ) : !imageURL?.aadharFront ? (
                        <div className="input-document-container apply-credit-input-document-container">
                          <input
                            className="file-input"
                            id="aadhar-front"
                            type="file"
                            name="aadharFront"
                            data-max-size="700"
                            onChange={handleFileInput}
                            onClick={() =>
                              callApiEvents("image_aadhar_front_credit")
                            }
                            accept="image/jpeg,image/jpg,image/png"
                          />
                          <label
                            className="image-container credit-document-input-label"
                            htmlFor="aadhar-front"
                          >
                            <AiOutlineUpload color="grey" size="2rem" />
                            UPLOAD AADHAR FRONT
                          </label>
                        </div>
                      ) : (
                        <div className="apply-credit-input-document-preview-container apply-credit-input-document-container">
                          <div
                            className="preview-close-container"
                            onClick={() => removeFileInput("aadharFront")}
                          >
                            <AiFillCloseCircle className="preview-close" />
                          </div>

                          <img
                            src={imageURL.aadharFront}
                            alt="aadhar front"
                            className="preview-image-credit"
                          />
                        </div>
                      )}
                      {/* aadhar front input end */}

                      {/* aadhar back input start */}
                      {docsUploaded.aadharBack ? (
                        <div className="apply-credit-input-document-preview-container apply-credit-input-document-container">
                          <div
                            className="preview-close-container"
                            onClick={() =>
                              handleDocsUploadedToggle("aadharBack")
                            }
                          >
                            <AiFillCloseCircle className="preview-close" />
                          </div>

                          <img
                            src={creditData?.aadharBackUrl}
                            alt="aadhar back"
                            className="preview-image-credit"
                          />
                        </div>
                      ) : !imageURL?.aadharBack ? (
                        <div className="input-document-container apply-credit-input-document-container">
                          <input
                            className="file-input"
                            id="aadhar-back"
                            type="file"
                            name="aadharBack"
                            data-max-size="700"
                            onChange={handleFileInput}
                            onClick={() =>
                              callApiEvents("image_aadhar_back_credit")
                            }
                            accept="image/jpeg,image/jpg,image/png"
                          />
                          <label
                            className="image-container credit-document-input-label"
                            htmlFor="aadhar-back"
                          >
                            <AiOutlineUpload color="grey" size="2rem" />
                            UPLOAD AADHAR BACK
                          </label>
                        </div>
                      ) : (
                        <div className="apply-credit-input-document-preview-container apply-credit-input-document-container">
                          <div
                            className="preview-close-container"
                            onClick={() => removeFileInput("aadharBack")}
                          >
                            <AiFillCloseCircle className="preview-close" />
                          </div>

                          <img
                            src={imageURL.aadharBack}
                            alt="aadhar front"
                            className="preview-image-credit"
                          />
                        </div>
                      )}
                    </InputGroup>
                    {/* aadhar back input end */}

                    <InputGroup className="document-upload-section2-container">
                      {docsUploaded.panFront ? (
                        <div className="upload-pan pan-front-preview-container">
                          <div
                            className="preview-close-container"
                            onClick={() => handleDocsUploadedToggle("panFront")}
                          >
                            <AiFillCloseCircle className="preview-close" />
                          </div>

                          <img
                            src={creditData?.panFrontUrl}
                            alt="pan front"
                            className="preview-image-credit-pan"
                          />
                        </div>
                      ) : !imageURL?.panFront ? (
                        <div className="input-document-container apply-credit-input-document-container upload-pan ">
                          <input
                            className="file-input"
                            id="pan-front"
                            type="file"
                            name="panFront"
                            data-max-size="700"
                            onChange={handleFileInput}
                            onClick={() =>
                              callApiEvents("image_pan_front_credit")
                            }
                            accept="image/jpeg,image/jpg,image/png"
                          />
                          <label
                            className="image-container credit-document-input-label"
                            htmlFor="pan-front"
                          >
                            <AiOutlineUpload color="grey" size="2rem" />
                            UPLOAD PAN FRONT
                          </label>
                        </div>
                      ) : (
                        <div className="upload-pan pan-front-preview-container">
                          <div
                            className="preview-close-container"
                            onClick={() => removeFileInput("panFront")}
                          >
                            <AiFillCloseCircle className="preview-close" />
                          </div>

                          <img
                            src={imageURL.panFront}
                            alt="pan front"
                            className="preview-image-credit-pan"
                          />
                        </div>
                      )}
                    </InputGroup>
                    <Form.Check
                      type="checkbox"
                      label="I accept that my details can be used to pull my credit bureau"
                      onChange={toggleUserAgreement}
                      checked={userAgreement}
                    ></Form.Check>
                    <div className="apply-credit-apply-button-wrapper">
                      <button
                        onClick={handleApplyCredit}
                        className="apply-credit-button"
                        // disabled={enabledSubmit() ? 'true' : 'false' }
                      >
                        {showSpinner && <ButtonLoading1 />}
                        {!showSpinner && "APPLY"}
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Container>
            </Container>
          </>
        )}
      </Container>
    </>
  );
}

export default ApplyCredit;
