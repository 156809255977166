import React, { Fragment, useEffect } from "react";
import "../Sliders/Slider.css";
import "../HomePage/HomePage.css";
import ProductCard from "../ProductCard/ProductCard";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { SiLinode } from "react-icons/si";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Common_Event_Hit } from "../../Actions/UserEvents";

const Slider2 = ({ flagBg, ProductRes, widgetData, shimmer ,flagColor}) => {
  const rightSroll2 = () => {
    let scrollStep = 400;
    let scrollDiv = document.getElementById("products_slider2");
    let sl = scrollDiv.scrollLeft;
    let cw = scrollDiv.scrollWidth;

    if (sl + scrollStep >= cw) {
      scrollDiv.scrollTo(cw, 0);
    } else {
      scrollDiv.scrollTo(sl + scrollStep, 0);
    }
    let scrollDiv2 = document.getElementById("products_slider3");
    let sl2 = scrollDiv2.scrollLeft;
    let cw2 = scrollDiv2.scrollWidth;

    if (sl2 + scrollStep >= cw2) {
      scrollDiv2.scrollTo(cw2, 0);
    } else {
      scrollDiv2.scrollTo(sl2 + scrollStep, 0);
    }
  };
  const leftScroll2 = () => {
    let scrollStep = 400;
    let scrollDiv = document.getElementById("products_slider2");
    let sl = scrollDiv.scrollLeft;

    if (sl - scrollStep <= 0) {
      scrollDiv.scrollTo(0, 0);
    } else {
      scrollDiv.scrollTo(sl - scrollStep, 0);
    }
    let scrollDiv2 = document.getElementById("products_slider3");
    let sl2 = scrollDiv2.scrollLeft;

    if (sl2 - scrollStep <= 0) {
      scrollDiv2.scrollTo(0, 0);
    } else {
      scrollDiv2.scrollTo(sl2 - scrollStep, 0);
    }
  };
  useEffect(() => {}, [shimmer]);
  return (
    <Fragment>
      <div className="slider2_parent">
        <div id="products_slider2" className="products_slide_view">
          {shimmer && (
            <>
              {[...Array(5)].map((_, index) => {
                return (
                  <Col className="padding_5" key={index}>
                    <ProductCard flagBg={flagBg} flagColor={flagColor} />{" "}
                  </Col>
                );
              })}
            </>
          )}

          {!shimmer &&
            ProductRes &&
            ProductRes?.medicinesResponsesList?.map((med, i) => {
              if (i % 2 == 0) {
                return (
                  <div className="padding_5" key={i}>
                    {" "}
                    <ProductCard
                      medData={med}
                      position={i}
                      flagBg={flagBg}
                      widgetData={widgetData}
                      flagColor={flagColor}
                    />{" "}
                  </div>
                );
              }
            })}
        </div>
        <div id="products_slider3" className="products_slide_view">
          {!shimmer &&
            ProductRes &&
            ProductRes?.medicinesResponsesList?.map((med, i) => {
              if (i % 2 !== 0) {
                return (
                  <div className="padding_5" key={i}>
                    {" "}
                    <ProductCard
                      position={i}
                      medData={med}
                      flagBg={flagBg}
                      widgetData={widgetData}
                      flagColor={flagColor}
                    />{" "}
                  </div>
                );
              }
            })}
        </div>
      </div>
      <div>
        {!shimmer && (
          <div className="center-con slider_footer2">
            <div className="center-con">
              <div className="round" onClick={leftScroll2}>
                <div id="cta">
                  <span className="arrowLeft primera next ">
                    {" "}
                    <FaChevronLeft
                      className="arrow_dark2"
                      style={{ paddingRight: "5px" }}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="arrowLeft segunda next ">
                    <FaChevronLeft className="arrow_light2" />
                  </span>
                </div>
              </div>
            </div>
            <Link
              className="text_decoration_none"
              to={{
                pathname: `/categories/specialOffers/DealOfTheDay`,
                state: { widgetData: widgetData && widgetData },
              }}
            >
              <div
                onClick={() => {
                  Common_Event_Hit("DYNAMIC_EVENT", {
                    eventName: "see_more_tap",
                    pageId: widgetData.data.pageId,
                  });
                }}
                className="arrow_dark2 font_ubuntu see_all_btn cursor_pointer font_weight_400"
                style={{ padding: "0px 60px", marginTop: "-2px" }}
              >
                SEE ALL DETAILS
              </div>
            </Link>
            <div className="center-con">
              <div className="round" onClick={rightSroll2}>
                <div id="cta">
                  <span className="arrow primera next ">
                    {" "}
                    <FaChevronRight
                      className="arrow_light2"
                      style={{ paddingRight: "5px" }}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="arrow segunda next ">
                    <FaChevronRight className="arrow_dark2" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default React.memo(Slider2);
