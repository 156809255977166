import React, { useContext, useEffect, useState } from "react";
import { Container, Dropdown, Spinner } from "react-bootstrap";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router";
import { AiOutlineRight } from "react-icons/ai";
import "./Issues.css";
import RaiseIssueModal from "../Modals/RaiseIssue/RaiseIssueModal";
import Button from "@mui/material/Button";
import IssueDetails from "../Modals/RaiseIssue/IssueDetails";
import {
  getIssueStatus,
  getIssueTypes,
  getIssuesByUser,
  getIssuesCountByUser,
} from "../../Actions/Support";
import { usersData } from "../../App";
import InfiniteScroll from "react-infinite-scroll-component";
import OpenState from "../../Images/svg/open_state_issue.svg";
import resolvedState from "../../Images/svg/resolved_state.svg";
import rejectedState from "../../Images/svg/rejected_state.svg";
import emptyScreen from "../../Images/svg/alert_empty_screen.svg";
import moment from "moment";
import { getRetailerCodesOnBehalf } from "../../Actions/OnBehalf";
import { MdOutlineCancel } from "react-icons/md";
import {
  Divider,
  FormControl,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import { FiSearch } from "react-icons/fi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Issues = () => {
  let navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState('');
  const [raiseIssue, setRaiseIssue] = useState(false);
  const [issueOpen, setIssueOpen] = useState({
    open: false,
    data: null,
    index: null,
  });
  const { setErrorHandler, userData } = useContext(usersData);
  const [filters, setFilters] = useState([]);
  const [issuesList, setIssuesList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loader, setLoader] = useState(false);
  const [dataCount, setDataCount] = useState(null);
  const [retailerCode, inputRetailerCode] = useState("");
  const [selectedretCode, setSelctedRetCode] = useState("");
  const [retailList, setRetailList] = useState([]);
  const [showRetailList, setShowRetailList] = useState(false);
  const [issueTypes, setIssueTypes] = useState([]);
  const [selectedIssueTypeFilter,setIssueTypeFilter]=useState('ALL')
  const fetchMoreData = () => {
    setPageNo(+pageNo + 1);
  };

  const getStatus = async () => {
    getIssueStatus()
      .then((res) => {
        setFilters(res.data.data);
        setActiveFilter(res.data.data[0]);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "raise_issues_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getIssueStatus({});
          },
        });
      });
  };

  const getIssueType = () => {
    getIssueTypes().then((res) => {
      let data = res.data.data;
      let index = data.findIndex((item) => item === "Ledger Updation");
      index >= 0 && data.splice(index, 1);
      setIssueTypes(data);
    });
  };

  const getIssuesCount = () => {
    let params = {
      status: activeFilter,
      userId: userData.id,
      ...(selectedIssueTypeFilter!=='ALL' && {issueType:selectedIssueTypeFilter})
    };
    getIssuesCountByUser(params)
      .then((res) => {
        setDataCount(res.data.data);
      })
      .catch((error) => {
        setLoader(false);
        setErrorHandler({
          show: true,
          screen: "raise_issues_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getIssuesCount({});
          },
        });
      });
  };

  const getIssues = async (reset = false) => {
    pageNo === 0 && setLoader(true);
    let params = {
      status: activeFilter,
      userId: userData.id,
      pageNo: reset ? 0 : pageNo,
      ...(selectedretCode?.length && { retailerCode: selectedretCode }),
      ...(selectedIssueTypeFilter!=='ALL' && {issueType:selectedIssueTypeFilter})
    };
    getIssuesByUser(params)
      .then((res) => {
        !reset && setIssuesList([...issuesList, ...res.data.data]);
        reset && setIssuesList(res.data.data);
        if (!res.data.data.length || res.data.data.length < 10) {
          setHasMore(false);
        }
        pageNo === 0 && setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setErrorHandler({
          show: true,
          screen: "raise_issues_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getIssues({});
          },
        });
      });
  };

  const updateIssueDataForReopen = (data, index) => {
    let list = issuesList;
    list.splice(index, 1);
    setIssuesList(list);
    setIssueOpen({ open: true, data: data, index: index });
  };

  const searchRetailer = async (search) => {
    inputRetailerCode(search.toUpperCase());
    if (search.length > 3) {
      const params = { searchKeyword: search };
      const res = await getRetailerCodesOnBehalf(params);

      if (res?.data?.data && res.data.data.length > 0) {
        setRetailList(res.data.data);
        setShowRetailList(true);
      } else if (res?.data?.data && res.data.data.length === 0) {
        setShowRetailList(false);
        setRetailList([]);
      }
    } else {
      if (showRetailList) {
        setShowRetailList(false);
        setRetailList([]);
      }
    }
  };

  const selectRetailer = async (retCode) => {
    inputRetailerCode(retCode.retailerCode);
    setSelctedRetCode(retCode.retailerCode);
    setShowRetailList(false);
    setRetailList([]);
  };

  const removeRetCode = () => {
    inputRetailerCode("");
    setSelctedRetCode("");
  };
  useEffect(() => {
    getStatus();
    getIssueType();
  }, []);

  useEffect(() => {
    setPageNo(0);
    setHasMore(true);
    userData && activeFilter && getIssues(true);
    userData && activeFilter && getIssuesCount();
  }, [activeFilter, userData, selectedretCode,selectedIssueTypeFilter]);

  useEffect(() => {
    pageNo > 0 && getIssues();
  }, [pageNo]);

  return (
    <Container fluid className="onbehalf_parent">
      {raiseIssue && (
        <RaiseIssueModal
          show={raiseIssue}
          onHide={(refresh) => {
            setRaiseIssue(false);
            refresh && setActiveFilter("Open");
            refresh && getIssues(true);
          }}
        />
      )}
      {issueOpen.open && (
        <IssueDetails
          show={issueOpen.open}
          data={issueOpen.data}
          index={issueOpen.index}
          updateDataForReopen={updateIssueDataForReopen}
          onHide={() => {
            setIssueOpen({ open: false, data: null, index: null });
          }}
        />
      )}

      <div className="onbehalf_header display_flex space_btw align_center">
        <div className="display_flex ">
          <div className="padding_5">
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate(-1)}
              style={{ marginTop: "0.5rem" }}
            />
          </div>

          <div
            className="go_back color_primary font_ubuntu font_weight_md no_wrap  padding_10"
            onClick={() => navigate(-1)}
          >
            Issues
          </div>
        </div>

        <div className="raise_issue_btn_mb">
          <Button
            variant="contained"
            className="bg_primary color_white no_wrap"
            onClick={() => setRaiseIssue(true)}
          >
            Raise an Issue
          </Button>
        </div>
      </div>

      <div className="onbehalf_body">
        <div className="issues_filters display_flex space_btw align_center">
          <div className="display_flex align_center">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="statusSelect">Select Status</InputLabel>
              <Select
                labelId="statusSelect"
                id=""
                value={activeFilter}
                onChange={(event) => setActiveFilter(event.target.value)}
                label="Select Status"
              >
                {filters.map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="issueTypeSelect">Select Issue Type</InputLabel>
              <Select
                labelId="issueTypeSelect"
                id=""
                value={selectedIssueTypeFilter}
                onChange={(event) => setIssueTypeFilter(event.target.value)}
                label="Select Issue Type"
              >
                <MenuItem value={'ALL'}>ALL</MenuItem>
                {issueTypes.map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>;
                })}
              </Select>
            </FormControl>

            {dataCount > 0 && (
              <div className="color_grey tickets_count">
                {" "}
                ● {dataCount} tickets
              </div>
            )}
          </div>

          <div>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
              className="retSearch"
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search By RetCode"
                onChange={(e) => searchRetailer(e.target.value)}
                inputProps={{ "aria-label": "Search by RetCode" }}
                value={retailerCode}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              {selectedretCode?.length > 0 ? (
                <IconButton
                  aria-label="Cancel"
                  component="label"
                  className="color_grey"
                  onClick={removeRetCode}
                >
                  <MdOutlineCancel />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="Cancel"
                  component="label"
                  className="color_grey"
                >
                  <FiSearch />
                </IconButton>
              )}
            </Paper>

            <div className="onBehalf_ret_list">
              <Dropdown.Menu show={showRetailList}>
                {retailList.map((retCode, indx) => {
                  return (
                    <div
                      class="dropdown_item"
                      key={indx}
                      onClick={() => selectRetailer(retCode)}
                    >
                      {retCode.retailerCode}
                      <span>{retCode.shopName}</span>
                    </div>
                  );
                })}
              </Dropdown.Menu>
            </div>
          </div>
        </div>

        <div id="issuesData" className="issues_tickets_section">
          {loader && (
            <div className="issues_list_div">
              {[1, 2, 3, 4, 5, 6].map(() => {
                return (
                  <div className="issue_ticket_card cursor_pointer animateShimmer2">
                    <div className="display_flex space_btw align_center issue_ticket_header">
                      <div>
                        <div className="display_flex"></div>
                        <div className="issue_ticket_desc"></div>
                      </div>
                      <div>
                        <AiOutlineRight className="margin_10 color_grey" />
                      </div>
                    </div>
                    <div className="display_flex space_btw issue_ticket_footer">
                      <p className="font_14"></p>
                      <p className="font_14"></p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!loader && issuesList?.length > 0 ? (
            <InfiniteScroll
              dataLength={issuesList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollThreshold="90%"
              scrollableTarget="issuesData"
              loader={
                <Spinner
                  animation="border"
                  className="color_secondary spinner"
                  size="lg"
                />
              }
            >
              <div className="issues_list_div">
                {issuesList.map((issue, index) => {
                  return (
                    <div
                      className="issue_ticket_card cursor_pointer"
                      onClick={() =>
                        setIssueOpen({ open: true, data: issue, index: index })
                      }
                    >
                      <div className="display_flex space_btw align_center issue_ticket_header">
                        <div>
                          <div className="display_flex">
                            <b>{issue.retailerCode}</b>&nbsp; ● &nbsp;
                            <div className="issue_ticket_store_name">
                              {issue.retailerName}
                            </div>
                          </div>

                          <div className="issue_ticket_desc">
                            {issue.description}
                          </div>
                        </div>
                        <div>
                          <AiOutlineRight className="margin_10 color_grey" />
                        </div>
                      </div>
                      <div className="display_flex space_btw issue_ticket_footer">
                        <div>
                          {" "}
                          <span className="font_14">Issue ID: {issue.id}</span>
                          <b className="font_12 color_grey">
                            &nbsp; &nbsp; Created at{" "}
                            {moment(issue.createdTimestamp).format(
                              "MMMM DD YYYY, hh:mm A"
                            )}
                          </b>
                        </div>

                        <p className="font_14 no_wrap">
                          <img
                            src={
                              issue.status === "Open"
                                ? OpenState
                                : issue.status === "Solved"
                                ? resolvedState
                                : issue.status === "Rejected"
                                ? rejectedState
                                : OpenState
                            }
                          />{" "}
                          {issue.status}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          ) : (
            !loader &&
            activeFilter && (
              <>
                <div className="emptyIssues_screen">
                  <div>
                    <img src={emptyScreen} className="emptyIssues_screen_img" />
                    <div className="emptyIssues_screen_desc">
                      {activeFilter === "All" ? (
                        <>
                          <p>Looks like you havent reported any issues yet.</p>
                          <p>
                            Click "Raise an Issue" to let us know of any
                            problems
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Looks like you dont have any{" "}
                            {activeFilter?.toLowerCase()} issues yet.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </Container>
  );
};

export default Issues;
