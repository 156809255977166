import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdAttachFile, MdDelete, MdRefresh } from "react-icons/md";
import ImageViewer from "react-simple-image-viewer";
import cx from "classnames";
import {
  sendMsg,
  getConversationByUser,
  uploadMultiImages,
  updateTicketStatus,
} from "../../../Actions/Support";
import InputChange from "../../../CustomHooks/InputChange";
import { usersData } from "../../../App";
import { AiFillCloseCircle } from "react-icons/ai";
import moment from "moment";
import ReasonInput from "../ReasonInput";

const IssueDetails = (props) => {
  const imageEndPoints = [".jpg", ".png", ".jpeg", ".webp", ".svg"];
  const [activeStep, setActiveStep] = useState(1);
  const [chatImages, setChatImages] = useState([]);
  const [pastImages, setPastImages] = useState([]);
  const [imgViewer, setImgViewer] = useState({ open: false, currentImage: 0 });
  const [msg, setMsg] = useState();
  const [chat, setChat] = useState({});
  const [latestRemark, setLatestRemark] = useState();
  const { setErrorHandler, userData } = useContext(usersData);
  const [convoLoading, setConvoLoading] = useState(false);
  const [openRemarks,setRemarksModal]=useState(false);
  const [steps, setSteps] = useState([
    { label: "Open" },
    { label: "In Progress" },
    { label: "Solved" },
  ]);
  const [chatImageViewer, setChatImageViewer] = useState({
    open: false,
    data: [],
  });
  const addImages = (event) => {
    setChatImages([...chatImages, ...event.target.files]);
  };
  const deleteImage = (file) => {
    let index = chatImages.findIndex((ele) => file.name === ele.name);
    if (index >= 0) {
      let data = [...chatImages];
      data.splice(index, 1);
      setChatImages(data);
    }
  };

  const getConversation = () => {
    setConvoLoading(true);
    let params = {
      userIssueId: props?.data?.id,
    };
    getConversationByUser(params).then((res) => {
      setConvoLoading(false);
      let data = res.data.data;
      //    getting latest admin remarks
      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].identifier === "ADMIN") {
          data[i].attachement ? setLatestRemark(data[i].conversation.split('/').slice(-1)):setLatestRemark(data[i].conversation);
          break;
        }
      }
      let obj = {};
      data?.forEach((ele) => {
        let date = new Date(ele.createdTimestamp).toLocaleString("default", {
          day: "numeric",
          month: "long",
        });
        if (date in obj) {
          obj[date].push(ele);
        } else {
          obj[date] = [ele];
        }
      });
      setChat(obj);
      var chatDiv = document.getElementById("chat_section");
      chatDiv.scrollTop = chatDiv.scrollHeight;
    });
  };

  const sendReply = () => {
    if (msg?.length) {
      let body = {
        attachement: false,
        conversation: msg,
        identifier: "RETAILER",
        userId: userData.id,
        userIssueId: props.data.id,
      };

      sendMsgApi(body);
    }

    if (chatImages?.length) {
      let body = new FormData();
      chatImages?.forEach((file) => {
        body.append("file", file);
      });
      uploadMultiImages(body)
        .then((res) => {
          let body = {
            attachement: true,
            conversation: res.data.data[0],
            identifier: "RETAILER",
            userId: userData.id,
            userIssueId: props.data.id,
          };

          sendMsgApi(body);
        })
        .catch((error) => {
          setErrorHandler({
            show: true,
            screen: "create_issue_screen",
            errorList: [{ error: error, code: "" }],
            fn: function () {
              uploadMultiImages(body);
            },
          });
        });
    }
  };

  const sendMsgApi = (body) => {
    sendMsg(body)
      .then((res) => {
        getConversation();
        setMsg("");
        setChatImages([]);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "issue_details_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            sendMsg(body);
          },
        });
      });
  };

  const remarksForReopen=()=>{
       setRemarksModal(true)
  }

  const reopenTicket = (reason="",id=null,index=null) => {
    setRemarksModal(false)
    let params = {
      status: "Request to Reopen",
      userIssueId: props.data.id,
    };
    updateTicketStatus(params)
      .then((res) => {
        let body = {
          attachement: false,
          conversation: `Requested to reopen ticket: ${reason}`,
          identifier: "RETAILER",
          userId: userData.id,
          userIssueId: props.data.id,
        };

        sendMsgApi(body);
        props.updateDataForReopen(res.data.data, props.index);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "issue_details_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            reopenTicket(reason);
          },
        });
      });
  };

  useEffect(() => {
    if (props.data) {
      let images = [];
      props.data?.attachementsList?.forEach((img) => {
        images.push(img.attachementLink);
      });
      setPastImages(images);

      if (props.data.status === "Rejected") {
        let stepsArray = [...steps];
        stepsArray[2].label = "Rejected";
        setSteps(stepsArray);
        setActiveStep(2);
      } else if (props.data.status === "Open") {
        setActiveStep(0);
      } else if (props.data.status === "In Progress") {
        setActiveStep(1);
      } else if (props.data.status === "Solved") {
        let stepsArray = [...steps];
        stepsArray[2].label = "Solved";
        setSteps(stepsArray);
        setActiveStep(2);
      } else {
        setActiveStep(1);
      }

      getConversation();
    }
  }, [props.data]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
      // backdrop="static"
    >
      {imgViewer.open && (
        <ImageViewer
          src={pastImages}
          currentIndex={imgViewer.currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setImgViewer({ open: false, currentImage: 0 })}
        />
      )}
      {chatImageViewer.open && (
        <ImageViewer
          src={chatImageViewer.data}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setChatImageViewer({ open: false, data: [] })}
        />
      )}

      {
        remarksForReopen && <ReasonInput
        show={openRemarks}
        content={"Please Type Reason"}
        data={null}
        reasonSelect={reopenTicket}
        onHide={() => {
          setRemarksModal(false);
        }}
      />
      }

      <div className="issue_details_modal">
        <div className="text_center raise_issue_header">
          <h5 className="color_primary">
            {" "}
            <b>Issue Details</b>
          </h5>
          <AiFillCloseCircle
            className="cursor_pointer cancel_issue_details"
            onClick={props.onHide}
          />
        </div>
        <div className="raise_issue_body">
          <div>
            <h6>
              <u>
                {" "}
                <b>Issue Details</b>
              </u>
            </h6>
          </div>

          <br />
          <div className="display_flex flex_wrap">
            <div className="issue_desc">
              <div className="issue_info bg_white">
                <b className="font_14">{props.data.retailerCode}</b>
                <p className="font_12">{props.data.retailerName}</p>

                <b className="font_14">{props.data.issueType}</b>
                <p className="font_12">{props.data.description}</p>

                <b className="font_14">Attachments</b>

                <div className="images_div">
                  {pastImages.map((img, i) => {
                    const fileName = img.split("/").pop();
                    if (imageEndPoints.some((el) => fileName.includes(el))) {
                      return (
                        <img
                          src={img}
                          onClick={() =>
                            setImgViewer({ open: true, currentImage: i })
                          }
                        />
                      );
                    } else {
                      return (
                        <a
                          href={img}
                          target="_blank"
                          className="font_10 doc_view_btn" rel="noreferrer"
                        >
                          {fileName}
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
              <div
                className={cx(
                  { resolved_state: props?.data?.status === "Solved" },
                  { open_state: props?.data?.status === "Open" },
                  { rejected_state: props?.data?.status === "Rejected" },
                  { progress_state: props?.data?.status === "In Progress" }
                )}
              >
                <b className="font_14">
                  {props?.data?.status?.charAt(0).toUpperCase() +
                    props?.data?.status?.slice(1)}
                </b>
                <p>{latestRemark}</p>
              </div>
            </div>
            <div className="stepper_div">
              <div>
                <h6>
                  <u>
                    {" "}
                    <b>Track Issue</b>
                  </u>
                </h6>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps?.length &&
                    steps?.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    ))}
                </Stepper>
              </div>
            </div>
          </div>

          <div>
            <br />
            <h6>
              <u>
                {" "}
                <b>Conversation & Description </b>&nbsp;&nbsp;
                {convoLoading && <CircularProgress size="15px" />}
                {!convoLoading && (
                  <MdRefresh
                    onClick={getConversation}
                    style={{ width: "30px", fontSize: "20px" }}
                    className="color_primary cursor_pointer"
                  />
                )}
              </u>
            </h6>
            <div className="chat_div" id="chat_section">
              {Object.keys(chat).map((key) => {
                return (
                  <div style={{ width: "100%" }}>
                    <div className="chat_date">{key}</div>
                    {chat[key].map((msg) => {
                      if (msg.attachement) {
                        const fileName = msg?.conversation?.split("/").pop();
                        return (
                          <div
                            className={cx({
                              chat_msg_ret: msg.identifier === "RETAILER",
                              chat_msg_admin: msg.identifier === "ADMIN",
                            })}
                          >
                          {/* checking if file is image or other type  */}
                            {imageEndPoints.some((el) =>
                              fileName.includes(el)
                            ) ? (
                              <img
                                src={msg.conversation}
                                className="chat_img"
                                onClick={() =>
                                  setChatImageViewer({
                                    open: true,
                                    data: [msg.conversation],
                                  })
                                }
                              />
                            ) : (
                              <div className="chat_doc_link">
                                <a
                                  href={msg.conversation}
                                  target="_blank"
                                  className="font_10 " rel="noreferrer"
                                >
                                  {fileName}
                                </a>
                              </div>
                            )}

                            <div className="chat_timestamp">
                              {moment(msg.createdTimestamp).format("h:mm A")}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className={cx({
                              chat_msg_ret: msg.identifier === "RETAILER",
                              chat_msg_admin: msg.identifier === "ADMIN",
                            })}
                          >
                            <span  dangerouslySetInnerHTML={{
                                __html: `${msg.conversation}`,
                              }}></span>
                            <div className="chat_timestamp">
                              {moment(msg.createdTimestamp).format("h:mm A")}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <br />
          <div className="issue_details_footer">
            {props?.data?.status !== "Solved" ? (
              <div className="display_flex content_center">
                <div>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="send_msg_footer"
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Type here"
                      onChange={(e) => InputChange(setMsg, e.target.value)}
                      inputProps={{ "aria-label": "search google maps" }}
                      value={msg}
                    />
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      aria-label="Upload image"
                      component="label"
                      className="color_primary"
                    >
                      <input
                        hidden
                        accept="image/*,.csv,.xlsx,.pdf"
                        onChange={addImages}
                        type="file"
                      />
                      <MdAttachFile />
                    </IconButton>
                  </Paper>
                </div>

                <Button
                  variant="contained"
                  className="bg_primary send_btn_issue_details color_white font_12"
                  onClick={sendReply}
                >
                  Send
                </Button>
              </div>
            ) : (
              <div className="display_flex content_center">
                <Button
                  variant="contained"
                  className=" no_wrap  font_12"
                  onClick={remarksForReopen}
                >
                  Request To Reopen
                </Button>
              </div>
            )}

            <div className="display_flex align_center flex_wrap">
              {chatImages.map((file) => {
                return (
                  <div
                    className="display_flex align_center brdr_grey"
                    style={{ marginRight: "10px" }}
                  >
                    <div className="issue_attach_image font_12">
                      {file.name}{" "}
                    </div>{" "}
                    <MdDelete
                      className="cursor_pointer"
                      onClick={() => deleteImage(file)}
                    />{" "}
                    &nbsp;
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IssueDetails;
