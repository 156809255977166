import { Modal, Spinner, Container } from "react-bootstrap";
import React, { useState, useEffect, Fragment, useContext } from "react";
import "../../App.css";
import "./dialog.css";
import "../ProductCard/ProductCard.css";
import { BiBlock } from "react-icons/bi";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { getData } from "../../Actions/Product";
import { useLocation, useNavigate } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { GiAlarmClock } from "react-icons/gi";
import { cartDataContext, forceBackNavContext, usersData } from "../../App";
import { Cart_Actions } from "../../Actions/Cart";
import ErrorModal from "./ErrorModal";
import Thumb from "../../Images/svg/Thumb.svg";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import OrderMessageModal from "./orderMessageModal";

const Product = () => {
  const [medData, setMedData] = useState();
  const [quantity, setQuantity] = useState(1);
  const [priceData, setPriceData] = useState();
  const [spinner, setSpinner] = useState({ totalPrice: false });
  const { setShowMaintenance } = useContext(usersData);
  const [userData, setUserData] = useState();
  const [shimmer, setShimmer] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [timerProductTime, setTimerProductTime] = useState({
    endTime: 1,
    stopWatch: "00:00:00",
  });
  const [multiplyFactorForOrderQuantity, setmMltiplyFactorForOrderQuantity] =
    useState(1);
  const [cartSpinner, setCartSpinner] = useState(false);
  const [maxStockError, setMaxStockError] = useState(false);
  const { cartData, setCartData } = useContext(cartDataContext);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { setForceBackNav } = useContext(forceBackNavContext);

  const addQuantity = (e) => {
    e.stopPropagation();
    setMaxStockError(false);
    if (medData.maxOrderQuantity > 0 && quantity < medData.maxOrderQuantity) {
      let updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      if (updatedQuantity <= medData.availableQuantity) {
        setQuantity(updatedQuantity);
      } else {
        setMaxStockError(true);
      }
    } else if (medData.maxOrderQuantity === 0) {
      let updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      if (updatedQuantity <= medData.availableQuantity) {
        setQuantity(updatedQuantity);
      } else {
        setMaxStockError(true);
      }
    }

    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "count_increment_tap",
      medicineId: medData.id,
      medicineName: medData.name,
      price: medData.effectivePrice.toFixed(2),
      count: quantity + multiplyFactorForOrderQuantity,
    });
  };
  const minusQuantity = (e) => {
    e.stopPropagation();
    if (quantity > 1 && quantity > medData.minOrderQuantity) {
      setQuantity(quantity - multiplyFactorForOrderQuantity);

      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "count_decrement_tap",
        medicineId: medData.id,
        medicineName: medData.name,
        price: medData.effectivePrice.toFixed(2),
        count: quantity - multiplyFactorForOrderQuantity,
      });
    }
  };

  const qtyChange = (e) => {
    if (e.target.value) {
      setQuantity(+e.target.value);
    } else {
      setQuantity(e.target.value);
    }
  };

  let location = useLocation();
  let navigate = useNavigate();

  const getProductPrice = () => {
    setSpinner({ totalPrice: true });

    const params = {
      medicineId: location.pathname.split("/")[2],
    };
    if (quantity === 0 || quantity > 0) {
      params.quantity = quantity ? quantity : 0;
    } else {
      params.quantity = 0;
    }
    getData("PRODUCTPRICE_BY_ID", params)
      .then((res) => {
        setPriceData(res.data.data);
        if (
          quantity + res.data.data?.freeQuantity >
          medData?.availableQuantity
        ) {
          setMaxStockError(true);
        } else {
          setMaxStockError(false);
        }
        setSpinner({ totalPrice: false });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getProductPrice();
          });
        } else if (
          error?.response?.status >= 400 &&
          error?.response?.status < 500
        ) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.data?.message,
            header: `Info`,
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong !",
            tryAgain: function () {
              getProductPrice();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.message
              ? error.response.data?.message
              : "Something went wrong!",
            tryAgain: function () {
              getProductPrice();
            },
          });
        }
      });
  };
  const addToCart = async (qty) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "confirm_add_to_cart",
      medicineId: medData.medicineId,
      medicineName: medData.name,
      price: medData.effectivePrice.toFixed(2),
      count: qty,
    });

    if (medData.deliverySlot) {
      setCartSpinner(true);
      const params = {};
      const body = {
        androidMap: {},
        medicineId: medData.medicineId,
        quantity: qty,
        retailerCode: userData.retailerCode,
        retailerId: userData.id,
      };
      Cart_Actions("ADD_TO_CART", params, body)
        .then((res) => {
          setQuantity(qty);
          setCartSpinner(false);
          setCartData(res.data.data);
        })
        .catch((error) => {
          setCartSpinner(false);
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              addToCart(qty);
            });
          } else if (
            error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504
          ) {
            setShowMaintenance(true);
          } else if (error?.response?.status === 500) {
            setErrorModal({
              show: true,
              content: "Oops something went wrong !",
              tryAgain: function () {
                addToCart(qty);
              },
            });
          } else {
            setErrorModal({
              show: true,
              content: error?.response?.data.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                addToCart(qty);
              },
            });
          }
        });
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };
  useEffect(() => {
    let data = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "product_screen");
    localStorage.removeItem("currentScreenBannerData");

    setShimmer(true);
    if (data && data.length) {
      setUserData(JSON.parse(data));
      const params = {
        medicineId: location.pathname.split("/")[2],
        dealType: null,
      };

      const getProduct = () => {
        getData("PRODUCT_BY_ID", params)
          .then((res) => {
            setShimmer(false);
            if (
              res.data.data &&
              res.data.data.multiplyFactorForOrderQuantity > 0
            ) {
              setmMltiplyFactorForOrderQuantity(
                res.data.data.multiplyFactorForOrderQuantity
              );
            }
            setMedData(res.data.data);
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshToken().then((res) => {
                getProduct();
              });
            } else if (
              error?.response?.status >= 400 &&
              error?.response?.status < 500
            ) {
              setOrderMessage({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error,
                header: `Info`,
              });
            } else if (
              error?.response?.status === 502 ||
              error?.response?.status === 503 ||
              error?.response?.status === 504
            ) {
              setShowMaintenance(true);
            } else if (error?.response?.status === 500) {
              setErrorModal({
                show: true,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  getProduct();
                },
              });
            } else {
              setErrorModal({
                show: true,
                content: error?.response?.data.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  getProduct();
                },
              });
            }
          });
      };

      getProduct();
      //  "freeQuantity": 0,
      //  "message": "string",
      //  "netPrice": 0,
      //  "retailerMargin": 0,
      //  "subTotal": 0
    } else {
      navigate("/login");
    }
  }, [location]);

  useEffect(() => {
    getProductPrice();
  }, [quantity, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (cartData && medData) {
      if (cartData.cartMedicineDetailResponseList) {
        let existingCartItem = cartData.cartMedicineDetailResponseList.filter(
          (item) => item.medicineId === medData.medicineId
        );
        if (existingCartItem && existingCartItem.length) {
          setQuantity(existingCartItem[0].quantity);
        } else {
          if (medData.minOrderQuantity > 0) {
            setQuantity(medData.minOrderQuantity);
          } else if (medData.multiplyFactorForOrderQuantity > 0) {
            setQuantity(medData.multiplyFactorForOrderQuantity);
          } else {
            setQuantity(1);
          }
        }
      }
    } else if (medData) {
      if (medData.minOrderQuantity > 0) {
        setQuantity(medData.minOrderQuantity);
      } else if (medData.multiplyFactorForOrderQuantity > 0) {
        setQuantity(medData.multiplyFactorForOrderQuantity);
      } else {
        setQuantity(1);
      }
    }
  }, [cartData, medData]);

  useEffect(() => {
    if (medData?.dealEndTime) {
      let timer;
      if (currentTime < medData.dealEndTime) {
        timer = setInterval(() => {
          setCurrentTime(new Date().getTime());
        }, 1000);
        function msToTime(s) {
          var ms = s % 1000;
          s = (s - ms) / 1000;
          var secs = s % 60;
          s = (s - secs) / 60;
          var mins = s % 60;
          var hrs = (s - mins) / 60;
          if (hrs < 10) {
            hrs = "0" + hrs;
          }
          if (mins < 10) {
            mins = "0" + mins;
          }
          if (secs < 10) {
            secs = "0" + secs;
          }

          let obj = { ...timerProductTime };
          obj.stopWatch = hrs + ":" + mins + ":" + secs;
          setTimerProductTime(obj);
        }
        msToTime(medData.dealEndTime - currentTime);
      } else {
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [currentTime, medData]);

  // handler to force navigate from banner redirect
  const handleForceBackNav = () => {
    if (location?.state?.from === "/bannerRedirect") setForceBackNav(true);
    navigate(-1);
  };

  // intercept browser back button click with custom logic to avoid banner redirect
  const onPageBackEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (location?.state?.from === "/bannerRedirect") setForceBackNav(true);
    navigate(-1);
  };

  // onMount register a brower back button click listener
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onPageBackEvent);
    return () => {
      window.removeEventListener("popstate", onPageBackEvent);
    };
  }, []);

  return (
    <div>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
          handleForceBackNav();
        }}
      />
      {shimmer && (
        <div className="product-parent-wrapper">
          <Container
            className="animateShimmer2 product_parent_div productShimmer_div"
            centered
          ></Container>
        </div>
      )}
      <div className="product-parent-wrapper">
        <Container className="product_parent_div">
          {medData && (
            <div>
              <HiOutlineArrowNarrowLeft
                className="cursor_pointer goBack margin_10px web_display"
                onClick={handleForceBackNav}
              />
              <img
                src={
                  medData?.medicineImage?.highResUrl
                    ? medData.medicineImage.highResUrl
                    : Thumb
                }
                alt="Medicine"
                className="product_image showImage_web"
              />
            </div>
          )}
          {medData && priceData && (
            <div className="product_data">
              <Modal.Header className="bg_primary">
                <div style={{ width: "100%" }}>
                  <div className="display_flex space_btw">
                    <img
                      src={
                        medData?.medicineImage?.lowResUrl
                          ? medData.medicineImage.lowResUrl
                          : Thumb
                      }
                      alt="Medicine"
                      className="product_image showImage_mob"
                    />

                    <div className="header_data">
                      <div className="tags display_flex space_btw">
                        {medData.medicineSubSectionType === "FlashSale" ? (
                          <div className="flashSale_tag font_12 padding_7">
                            <GiAlarmClock
                              style={{ fontSize: "14px", marginTop: "-1px" }}
                            />
                            FLASH SALE
                          </div>
                        ) : medData.medicineSubSectionType ===
                          "DealOfTheDay" ? (
                          <div className="deal_tag color_primary font_12 padding_7">
                            <GiAlarmClock
                              style={{ fontSize: "14px", marginTop: "-1px" }}
                            />{" "}
                            DEAL OF THE DAY
                          </div>
                        ) : medData.medicineSubSectionType ===
                          "Special_Rate" ? (
                          <div className="special_tag color_white font_12 padding_7">
                            SPECIAL DEAL
                          </div>
                        ) : (
                          <div className="deal_tag color_primary font_12 padding_7">
                            {medData.medicineSubSectionType}
                          </div>
                        )}
                        {medData.multiplyFactorForOrderQuantity > 0 && (
                          <div className="multiplyFactor_tag font_12 color_primary bg_white padding_10">
                            BUY IN MULTIPLE OF{" "}
                            {medData.multiplyFactorForOrderQuantity}
                          </div>
                        )}
                        {medData?.dealEndTime && (
                          <div className="timer_tag_product">
                            <GiAlarmClock
                              style={{ fontSize: "14px", marginTop: "-1px" }}
                            />
                            <span className="font_12">
                              {timerProductTime.stopWatch}
                            </span>{" "}
                            &nbsp;<span className="font_12">hrs left</span>
                          </div>
                        )}
                      </div>

                      <p className="med_name font_ubuntu">{medData.name}</p>
                      <p
                        className="font_weight_lt manf_name"
                        style={{ color: "rgba(255, 255, 255, 0.781)" }}
                      >
                        {medData.packaging} •{" "}
                        <span className="color_secondary font_weight_md">
                          {" "}
                          {medData.manufacturer}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="display_flex">
                    {medData.tags &&
                      medData.tags.map((tag) => {
                        return (
                          <div className="returns color_grey font_weight_md nowrap">
                            {tag.iconCode === "iconRestricted" && (
                              <BiBlock className="color_secondary" />
                            )}{" "}
                            {tag.message}
                          </div>
                        );
                      })}
                    &nbsp;&nbsp;&nbsp;
                    {medData.scheme && medData.scheme.length > 0 && (
                      <span
                        className="deals_section display_flex web_display"
                        style={{
                          marginTop: "-15px",
                          height: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span className="color_grey font_12">DEALS :</span>
                        {medData.scheme.map((x) => {
                          return (
                            <div className="deal_card color_primary font_12">
                              {x}{" "}
                            </div>
                          );
                        })}
                      </span>
                    )}
                  </div>
                  <div
                    className="display_flex mobile_display"
                    style={{ marginTop: "30px" }}
                  >
                    {medData.scheme && medData.scheme.length > 0 && (
                      <span
                        className="deals_section display_flex"
                        style={{
                          marginTop: "-15px",
                          height: "fit-content",
                          whiteSpace: "nowrap",
                          padding: "3px",
                        }}
                      >
                        <span className="color_grey font_12">DEALS :</span>
                        {medData.scheme.map((x) => {
                          return (
                            <div className="deal_card color_primary font_12">
                              {x}{" "}
                            </div>
                          );
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="prd_dialog_body">
                <p className="font_12  font_ubuntu font_weight_md">
                  <span className="color_grey">Delivery :</span>{" "}
                  {medData.deliveryDate},{medData.deliverySlot}
                </p>
                <div className="body_inner_div">
                  <div
                    style={{ margin: "10px", padding: "10px 10px 0px 10px" }}
                    closeButton
                  >
                    <div
                      className="display_flex space_btw header_ptr_modal border_bottom"
                      id="contained-modal-title-vcenter"
                    >
                      <div className="left_top_sec">
                        <p className="color_grey_3 font_ubuntu font_weight_md font_12">
                          MRP
                        </p>
                        <p className="color_grey_1 font_ubuntu font_weight_lg">
                          ₹ {medData.mrp}
                        </p>
                      </div>
                      <div className="border_radius_5 bg_light_green color_primary text_center top_center_sec">
                        <span className="font_weight_md text_center font_12">
                          {Math.round(priceData.retailerMargin)}%
                        </span>{" "}
                        <br />
                        <span
                          style={{ fontSize: "9px" }}
                          className="color_primary"
                        >
                          MARGIN
                        </span>
                      </div>
                      <div className="left_top_sec">
                        <p className="color_grey_3 font_ubuntu font_weight_md font_12">
                          NET PRICE
                        </p>
                        <p className="color_grey_1 font_ubuntu font_weight_lg">
                          {" "}
                          <span className="color_black font_weight_lg">
                            ₹{" "}
                            {
                              priceData.netPrice
                                .toFixed(2)
                                .toString()
                                .split(".")[0]
                            }
                          </span>
                          <span style={{ fontSize: "10px" }}>
                            .
                            {
                              priceData.netPrice
                                .toFixed(2)
                                .toString()
                                .split(".")[1]
                            }
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "0px" }} className="border_bottom">
                    {/* <div
                    style={{ padding: "0px 20px" }}
                    className="display_flex space_btw"
                  >
                    <div className="font_14">PTR</div>
                    <p className="color_grey_1 font_ubuntu font_weight_lg">
                      {" "}
                      <span className="color_black font_weight_lg">
                        + ₹{" "}
                        {
                          medData.effectivePrice
                            .toFixed(2)
                            .toString()
                            .split(".")[0]
                        }
                      </span>
                      <span style={{ fontSize: "10px" }}>
                        .
                        {
                          medData.effectivePrice
                            .toFixed(2)
                            .toString()
                            .split(".")[1]
                        }
                      </span>
                    </p>
                  </div> */}
                    {/* <div className="dialog_cd_sec display_flex space_btw">
                    <div className="font_ubuntu font_weight_lg">
                      Cash Discount ({medData.cashDiscountInPercentage}%)
                    </div>
                    <div className=" font_ubuntu font_weight_lg">
                      {" "}
                      <span className="font_weight_lg">
                        - ₹{" "}
                        {
                          medData.cashDiscount
                            .toFixed(2)
                            .toString()
                            .split(".")[0]
                        }
                      </span>
                      <span style={{ fontSize: "10px" }}>
                        .
                        {
                          medData.cashDiscount
                            .toFixed(2)
                            .toString()
                            .split(".")[1]
                        }
                      </span>
                    </div>
                  </div> */}
                    {priceData?.breakup.map((item, i) => {
                      return (
                        <div
                          className={
                            item?.focus
                              ? "dialog_cd_sec display_flex space_btw"
                              : "display_flex space_btw"
                          }
                          style={{ padding: "10px 20px" }}
                          key={i}
                        >
                          <div className="font_ubuntu font_weight_lg font_12">
                            {item?.key} {item.asterisk && <>&#x2a;</>}
                          </div>
                          <div className=" font_ubuntu font_weight_lg font_12">
                            {" "}
                            <span className="font_weight_lg">
                              {item.value > 0 && "+"} ₹{" "}
                              {typeof item.value !== "string" &&
                                item?.value
                                  ?.toFixed(2)
                                  .toString()
                                  .split(".")[0]}
                            </span>
                            <span style={{ fontSize: "10px" }}>
                              .
                              {typeof item.value !== "string" &&
                                item?.value
                                  ?.toFixed(2)
                                  .toString()
                                  .split(".")[1]}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className="width_100 display_flex space_btw"
                    style={{ padding: "10px 20px" }}
                  >
                    <div className="font_ubuntu font_weight_lg font_14">
                      Net Price
                    </div>
                    <div className=" font_ubuntu font_weight_lg font_14">
                      {" "}
                      <span className="font_weight_lg">
                        + ₹{" "}
                        {priceData.netPrice.toFixed(2).toString().split(".")[0]}
                      </span>
                      <span style={{ fontSize: "10px" }}>
                        .
                        {priceData.netPrice.toFixed(2).toString().split(".")[1]}
                      </span>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ padding: "0px" }}>
                <div className="footer_sec_1 display_flex space_btw font_ubuntu">
                  <div>
                    <span className="color_black font_weight_md" id="inStock">
                      {" "}
                      {medData.availableQuantity}{" "}
                    </span>{" "}
                    <span className="color_grey font_weight_md font_12">
                      &nbsp; &nbsp;IN STOCK{" "}
                    </span>
                  </div>
                  <div>
                    <span className="color_grey font_weight_md font_12">
                      {" "}
                      Expiry{" "}
                    </span>{" "}
                    <span className="color_green font_weight_md font_12">
                      &nbsp; {medData.expiryDate}{" "}
                    </span>
                  </div>
                </div>
                <div className="width_100 footer_sec_2 display_flex space_btw font_ubuntu">
                  <div className="color_grey font_14 font_weight_md txt_center">
                    <span>{priceData.freeQuantity}</span> <br />
                    <span>Free Qty</span>
                  </div>
                  <div className="display_flex space_btw padding_10">
                    <Fragment>
                      <div
                        className="minusQty color_primary"
                        id="minusQty"
                        onClick={minusQuantity}
                      >
                        <AiOutlineMinus />
                      </div>

                      <div className="qty font_ubuntu">
                        <input
                          className="qty_input"
                          onChange={qtyChange}
                          type="number"
                          id="qtyInput"
                          value={quantity}
                        />
                      </div>
                      <div
                        className="addQty bg_primary color_white"
                        onClick={addQuantity}
                        id="plusQty"
                      >
                        <AiOutlinePlus />
                      </div>
                    </Fragment>
                  </div>
                  <div className="color_grey font_14 font_weight_md txt_center">
                    <span className="color_black">
                      {+quantity + priceData.freeQuantity}
                    </span>{" "}
                    <br />
                    <span className="font_12">Total Qty</span>
                  </div>
                </div>
              </Modal.Footer>
              <Modal.Footer style={{ padding: "0px", margin: "0px" }}>
                {maxStockError && (
                  <div
                    className="productMessage"
                    style={{ color: "orangered" }}
                  >
                    Maximun total quantity should be{" "}
                    {medData?.availableQuantity}
                  </div>
                )}
                <div
                  className="productMessage"
                  style={{
                    background: priceData?.appMessageDto?.colorCode,
                    color: priceData?.appMessageDto?.textColor,
                  }}
                >
                  {priceData?.appMessageDto?.appMessage}
                </div>
                <div
                  onClick={() => {
                    quantity > 0 && !maxStockError && addToCart(quantity);
                  }}
                  className={
                    cartSpinner || quantity < 1 || maxStockError
                      ? "disableBtn add_to_cart_footer"
                      : "bg_primary color_white add_to_cart_footer"
                  }
                  id="addToCart"
                >
                  <span>
                    {spinner.totalPrice ? (
                      <Spinner
                        animation="border"
                        className="color_secondary"
                        size="sm"
                      />
                    ) : (
                      "₹" + priceData.subTotal
                    )}
                  </span>
                  <br />
                  <span>
                    {" "}
                    {cartSpinner ? (
                      <Spinner
                        animation="border"
                        className="color_primary"
                        size="sm"
                      />
                    ) : (
                      "ADD TO CART"
                    )}{" "}
                  </span>
                </div>
              </Modal.Footer>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(Product);
