import React, { useEffect, useState } from "react";
import "./Gamification.css";
import conditionalClass from "classnames";
import { FiArrowLeft } from "react-icons/fi";
import { CampaignGift } from "../Campaigns/CampaignGift";
import { getGamificationData } from "../../Actions/Gamification";
import Lottie from "react-lottie-player";
import LogoLoader from "../../Images/json/LogoLoader.json";
import { useNavigate } from "react-router";

export const Gamification = () => {
  const [currentNav, setCurrentNav] = useState("LIVE");
  const [campaignData, setCampaignData] = useState([]);
  const [campLoader, setCampLoader] = useState(false);
  let navigate = useNavigate();

  const handleNav = (selectedNav) => {
    setCampLoader(true);
    setCampaignData([]);
    setCurrentNav(selectedNav);
  };

  const getCampaigns = async () => {
    const params = {
      campaignState: currentNav,
      retailerCode: JSON.parse(localStorage.getItem("userData")).retailerCode,
    };
    getGamificationData("SHOW_CAMPAIGNS", params).then((response) => {
      setCampaignData(response.data.data);
      setCampLoader(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setCampLoader(true);
    getCampaigns();
  }, [currentNav]);
  return (
    <div className="giftParentCont">
      <div className="giftChildCont">
        <div className="giftHeaderCont">
          <ul className="giftHeader">
            <li className="giftBackBtn">
              <FiArrowLeft onClick={() => navigate("/home")} />
            </li>
            <li
              className={conditionalClass({
                currentNav: currentNav === "LIVE",
              })}
              onClick={() => handleNav("LIVE")}>
              Live
            </li>
            <li
              className={conditionalClass({
                currentNav: currentNav === "COMPLETED",
              })}
              onClick={() => handleNav("COMPLETED")}>
              Completed
            </li>
            <li
              className={conditionalClass({
                currentNav: currentNav === "EXPIRED",
              })}
              onClick={() => handleNav("EXPIRED")}>
              Expired
            </li>
          </ul>
        </div>

        <div className={"giftBodyCont"}>
          {campLoader && <Lottie className="campLoader" loop animationData={LogoLoader} play style={{ width: "70px", height: "100%" }} />}

          {campaignData &&
            campaignData.length > 0 &&
            campaignData.map((campData, indx) => {
              return <CampaignGift key={"gift" + indx} campaignData={campData} currentNav={currentNav} />;
            })}

          {!campLoader && campaignData.length === 0 && <p className="emptyCampDesc">No campaigns are available at this moment.</p>}
        </div>
      </div>
    </div>
  );
};
