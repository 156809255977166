import React, { useState, useEffect, Fragment, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../App.css";
import "../HomePage/HomePage.css";
import "../../animate.css";
import Spark from "../../Images/png/flash_sale_spark.png";
import Slider from "../Sliders/Slider";
import { GiAlarmClock } from "react-icons/gi";
// import { dialyFlashSaleTimer } from "./HomePage";
import { timer_product_widget } from "../../Actions/Widgets";
import { searchContext } from "../../App";
import ErrorModal from "../Modals/ErrorModal";
import OrderMessageModal from "../Modals/orderMessageModal";

const DialyFlashSale = ({widgetIndex,widgetData, renderWidget }) => {
  const [timerProductTime, setTimerProductTime] = useState({
    endTime: 1,
    stopWatch: "00:00:00",
  });
  const [shimmer, setShimmer] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const { showRecent, setShowRecent } = useContext(searchContext);

  //api to hit widgetData endpoint
  const getData = async () => {
    console.log("inside the get data hit in daily flash sale");
    //update the shimmer state
    setShimmer(true);
    let timerObj = { ...timerProductTime };
    timerObj.endTime = widgetData?.data?.endTime;
    try {
      const res = await timer_product_widget(widgetData?.data?.apiEndPoint);
      console.log(res?.data?.data);
      //update the shimmer state
      setShimmer(false);
      //update the product res data
      setProductsData(res?.data?.data);
      //update the deal response time if any
      if (
        res.data.data.medicinesResponsesList &&
        res.data.data.medicinesResponsesList[0]?.dealEndTime
      ) {
        timerObj.endTime = res.data.data.medicinesResponsesList[0].dealEndTime;
      }
      //update the timer object state
      setTimerProductTime(timerObj);
      renderWidget(widgetData,widgetIndex,false,null)

    } catch (error) {
      //update shimmer state if error
      setShimmer(false);
      renderWidget(widgetData,widgetIndex,true,error)
      // console.log(error);
      // if (
      //   error?.response?.status == 502 ||
      //   error?.response?.status == 503 ||
      //   error?.response?.status == 504
      // ) {
      //   setShowMaintenance({
      //     status: true,
      //     apiData: error?.response,
      //   });
      // } else if (error?.response?.status == 500) {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: "Oops something went wrong !",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // } else if (error?.response?.status == 400) {
      //   setOrderMessage({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data?.error?.error,
      //     header: `Info`,
      //   });
      // } else {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data.error?.error
      //       ? error.response.data.error.error
      //       : "Something went wrong!",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // }
    }
  };

  useEffect(() => {
    console.log({ widgetData });
    //get widget data when
    if (widgetData) getData();
  }, [widgetData,widgetData.renderStatus]);

  useEffect(() => {
    //disable the timer when search is active

    if (showRecent) return;
    let timer;
    if (currentTime < timerProductTime.endTime) {
      timer = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 1000);
      function msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 10) {
          secs = "0" + secs;
        }
        if (hrs < 10) {
          hrs = "0" + hrs;
        }
        let obj = { ...timerProductTime };
        obj.stopWatch = hrs + ":" + mins + ":" + secs;
        setTimerProductTime(obj);
      }
      msToTime(timerProductTime.endTime - currentTime);
    } else {
      return () => {
        clearInterval(timer);
      };
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [
    currentTime,
    widgetData,
    timerProductTime.endTime,
    showRecent,
    productsData,
  ]);


  useEffect(() => {
    console.log({shimmer})
  },[shimmer])

  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />
      {shimmer && productsData.length === 0 &&(
        <Container className="dialyFlashSale bg_opacity">
          <div className="display_flex space_btw">
            <div className="header_left ">
              <Row className="flash_sale_header">
                <Col style={{ display: "flex" }}>
                  <div>
                    <span>
                      <img src={Spark} className="fash_sale_spark_img" alt="flash sale" />
                    </span>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <span className="color_light_purple flash_header_12">
                      FlashSale
                    </span>
                    <br />

                    <span className="color_white font_wight_md flash_header_14 "></span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="padding_5 timer_div">
              <div className="time_limit display_flex ">
                <GiAlarmClock className="color_pink clock_img" />
                <p
                  className="color_pink font_14 font_weight_400 no_wrap"
                  style={{ margin: "0px" }}
                >
                  &nbsp; {"00:00:00"} &nbsp;
                  <span className="color_grey font_ubuntu font_weight_md">
                    hrs left
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <Slider
              screen={"home_screen"}
              shimmer={shimmer}
              widgetData={widgetData}
              flagBg={"#C719B1"}
              ProductRes={productsData}
              flagColor={"#ffffff"}
            />
          </div>
        </Container>
      )}

      {
        widgetData &&
        productsData?.medicinesResponsesList?.length > 0 &&
        !shimmer && (
          <Container className="dialyFlashSale">
            <div className="display_flex space_btw">
              <div className="header_left ">
                <Row className="flash_sale_header">
                  <Col style={{ display: "flex" }}>
                    <div>
                      <span>
                        <img src={Spark} className="fash_sale_spark_img" alt="flash sale" />
                      </span>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <span className="color_light_purple flash_header_12">
                        {widgetData.data.saleTime}
                      </span>
                      <br />

                      <span className="color_white font_wight_md flash_header_14 ">
                        {widgetData.data.title}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="padding_5 timer_div">
                <div className="time_limit display_flex ">
                  <GiAlarmClock className="color_pink clock_img" />
                  <p
                    className="color_pink font_14 font_weight_400 no_wrap"
                    style={{ margin: "0px" }}
                  >
                    &nbsp; {timerProductTime.stopWatch} &nbsp;
                    <span className="color_grey font_ubuntu font_weight_md">
                      hrs left
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <Slider
                screen={"home_screen"}
                widgetData={widgetData}
                ProductRes={productsData}
                flagBg={"#C719B1"}
                shimmer={shimmer}
                flagColor={"#ffffff"}
              />
            </div>
          </Container>
        )
      }
    </Fragment>
  );
};

export default React.memo(DialyFlashSale);
