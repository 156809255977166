import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useCallback,
} from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { refreshToken } from "../../Actions/Auth";
import { Cart_Actions } from "../../Actions/Cart";
import { cartDataContext, usersData } from "../../App";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Delete from "../../Images/svg/delete.svg";
import DeleteProductModal from "../Modals/DeleteProductModal";
import { useNavigate } from "react-router";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import offers from "../../Images/svg/cart_offers.svg";
import { HiOutlineChevronRight } from "react-icons/hi";
import CartGenericsModal from "../Modals/CartGenericsModal";
import { GiAlarmClock } from "react-icons/gi";

const CartCard = ({ item, animateCard, genericStatus, genericMessage }) => {
  let navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [quantity, setQuantity] = useState();
  const { cartData, setCartData } = useContext(cartDataContext);
  const { userData } = useContext(usersData);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [cartGenericsModal, setCartGenericsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const addQuantity = (e, item) => {
    e && e.stopPropagation();
    let qty;
    item.multiplyFactorForOrderQuantity > 0
      ? (qty = quantity + item.multiplyFactorForOrderQuantity)
      : (qty = quantity + 1);

    setQuantity(qty);
    editCart(item, { target: { value: qty } });
  };
  const minusQuantity = (e, item) => {
    e.stopPropagation();
    let qty;
    item.multiplyFactorForOrderQuantity > 0
      ? (qty = quantity - item.multiplyFactorForOrderQuantity)
      : (qty = quantity - 1);

    qty > -1 && setQuantity(qty);
    editCart(item, { target: { value: qty } });
  };

  const editCart = (item, e) => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (+e.target.value > 0) {
      let condition1 = true;
      let error = "";
      if (
        item.maxOrderQuantity > 0 &&
        +e.target.value > item.maxOrderQuantity
      ) {
        condition1 = false;
        error = `Maximum Order Quantity ${item.maxOrderQuantity}`;
      }
      let condition2 = true;
      if (
        item.minOrderQuantity > 0 &&
        +e.target.value > 0 &&
        +e.target.value < item.minOrderQuantity
      ) {
        condition2 = false;
        error = `Minimum Order Quantity ${item.minOrderQuantity}`;
      }

      let condition3 = true;
      if (
        item.multiplyFactorForOrderQuantity > 0 &&
        +e.target.value % item.multiplyFactorForOrderQuantity !== 0
      ) {
        condition3 = false;
        error = `Quantity Should be multiple of ${item.multiplyFactorForOrderQuantity}`;
      }

      if (condition1 && condition2 && condition3) {
        setShowSpinner(true);
        const params = {};
        const body = {
          androidMap: {
            locale: "en",
            source: "pharmacyWeb",
            deviceId: null,
            appVersion: navigator.userAgent,
            deviceName: null,
            screen_name: "cart_screen",
            appVersionCode: "156",
          },
          medicineId: item.medicineId,
          quantity: +e.target.value,
          retailerCode: data.retailerCode,
          retailerId: data.id,
          selectedSlotName: item.selectedSlotName,
        };
        Cart_Actions("EDIT_CART", params, body)
          .then((res) => {
            setShowSpinner(false);
            setCartData(res.data.data);
          })
          .catch((error) => {
            setQuantity(item.quantity);
            setShowSpinner(false);
            if (error?.response?.status == 401) {
              refreshToken().then((res) => {
                editCart(item, e);
              });
            } else if (
              error?.response?.status == 502 ||
              error?.response?.status == 503 ||
              error?.response?.status == 504
            ) {
              setShowMaintenance({ status: true, apiData: error?.response });
            } else if (error?.response?.status == 500) {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: "Oops something went wrong !",
                tryAgain: function () {
                  editCart(item, e);
                },
              });
            } else {
              setErrorModal({
                show: true,
                apiData: error?.response,
                content: error?.response?.data?.error?.error
                  ? error.response.data.error.error
                  : "Something went wrong!",
                tryAgain: function () {
                  editCart(item, e);
                },
              });
            }
          });
      } else {
        setQuantity(item.quantity);
      }
    } else if (e.target.value.toString().length && +e.target.value < 1) {
      setShowDeleteModal(true);
      setQuantity(item.quantity);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const editCartOptimised = useCallback(debounce(editCart), []);

  const removeFromCart = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "delete_from_cart",
      medicineId: item.medicineId,
    });
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setShowSpinner(true);
    setShowDeleteModal(false);
    const params = {};
    const body = {
      androidMap: {},
      medicineId: item.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };

    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setShowSpinner(false);
        setCartData(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          refreshToken().then((res) => {
            removeFromCart();
          });
        } else if (
          error.response.status == 502 ||
          error.response.status == 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status == 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error.response.data.error.error,
            tryAgain: function () {
              removeFromCart();
            },
          });
        }
      });
  };

  const openCartGenerics = (e) => {
    e.stopPropagation();
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "generics_cart_tag_click",
      medicineId: item.medicineId,
    });
    setCartGenericsModal(true);
  };
  useEffect(() => {
    setQuantity(item.quantity);
  }, [item]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
      {item && (
        <>
          {" "}
          {showDeleteModal && (
            <DeleteProductModal
              show={showDeleteModal}
              itemName={item.medicineName}
              removeFromCart={removeFromCart}
              onHide={() => setShowDeleteModal(false)}
            />
          )}
          {cartGenericsModal && (
            <CartGenericsModal
              show={cartGenericsModal}
              item={item}
              onHide={() => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "dialog_close",
                  dialog_name: "Generics_Cart_Popup_Close",
                });
                setCartGenericsModal(false);
              }}
            />
          )}
          <div
            className={
              animateCard.show && animateCard.id == item.medicineId
                ? "errorAnime brdr_highlight cart_card"
                : "cart_card"
            }
            onClick={async (e) => {
              await Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "cart_item_tap",
                medicineId: item.medicineId,
              });
              if (item?.medicineSection === "EXPIRY") {
                navigate(`/product/${item.medicineId}?type=SE`);
              } else {
                navigate(`/product/${item.medicineId}`);
              }
            }}
          >
            <Row>
              <Col>
                {" "}
                {item.dealType === "FlashSale" ? (
                  <div className="flashSale_tag">
                    <GiAlarmClock
                      style={{ fontSize: "14px", marginTop: "-1px" }}
                    />{" "}
                    FLASH SALE
                  </div>
                ) : item.dealType === "DealOfTheDay" ? (
                  <div className="deal_tag color_primary">
                    <GiAlarmClock
                      style={{ fontSize: "14px", marginTop: "-1px" }}
                    />{" "}
                    DEAL OF THE DAY
                  </div>
                ) : item.dealType === "Special_Rate" ? (
                  <div className="special_tag color_white">SPECIAL DEAL</div>
                ) : (
                  item.dealType !== "REGULAR" && (
                    <div className="special_tag color_white">
                      {item.dealType}
                    </div>
                  )
                )}
              </Col>
            </Row>
            <Row>
              <Col className="nowrap font_weight_md font_ubuntu">
                {item.medicineName.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                <br />
                <span className="color_grey font_14">{item.packaging}</span>
              </Col>
              <Col>
                <div className="rightSec">
                  <div className="ptr_section color_grey">
                    <div className="font_weight_md font_ubuntu">
                      <p>PTR</p>₹ {item.ptr}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="padding_10">
              <Col>
                <div className="total_card_sec font_ubuntu font_weight_md">
                  <p className="color_secondary">Total</p>₹{" "}
                  {item.actualPrice.toFixed(2)}
                </div>
              </Col>
              <Col>
                <div className="rightSec">
                  <div className="qty_edit_sec">
                    <div
                      className="display_flex"
                      style={{
                        justifyContent: "end",
                      }}
                    >
                      <div className="display_flex space_btw">
                        <img
                          src={Delete}
                          alt="Delete"
                          id="delete_cart_product"
                          className="delete_product_svg"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                          }}
                        />
                        {
                          <Fragment>
                            <div
                              id="cart_product_minus_qty"
                              className="minusQty color_primary"
                              onClick={(e) => minusQuantity(e, item)}
                            >
                              <AiOutlineMinus />
                            </div>
                            <div className="qty font_ubuntu">
                              {showSpinner ? (
                                <Spinner
                                  animation="border"
                                  className="color_secondary spinner"
                                  size="sm"
                                  style={{ marginLeft: "-2px" }}
                                />
                              ) : (
                                <input
                                  className="qty_input"
                                  id="cart_product_qty_input"
                                  style={{ textAlign: "center" }}
                                  type="number"
                                  value={quantity}
                                  min={item.minOrderQuantity}
                                  max={
                                    item.maxOrderQuantity > 0 &&
                                    item.maxOrderQuantity
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    setQuantity(e.target.value);
                                    editCartOptimised(item, e);
                                  }}
                                />
                              )}
                            </div>
                            <div
                              id="cart_prpduct_plus_qty"
                              className="addQty bg_primary color_white"
                              onClick={(e) => {
                                addQuantity(e, item);
                              }}
                            >
                              <AiOutlinePlus />
                            </div>
                          </Fragment>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {(item?.genericAlternativeMessage || genericStatus) && (
              <div className="generic_message" onClick={openCartGenerics}>
                <div>
                  <img src={offers} alt="Offer" />
                  <div className="font_14">
                    {item?.genericAlternativeMessage || genericMessage}
                  </div>
                </div>
                <div>
                  <HiOutlineChevronRight style={{ color: "#F46D6A" }} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(CartCard);
