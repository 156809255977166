import React, { useState, useEffect, Fragment, useContext } from "react";
import { Container } from "react-bootstrap";
import "../../App.css";
import "../HomePage/HomePage.css";
import "../../animate.css";
import Slider2 from "../Sliders/Slider2";
import { GiAlarmClock } from "react-icons/gi";
import { timer_product_widget_two } from "../../Actions/Widgets";
import { searchContext, usersData } from "../../App";

const DealOfDay = ({ widgetIndex, renderWidget, widgetData }) => {
  const [shimmer, setShimmer] = useState(false);
  const [timerProductTime2, setTimerProductTime2] = useState({
    endTime: 1,
    stopWatch: "00:00:00",
  });
  const [productsData, setProductsData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const { showRecent, setShowRecent } = useContext(searchContext);

  //get products data
  const getData = async () => {
    //update the shimmer state
    setShimmer(true);
    let timerObj = { ...timerProductTime2 };
    timerObj.endTime = widgetData?.data?.endTime;
    try {
      const res = await timer_product_widget_two(widgetData?.data?.apiEndPoint);
      console.log({ res });
      //update the product res staTe
      setProductsData(res?.data?.data);
      //update the shimmer state
      setShimmer(false);
      //update the timer object 
      if (
        res.data.data.medicinesResponsesList &&
        res.data.data.medicinesResponsesList[0]?.dealEndTime
      ) {
        timerObj.endTime = res.data.data.medicinesResponsesList[0].dealEndTime;
      }
      setTimerProductTime2(timerObj);
      renderWidget(widgetData,widgetIndex,false,null)

    } catch (error) {
      //update the shimmer state if any errpr
      setShimmer(false);
      renderWidget(widgetData,widgetIndex,true,error)
      // console.log(error);
      // if (
      //   error?.response?.status == 502 ||
      //   error?.response?.status == 503 ||
      //   error?.response?.status == 504
      // ) {
      //   setShowMaintenance({
      //     status: true,
      //     apiData: error?.response,
      //   });
      // } else if (error?.response?.status == 500) {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: "Oops something went wrong !",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // } else if (error?.response?.status == 400) {
      //   setOrderMessage({
      //     show: true,
      //     content: error?.response?.data?.error?.error,
      //     header: `Info`,
      //   });
      // } else {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data.error?.error
      //       ? error.response.data.error.error
      //       : "Something went wrong!",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // }
    }
  };

  //timer logic
  useEffect(() => {
    //disable the timer when search is active
    if (showRecent) return;
    let timer;
    if (currentTime < timerProductTime2.endTime) {
      timer = setInterval(() => { 
        setCurrentTime(new Date().getTime());
      }, 1000);
      function msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 10) {
          secs = "0" + secs;
        }
        if (hrs < 10) {
          hrs = "0" + hrs;
        }
        let obj = { ...timerProductTime2 };
        obj.stopWatch = hrs + ":" + mins + ":" + secs;
        setTimerProductTime2(obj);
      }
      msToTime(timerProductTime2.endTime - currentTime);
    } else {
      return () => {
        clearInterval(timer);
      };
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [
    currentTime,
    widgetData,
    timerProductTime2.endTime,
    showRecent,
    productsData,
  ]);

  useEffect(() => {
    //hit the api when widgetData is available
    if (widgetData) getData();
  }, [widgetData,widgetData.renderStatus]);

  return (
    <Fragment>
      {shimmer && (
        <Container fluid className="dealofday bg_opacity">
          <div className="display_flex space_btw">
            <div
              className="color_primary font_weight_md product_name padding_5"
              style={{ marginLeft: "1%" }}
            >
              Deal Of The Day
            </div>

            <div className="padding_5 timer_div " style={{ marginTop: "-5px" }}>
              <div
                className="time_limit brdr_primary display_flex bg_primary "
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "6px",
                }}
              >
                <p
                  className="color_white font_14 font_weight_400 no_wrap"
                  style={{ margin: "0px" }}
                >
                  &nbsp; 00:00:00 &nbsp;
                  <span className="color_grey font_ubuntu font_weight_md">
                    hrs left
                  </span>
                </p>
              </div>
            </div>
          </div>

          <Slider2 shimmer={shimmer} flagBg={"#C719B1"} flagColor={"#ffffff"} />
        </Container>
      )}
      {!shimmer &&
        widgetData &&
        productsData?.medicinesResponsesList?.length > 0 && (
          <Container fluid className="dealofday">
            <div className="display_flex space_btw">
              <div
                className="color_primary font_weight_md product_name padding_5"
                style={{ marginLeft: "1%" }}
              >
                {widgetData.data.title}
              </div>

              <div
                className="padding_5 timer_div "
                style={{ marginTop: "-5px" }}
              >
                <div
                  className="time_limit brdr_primary display_flex bg_primary "
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "6px",
                  }}
                >
                  <GiAlarmClock className="color_white clock_img" />
                  <p
                    className="color_white font_14 font_weight_400 no_wrap"
                    style={{ margin: "0px" }}
                  >
                    &nbsp; {timerProductTime2.stopWatch} &nbsp;
                    <span
                      className=" font_ubuntu font_weight_md"
                      style={{ color: "rgb(211, 210, 210)" }}
                    >
                      hrs left
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <Slider2
              screen={"home_screen"}
              widgetData={widgetData}
              ProductRes={productsData}
              flagBg={"#C719B1"}
              flagColor={"#ffffff"}
            />
          </Container>
        )}
    </Fragment>
  );
};

export default React.memo(DealOfDay);
