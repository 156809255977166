import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "../ProductCard/ProductCard.css";
import "../../App.css";
import info from "../../Images/svg/info-i.svg";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import ProductPtrDialog from "../Modals/ProductPtrDialog";
import ProductModal from "../Modals/ProductModal";
import { useNavigate } from "react-router";
import { Cart_Actions } from "../../Actions/Cart";
import { cartDataContext, usersData } from "../../App";
import { refreshToken } from "../../Actions/Auth";
import ErrorModal from "../Modals/ErrorModal";
import Thumb from "../../Images/svg/Thumb.svg";
import { Common_Event_Hit } from "../../Actions/UserEvents";
const ProductCard = ({ flagBg, medData, widgetData, screen, position,flagColor }) => {
  const [quantity, setQuantity] = useState(0);
  const [productPtrShow, setProductPtrShow] = React.useState(false);
  const [productModalShow, setProductModalShow] = React.useState(false);
  const [multiplyFactorForOrderQuantity, setmMltiplyFactorForOrderQuantity] =
    useState(1);
  const [maxQtyError, setMaxQtyError] = useState(false);
  const [maxStockError, setMaxStockError] = useState(false);
  const [minQtyError, setMinQtyError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { cartData, setCartData } = useContext(cartDataContext);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });

  let navigate = useNavigate();
  const addQuantity = (e, initialHit) => {
    e.stopPropagation();
    if (medData.maxOrderQuantity > 0 && quantity < medData.maxOrderQuantity) {
      let updatedQuantity;

      if (
        multiplyFactorForOrderQuantity &&
        multiplyFactorForOrderQuantity > 1
      ) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity >= medData.minOrderQuantity) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity < medData.minOrderQuantity) {
        updatedQuantity = quantity + medData.minOrderQuantity;
      }
      if (updatedQuantity <= medData.availableQuantity) {
        addToCart(updatedQuantity);
      } else {
        setMaxStockError(true);
        setTimeout(() => {
          setMaxStockError(false);
        }, 2000);
      }
    } else if (medData.maxOrderQuantity === 0) {
      let updatedQuantity;

      if (
        multiplyFactorForOrderQuantity &&
        multiplyFactorForOrderQuantity > 1
      ) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity >= medData.minOrderQuantity) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity < medData.minOrderQuantity) {
        updatedQuantity = quantity + medData.minOrderQuantity;
      }
      if (updatedQuantity <= medData.availableQuantity) {
        addToCart(updatedQuantity);
      } else {
        setMaxStockError(true);
        setTimeout(() => {
          setMaxStockError(false);
        }, 2000);
      }
    }

    if (initialHit) {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "add_to_cart",
        pageId: widgetData.data.pageId,
        medicineId: medData.id,
        position: position,
        price: medData.effectivePrice.toFixed(2),
        count: quantity + multiplyFactorForOrderQuantity,
      });
    } else {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "count_increment_tap",
        pageId: widgetData.data.pageId,
        medicineId: medData.id,
        medicineName: medData.name,
        position: position,
        price: medData.effectivePrice.toFixed(2),
        count: quantity + multiplyFactorForOrderQuantity,
      });
    }

    if (
      quantity > 0 &&
      medData.maxOrderQuantity > 0 &&
      quantity >= medData.maxOrderQuantity
    ) {
      setMaxQtyError(true);
      setTimeout(() => {
        setMaxQtyError(false);
      }, 2000);
    }
  };
  const minusQuantity = (e) => {
    e.stopPropagation();
    if (quantity > 0 && quantity > medData.minOrderQuantity) {
      let updatedQuantity = quantity - multiplyFactorForOrderQuantity;
      updatedQuantity > 0 && addToCart(updatedQuantity);
      updatedQuantity === 0 && removeFromCart();

      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "count_decrement_tap",
        pageId: widgetData.data.pageId,
        medicineId: medData.id,
        medicineName: medData.name,
        position: position,
        price: medData.effectivePrice.toFixed(2),
        count: updatedQuantity,
      });
    }

    if (quantity <= medData.minOrderQuantity) {
      removeFromCart();
      setMinQtyError(true);
      setTimeout(() => {
        setMinQtyError(false);
      }, 1000);
    }
  };

  const addToCart = (qty) => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (medData.deliverySlot) {
      setShowSpinner(true);
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          screen_name: screen,
          appVersionCode: "156",
        },
        medicineId: medData.medicineId,
        quantity: qty,
        retailerCode: data.retailerCode,
        retailerId: data.id,
      };
      Cart_Actions("ADD_TO_CART", params, body)
        .then((res) => {
          setQuantity(qty);
          setShowSpinner(false);
          setCartData(res.data.data);
        })
        .catch((error) => {
          setShowSpinner(false);
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              addToCart(qty);
            });
          } else if (
            error.response.status === 502 ||
            error.response.status === 503 ||
            error.response.status === 504
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                addToCart(qty);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                addToCart(qty);
              },
            });
          }
        });
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };

  const removeFromCart = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setShowSpinner(true);
    const params = {};
    const body = {
      androidMap: {},
      medicineId: medData.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };
    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setShowSpinner(false);
        setQuantity(0);
        setCartData(res.data.data);
      })
      .catch((error) => {
        setShowSpinner(false);
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            removeFromCart();
          });
        } else if (
          error.response.status === 502 ||
          error.response.status === 503 ||
          error.response.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              removeFromCart();
            },
          });
        }
      });
  };
  useEffect(() => {
    if (medData && medData.multiplyFactorForOrderQuantity > 0) {
      setmMltiplyFactorForOrderQuantity(medData.multiplyFactorForOrderQuantity);
    }
  }, []);

  useEffect(() => {
    if (cartData && medData) {
      if (cartData.cartMedicineDetailResponseList) {
        let existingCartItem = cartData.cartMedicineDetailResponseList.filter(
          (item) => +item.medicineId === +medData.medicineId
        );
        if (existingCartItem && existingCartItem.length) {
          setQuantity(existingCartItem[0].quantity);
        }
      }
    }
  }, [cartData]);
  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
      <ProductPtrDialog
        show={productPtrShow}
        medData={medData}
        onHide={() => setProductPtrShow(false)}
      />
      <ProductModal
        show={productModalShow}
        medData={medData}
        onHide={() => setProductModalShow(false)}
      />

      {medData ? (
        <div
          className="productCard_parent cursor_pointer"
          onClick={async (e) => {
            console.log(position);
            await Common_Event_Hit("CARD_TAP", {
              eventName: "card_tap",
              pageId: widgetData.data.pageId,
              medicineId: medData.id,
              position: position,
            });
            navigate(`/product/${medData.id}`);
          }}
        >
          <div style={{ position: "relative" }}>
            {medData.cashDiscountInPercentage > 0 && (
              <div
                className="cd_flag font_ubutu font_weight_lg"
                style={{
                  backgroundColor: flagBg,
                  color:flagColor?flagColor:flagBg ? "#2a2a2a" : "white",
                }}
              >
                {medData.cashDiscountInPercentage.toFixed(2).replace(/[.,]00$/, "")} %{" "}
                <span style={{  color:flagColor?flagColor:flagBg ? "#2a2a2a" : "white",}}> CD</span>
              </div>
            )}
          </div>

          <Row>
            <div className="display_flex ">
              <img
                className="product_img"
                src={
                  medData?.medicineImage?.lowResUrl
                    ? medData.medicineImage.lowResUrl
                    : Thumb
                }
                alt=""
              />
              <div className="padding_10"></div>
              <div className="color_primary font_weight_md product_name">
                <p>
                  <OverlayTrigger placement={"bottom"} overlay={<Tooltip id={`tooltip-bottom`}>{medData.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</Tooltip>}>
                    <span>{medData.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span>
                  </OverlayTrigger>
                </p>
              </div>
            </div>
          </Row>
          <Row
            className="brdr_grey border_radius_5 marign_top_10 "
            style={{ padding: "5px 0px" }}
          >
            <div className="display_flex space_btw">
              <div className="color_grey font_weight_md font_10">
                {" "}
                MRP <br /> <span className="color_black">
                  {" "}
                  ₹ {medData.mrp}
                </span>{" "}
              </div>
              <div
                className="product_margin text_center"
                style={{ padding: "3px 2px", lineHeight: "10px" }}
              >
                <span className="font_weight_md text_center font_12">
                  {Math.round(medData.retailerMargin)}%
                </span>{" "}
                <br />
                <span style={{ fontSize: "9px" }}>MARGIN</span>
              </div>

              <div style={{ lineHeight: "10px", textAlign: "right" }}>
                {/* {medData.ptr !== medData.effectivePrice && (
                  <span className="color_grey text_line_through font_10 font_weight_md">
                    ₹ {medData.ptr}
                  </span>
                )} */}

                <span className="color_primary_lt font_weight_md font_10">
                  {" "}
                  &nbsp; PTR{" "}
                  <img
                    src={info}
                    className="ptrInfo"
                    onClick={(e) => {
                      e.stopPropagation();
                      Common_Event_Hit("DYNAMIC_EVENT", {
                        eventName: "info_tap",
                        medicineId: medData.id,
                      });
                      setProductPtrShow(true);
                    }}
                    alt="ptr_img"
                  />
                </span>
                <br />
                <span className="color_primary font_10 font_weight_md">
                  ₹ {medData.ptr.toFixed(2).toString().split(".")[0]}
                </span>
                <span
                  className="color_primary font_weight_md"
                  style={{ fontSize: "8px" }}
                >
                  .{medData.ptr.toFixed(2).toString().split(".")[1]}
                </span>
              </div>
            </div>
          </Row>
          <Row>
            <div className="display_flex space_btw">
              <div className="schemes display_flex space_btw">
                {medData.scheme &&
                  medData.scheme.map((scheme) => (
                    <div className="scheme">
                      <span className="color_grey">{scheme.split("+")[0]}</span>
                      <span className="color_grey">
                        {" "}
                        + {scheme.split("+")[1]}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="display_flex space_btw productQty">
                {quantity < 1 && (
                  <Button
                    type="button"
                    className="primary_btn product_add_btn font_ubuntu font_weight_md "
                    onClick={async (e) => {
                      addQuantity(e, true);
                    }}
                  >
                    {showSpinner ? (
                      <Spinner
                        animation="border"
                        className="color_secondary spinner"
                        size="sm"
                        style={{ margin: "0px 5px" }}
                      />
                    ) : (
                      "+ ADD"
                    )}
                  </Button>
                )}
                {quantity > 0 && (
                  <Fragment>
                    <div
                      className="minusQty color_primary"
                      style={{ left: "-20px", marginLeft: "-20px" }}
                      onClick={minusQuantity}
                    >
                      <AiOutlineMinus />
                    </div>
                    <div className="qty font_ubuntu">
                      {showSpinner ? (
                        <Spinner
                          animation="border"
                          className="color_secondary spinner"
                          size="sm"
                          style={{ marginLeft: "-2px" }}
                        />
                      ) : (
                        quantity
                      )}
                    </div>
                    <div
                      className="addQty bg_primary color_white"
                      onClick={(e) => addQuantity(e, false)}
                    >
                      <AiOutlinePlus />
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Row>
          {maxQtyError ? (
            <Row>
              <Col className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                {" "}
                Maximum Order Quantity is {medData.maxOrderQuantity}{" "}
              </Col>
            </Row>
          ) : (
            maxStockError && (
              <Row>
                <Col className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                  {" "}
                  Maximum Order Quantity is {medData.availableQuantity}{" "}
                </Col>
              </Row>
            )
          )}

          {minQtyError && (
            <Row>
              <Col className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                {" "}
                Minimum Order Quantity is {medData.minOrderQuantity}{" "}
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div className="productCard_parent  cursor_pointer ">
          <div style={{ position: "relative" }}></div>

          <Row>
            <div className="display_flex ">
              <div className="padding_10 product_img animateShimmer"></div>
              <div className="color_primary font_weight_md product_name"> </div>
            </div>
          </Row>
          <Row
            className="brdr_grey border_radius_5 animateShimmer marign_top_10 "
            style={{ padding: "10px 0px", marginTop: "3%" }}
          ></Row>
          <Row>
            <div
              className="display_flex space_btw animateShimmer"
              style={{ height: "20px", margin: "3% 5%", width: "90%" }}
            ></div>
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default React.memo(ProductCard);
