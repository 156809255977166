import { Button } from "bootstrap";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Common_Event_Hit } from "../../Actions/UserEvents";

function TicketResultScreen() {
  const { id: ticketId } = useParams();
  const navigate = useNavigate();

  //events handler
  const callApiEvents = (eventName) => {
    let props = {
      eventName: eventName,
    };
    if (eventName === "close_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  const handleClose = () => {
    //call close_tap event
    callApiEvents("close_tap");
    //navigate home
    navigate("/home");
  };

  //onMount set the screen name for events
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "issue_submitted_screen");
  }, []);
  return (
    <Container fluid className="ticket-parent-container">
      <Container fluid className="ticket-header-container">
        <AiOutlineClose
          onClick={handleClose}
          size="1.5rem"
          style={{ cursor: "pointer" }}
        />
      </Container>
      <Container fluid className="ticket-main-container">
        <div className="ticket-status-container">
          <h3>Issue Raised Successfully</h3>
          <p style={{ color: "#4B4495" }}>Ticket Id : {ticketId}</p>
        </div>

        <div className="ticket-status-container ticket-status-info=container">
          <h5 style={{ color: "#48D1CC" }}>Thanks for submitting your issue</h5>
          <p>
            Our team is working on the issue you have raised. Our support team
            will contact you within 48hrs
          </p>
        </div>
        <button
          onClick={() => navigate("/home")}
          className="ticket-home-button"
        >
          Go to Home
        </button>
      </Container>
    </Container>
  );
}

export default TicketResultScreen;
