import React, { useEffect, useState, useContext } from "react";
import { Card, Container, ListGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { refreshToken } from "../../Actions/Auth";
import { My_Account } from "../../Actions/MyAccount";
import ErrorModal from "../Modals/ErrorModal";
import { usersData } from "../../App";
import { GrStatusGoodSmall } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import moment from "moment";
import NoOrders from "../../Images/svg/No orders.svg";
import { Common_Event_Hit } from "../../Actions/UserEvents";

function OrderWithIssueScreen() {
  const { orderType } = useParams();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [shimmer, setShimmer] = useState(false);
  const [showFallBack, setShowFallBack] = useState(false);

  //returns order details(live/past)
  const getOrderData = () => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    const params = {
      retailerId: userData.id,
    };
    setShimmer(true);
    My_Account(orderType, params, {})
      .then((res) => {
        console.log("order with issue screen", res.data.data);
        setOrderData(res.data.data);
        setShimmer(false);
      })
      .catch((error) => {
        console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            getOrderData();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong!",
            tryAgain: function () {
              getOrderData();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              getOrderData();
            },
          });
        }
      });
  };

  const handleIssueWithOrder = (orderId) => {
    callApiEvents("order_selected");
    //renders LiveOrderIssueScreen for both live and past orders alike
    navigate("raiseIssue/liveOrderIssue/" + orderId, { state: orderType });
  };

  const callApiEvents = (eventName) => {
    if (eventName === "order_selected") {
      let props = {
        eventName: eventName,
      };
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  //onMount
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("currentScreen", "select_order_issue_screen");
    getOrderData();
  }, []);

  useEffect(() => {
    if (orderData) {
      orderData.length === 0 ? setShowFallBack(true) : setShowFallBack(false);
    }
  }, [orderData]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />

      <Container fluid className="order-issue-parent-container">
        <div className="order-issue-nav-container nav-container">
          <BiArrowBack
            size="1.5rem"
            color="#4b4495"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span className="page-title">Select Order With Issue</span>
        </div>
        <Container fluid className="order-issue-main-container">
          {shimmer &&
            [...Array(5)].map((_, index) => {
              return (
                <Card
                  key={index}
                  className="order-list-item-card animateShimmer"
                  style={{ minHeight: "9rem" }}
                ></Card>
              );
            })}

          {!shimmer && showFallBack && (
            <>
              <Container fluid className="no-orders-container">
                <img src={NoOrders} className="no-orders" alt="No orders" />
                <h6>There are no orders yet.</h6>
              </Container>
            </>
          )}

          {!shimmer &&
            orderData.map((item, index) => {
              return (
                <Card
                  key={index}
                  className="order-list-item-card"
                  onClick={() => handleIssueWithOrder(item.orderId)}
                  style={{ marginTop: index === 0 && "1rem" }}
                >
                  <ListGroup.Item className="order-list-group-item">
                    <div className="order-list-subitem-group-left">
                      <p className="oreder-list-item-header-text">Order Id</p>
                      <p>{item.orderId}</p>
                    </div>
                    <div className="order-list-subitem-group-right">
                      <p className="oreder-list-item-header-text">Order Date</p>
                      <p>{moment(item.epochTimestamp).format("lll")}</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="order-list-group-item">
                    <div className="order-list-subitem-group-left">
                      <p className="oreder-list-item-header-text">
                        Expected Delivery Slot
                      </p>
                      <p>{item.deliverySlot}</p>
                    </div>
                    <div className="order-list-subitem-group-right">
                      <p className="oreder-list-item-header-text">
                        Total Value
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        ₹ <span>{item.subtotal.toString().split(".")[0]}</span>.
                        <span style={{ fontSize: "0.8rem" }}>
                          {item.subtotal.toString().split(".")[1]}
                        </span>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="order-list-group-item">
                    <div>
                      <GrStatusGoodSmall color="#48D1CC" size="1.3rem" />
                      <span style={{ marginLeft: "0.5rem" }}>
                        {orderType === "LIVE_ORDERS"
                          ? item?.orderTrack[0]?.status
                          : item?.pastOrderStatus}
                      </span>
                    </div>
                    <div className="order-list-item-final-row">
                      <p style={{ margin: "0px" }}>
                        {item.orderDetailList.length} items
                      </p>
                      <span>
                        <MdNavigateNext
                          color="#48D1CC"
                          size="2rem"
                          className="next-icon-report"
                        />
                      </span>
                    </div>
                  </ListGroup.Item>
                </Card>
              );
            })}
        </Container>
      </Container>
    </>
  );
}

export default OrderWithIssueScreen;
