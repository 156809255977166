import React, { useState, useEffect, useContext } from "react";
import { Container, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./Orders.css";
import { HiOutlineArrowNarrowLeft, HiDownload } from "react-icons/hi";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import {
  getLedgerRecords,
  getUserLedgerBalanceDetails,
} from "../../Actions/Ledger";
import { usersData } from "../../App";
import cx from "classnames";
import moment from "moment";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import LedgerPaymentMappingView from "../Modals/LedgerPaymentMappingView";
import PastOrderCardView from "../Modals/PastOrderCardView";
import { BiLinkExternal } from "react-icons/bi";
import { Button } from "@mui/material";
import info from "../../Images/svg/info-i.svg";

const Ledger = () => {
  let navigate = useNavigate();
  const { setErrorHandler, onBehalfStatus } = useContext(usersData);
  const [selectedFilter, setFilter] = useState("Ledger");
  const [page, setPageNo] = useState(0);
  const [ledger, setLedger] = useState({ loader: false, data: null });
  const [invoiceFilter, setInvoiceFilters] = useState({ pending: true });
  const [paymentView, setPaymentView] = useState({ show: false, invoice: "" });
  const [pastOrderCard, setPastOrderCard] = useState({ show: false, id: "" });
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAllOrders, setSelectAllOrders] = useState(false);

  const [ledgerRecords, setLedgerRecords] = useState({
    loader: false,
    data: {},
  });
  const onHandleCategory = async (categType) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName:
        categType === "Ledger"
          ? "ledger_nav_tap"
          : categType === "Invoice"
          ? "ledger_invoice_tap"
          : "ledger_credit_tap",
    });
    setPageNo(0);
    categType === "Invoice" && setInvoiceFilter({ pending: true });
    setFilter(categType);
    window.scrollTo(0, 0);
  };

  const getRecords = (retCode = "") => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let filter =
      selectedFilter === "Ledger"
        ? "all"
        : selectedFilter === "Invoice"
        ? "invoice"
        : "credit-note";
    let body = {
      ledgerType: filter,
      pageNumber: page,
      retailerCode: data.retailerCode,
    };
    if (filter === "invoice") {
      body["invoiceTabStrategy"] = invoiceFilter.pending
        ? "pendingInvoices"
        : "allInvoices";
    }
    let ledgerData = ledgerRecords;
    ledgerData.loader = true;
    setLedgerRecords(ledgerData);
    getLedgerRecords(body)
      .then((res) => {
        let previousData = JSON.parse(JSON.stringify(ledgerRecords));
        previousData.loader = false;
        let data = res.data.data;
        let sortedRecords = {};
        data.ledgerData?.forEach((record) => {
          let month = new Date(
            filter === "invoice"
              ? record?.invoiceDate
              : record?.createdTimestamp
          ).toLocaleString("default", { month: "long", year: "numeric" });

          if (month in sortedRecords) {
             if(invoiceFilter.pending){
              record.grandTotal>0 &&  sortedRecords[month].push(record);
             }else{
              sortedRecords[month].push(record);
             }
          } else {
            if(invoiceFilter.pending){
              record.grandTotal>0 && (sortedRecords[month] = [record])
              ;
             }else{
              sortedRecords[month] = [record];
             }
          }
        });
        data.ledgerData = sortedRecords;
        previousData.data = data;
        console.log(previousData);
        setLedgerRecords(previousData);
      })
      .catch((error) => {
        let data = ledgerRecords;
        data.loader = false;
        setLedgerRecords(data);
        setErrorHandler({
          show: true,
          screen: "account_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getRecords(data.retailerCode);
          },
        });
      });
  };

  const getLedgerBalance = (retcode) => {
    let stateData = JSON.parse(JSON.stringify(ledger));
    stateData.loader = true;
    setLedger(stateData);
    getUserLedgerBalanceDetails({ retailerCode: retcode })
      .then((res) => {
        let data = JSON.parse(JSON.stringify(ledger));
        data.loader = false;
        data.data = res.data.data;
        setLedger(data);
      })
      .catch((error) => {
        let data = JSON.parse(JSON.stringify(ledger));
        data.loader = false;
        data.loader = false;
        setLedger(data);
        setErrorHandler({
          show: true,
          screen: "account_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getLedgerBalance(retcode);
          },
        });
      });
  };

  const previousPage = () => {
    if (page > 0) {
      let updatePage = +page - 1;
      setPageNo(updatePage);
    }
  };
  const nextPage = (invoiceTab = false) => {
    if (invoiceTab && Object.keys(ledgerRecords?.data?.ledgerData)?.length) {
      let updatePage = +page + 1;
      setPageNo(updatePage);
    } else {
      if (page + 1 * 10 <= ledgerRecords?.data?.totalCount) {
        let updatePage = +page + 1;
        setPageNo(updatePage);
      }
    }
  };

  const openInvoicePage = (type, subtype, subTypeID, invoice) => {
    let id = invoice;
    let authData = JSON.parse(localStorage.getItem("userAuth"));
    let token = authData?.data?.jwtToken;

    if (type === "CREDIT_NOTE") {
      id = subTypeID;
      if (subtype === "RDCN") {
        window.open(
          `https://saveo.in/bills/creditNote?id=${id}&token=${token}&type='RDCN'`,
          "_blank",
          ""
        );
      } else if (subtype === "EXPIRY") {
        window.open(
          `https://saveo.in/bills/creditNote?id=${id}&token=${token}&expiry=true`,
          "_blank",
          ""
        );
      } else {
        window.open(
          `https://saveo.in/bills/creditNote?id=${id}&token=${token}`,
          "_blank",
          ""
        );
      }
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "ledger_download_cn",
        creditNoteId: id,
      });
    } else {
      window.open(
        `https://saveo.in/bills/invoice?id=${subTypeID}&token=${token}`,
        "_blank",
        ""
      );
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "ledger_download_invoice",
        invoiceId: subTypeID,
      });
    }
  };

  const setInvoiceFilter = (isPending) => {
    let filter = { ...invoiceFilter };
    filter.pending = isPending;
    setPageNo(0);
    setInvoiceFilters({ ...filter });
  };

  const orderSelect = (event, orderRetrieveId, selectAll = false) => {
    if (event.target.checked) {
      if (selectAll) {
        setSelectAllOrders(true);
        let selectedInv = [];
        Object.keys(ledgerRecords?.data?.ledgerData).map((data) => {
          ledgerRecords?.data?.ledgerData[data].forEach((order) => {
            if (order.statusMap?.true) {
              selectedInv.push(order.orderRetrieveId);
            }
          });
        });
        setSelectedOrders(selectedInv);
      } else {
        let orders = [...selectedOrders];
        orders.push(orderRetrieveId);
        setSelectedOrders(orders);
      }
    } else {
      if (selectAll) {
        setSelectedOrders([]);
        setSelectAllOrders(false);
      } else {
        let orders = [...selectedOrders];
        let index = orders.indexOf(orderRetrieveId);
        orders.splice(index, 1);
        setSelectedOrders(orders);
      }
    }
  };
  useEffect(() => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setSelectedOrders([]);
    setSelectAllOrders(false);
    getRecords(data.retailerCode);
    !ledger?.data && getLedgerBalance(data.retailerCode);
    if (selectedFilter === "Ledger") {
      localStorage.setItem("currentScreen", "ledger_screen");
    } else if (selectedFilter === "Invoice") {
      invoiceFilter.pending
        ? localStorage.setItem("currentScreen", "invoice_pending_screen")
        : localStorage.setItem("currentScreen", "invoice_all_screen");
    } else {
      localStorage.setItem("currentScreen", "credit_note_screen");
    }
  }, [selectedFilter, page, invoiceFilter]);

  return (
    <>
      {paymentView.show && (
        <LedgerPaymentMappingView
          show={paymentView.show}
          invoiceNumber={paymentView.invoice}
          onHide={() => {
            setPaymentView({ show: false, invoice: "" });
          }}
        />
      )}

      {pastOrderCard.show && (
        <PastOrderCardView
          show={pastOrderCard.show}
          id={pastOrderCard.id}
          refresh={() => {
            setPastOrderCard({ show: false, invoice: "" });
            setSelectedOrders([]);
            setSelectAllOrders(false);
            getRecords();
          }}
          onHide={() => {
            setPastOrderCard({ show: false, invoice: "" });
          }}
        />
      )}

      <Container fluid className="ledger_parent">
        <div className="ledger_filter display_flex space_btw font_ubuntu">
          <div className="display_flex">
            <div className="padding_5">
              <HiOutlineArrowNarrowLeft
                className="cursor_pointer goBack"
                onClick={() => navigate(-1)}
                style={{ marginTop: "0.5rem" }}
              />
            </div>

            <div
              className="color_primary  font_ubuntu font_weight_md no_wrap  padding_10"
              onClick={() => navigate(-1)}
            >
              Outstanding
            </div>
          </div>

          <div className="display_flex ledger_menu">
            <div
              className={
                selectedFilter === "Ledger"
                  ? "past_menu color_primary font_weight_md ledger_header_fonts activeClass2"
                  : "past_menu color_primary font_weight_md ledger_header_fonts"
              }
              onClick={() => onHandleCategory("Ledger")}
            >
              Ledger
            </div>

            <div
              className={
                selectedFilter === "Invoice"
                  ? "past_menu color_primary font_weight_md ledger_header_fonts activeClass2"
                  : "past_menu color_primary font_weight_md ledger_header_fonts"
              }
              onClick={() => onHandleCategory("Invoice")}
            >
              Invoice
            </div>
            <div
              className={
                selectedFilter === "Credit Note"
                  ? "past_menu color_primary font_weight_md ledger_header_fonts activeClass2"
                  : "past_menu color_primary font_weight_md ledger_header_fonts"
              }
              onClick={() => onHandleCategory("Credit Note")}
            >
              Credit&nbsp;Note
            </div>
          </div>
        </div>

        <div id="ordersData" className="ledger_parent_div">
          <div className="leder_data_div">
            <div className="ledger_selected_section_header">
              <div className="display_flex">
                {selectedFilter === "Invoice" && (
                  <>
                    {" "}
                    <div
                      onClick={() => setInvoiceFilter(true)}
                      className={cx("invoice_filter_tab", {
                        activeInvoiceFilterTab: invoiceFilter?.pending,
                      })}
                    >
                      Pending Invoices
                    </div>
                    <div
                      onClick={() => setInvoiceFilter(false)}
                      className={cx("invoice_filter_tab", {
                        activeInvoiceFilterTab: !invoiceFilter?.pending,
                      })}
                    >
                      All Invoices
                    </div>
                  </>
                )}
              </div>

              <h1 className="color_primary">
                <b>{selectedFilter}</b>
              </h1>
            </div>
            {/* ledger shimmer  */}
            {ledger.loader && (
              <div
                className={cx(
                  "ledger_section_stats ledger_border animateShimmer"
                )}
              >
                <div>
                  <h4>
                    <b></b> &#x2a;{" "}
                  </h4>
                  <b className="font_10" style={{ margin: "0px" }}>
                    AMOUNT PENDING
                  </b>
                </div>

                {ledger?.data?.ledgerView?.isCreditCustomer && (
                  <>
                    <div className="font_14">
                      <p></p>
                      <p className="font_12" style={{ margin: "0px" }}>
                        UTILISED
                      </p>
                    </div>
                    <div className="font_14">
                      <p></p>
                      <p className="font_12" style={{ margin: "0px" }}>
                        SANCTIONED LIMIT
                      </p>
                    </div>
                  </>
                )}
              </div>
            )}
            {/* ledger data  */}
            {!ledger.loader && ledger?.data?.isEnabled && (
              <div
                className={cx(
                  "ledger_section_stats",
                  {
                    ledger_pending_border:
                      ledger?.data?.ledgerView?.balanceAmount < 0,
                  },
                  {
                    ledger_border: ledger?.data?.ledgerView?.balanceAmount >= 0,
                  }
                )}
              >
                <div>
                  <h4 className="ledger_header_fonts">
                    <b>₹ {Math.abs(ledger?.data?.ledgerView?.balanceAmount)}</b>{" "}
                    &#x2a;{" "}
                  </h4>
                  <b className="font_14" style={{ margin: "0px" }}>
                    Overdue{" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`overdue_info`}>
                          <strong>
                            {" "}
                            Summation of Grand total for the invoices which are
                            overdue
                          </strong>
                        </Tooltip>
                      }
                    >
                      <img src={info} className="ptrInfo" alt="info" />
                    </OverlayTrigger>
                  </b>
                </div>

                {ledger?.data?.ledgerView?.isCreditCustomer && (
                  <>
                    <div className=" ">
                      <p className="ledger_stats_font_value">
                        ₹ <b>{ledger?.data?.ledgerView?.creditDays}</b> &#x2a;{" "}
                      </p>
                      <p
                        className=" ledger_stats_font_key"
                      >
                        CREDIT DAYS
                        {/* <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`overdue_info`}>
                              <strong>
                                Total credit days sanctioned to the customer,
                                mention the table name{" "}
                              </strong>
                            </Tooltip>
                          }
                        >
                          <img src={info} className="ptrInfo" alt="info" />
                        </OverlayTrigger> */}
                      </p>
                    </div>
                    <div className=" ">
                      <p className="ledger_stats_font_value">
                        ₹ <b>{ledger?.data?.ledgerView?.usedLimit.toFixed(2)}</b> &#x2a;{" "}
                      </p>
                      <p
                        className=" ledger_stats_font_key"
                      >
                        UTILISED LIMIT
                      </p>
                    </div>

                    <div className="font_14 ">
                      <p className="ledger_stats_font_value">
                        ₹ <b>{ledger?.data?.ledgerView?.sanctionedLimit}</b>{" "}
                        &#x2a;{" "}
                      </p>
                      <p
                        className="ledger_stats_font_key"
                      >
                        SANCTIONED LIMIT
                      </p>
                    </div>
                    <div className=" ">
                      <p className="ledger_stats_font_value">
                        ₹ <b>{ledger?.data?.ledgerView?.additionalLimit}</b>{" "}
                        &#x2a;{" "}
                      </p>
                      <p
                        className=" ledger_stats_font_key"
                      >
                        ADDITIONAL LIMIT
                      </p>
                    </div>
                  </>
                )}
                <div className=" ">
                  <p className="ledger_stats_font_value">
                    ₹ <b>{ledger?.data?.ledgerView?.availableCoins}</b> &#x2a;{" "}
                  </p>
                  <p
                    className=" ledger_stats_font_key"
                    style={{ margin: "0px" }}
                  >
                    AVAILABLE COINS
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`overdue_info`}>
                          <strong>
                              Kindly refer to the Wallet Amount shown in Store page
                          </strong>
                        </Tooltip>
                      }
                    >
                      <img src={info} className="ptrInfo" alt="info" />
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="ledger_data_table">
            {selectedFilter === "Invoice" ? (
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="display_flex">
                        <input
                          type="checkbox"
                          className="cart_check"
                          id="payBulkBtnRef"
                          checked={selectAllOrders}
                          onChange={(e) => orderSelect(e, "", true)}
                        />

                        {selectedOrders?.length > 0 && !onBehalfStatus && (
                          <>
                            &nbsp; &nbsp; &nbsp; &nbsp;
                            <Button
                              className="bg_primary"
                              variant="contained"
                              onClick={() =>
                                setPastOrderCard({
                                  show: true,
                                  id: selectedOrders,
                                })
                              }
                            >
                              Pay&nbsp;Now
                            </Button>
                          </>
                        )}
                      </div>
                    </th>
                    <th>INVOICE DATE</th>
                    <th>INVOICE NUMBER</th>
                    <th>GRAND TOTAL</th>
                    <th>PAID AMT.</th>
                    <th>PENDING AMT.</th>
                    <th>AMT. UNDER VERIFICATION</th>
                    {
                      !invoiceFilter?.pending &&  <th>STATUS</th>
                    }
                    <th>DUE ON</th>
                    <th>OVERDUE BY DAYS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ledgerRecords.loader && (
                    <tr>
                      <td className="animateShimmer" colSpan={9}></td>
                    </tr>
                  )}
                  {!ledgerRecords.loader &&
                    ledgerRecords?.data?.ledgerData &&
                    !Object.keys(ledgerRecords?.data?.ledgerData)?.length && (
                      <tr>
                        <td colSpan={6} className="text_center">
                          No records found
                        </td>
                      </tr>
                    )}
                  {!ledgerRecords.loader &&
                    ledgerRecords?.data?.ledgerData &&
                    Object.keys(ledgerRecords?.data?.ledgerData)?.map(
                      (data) => {
                        return (
                          <>
                            <tr>
                              <td
                                colSpan={12}
                                className="ledger_data_table_mnth"
                              >
                                {data.toUpperCase()}, INVOICES
                              </td>
                            </tr>
                            {ledgerRecords?.data?.ledgerData[data].map(
                              (record, key) => {
                                return (
                                  <>
                                    {
                                      <tr>
                                    
                                    <td>
                                      {record.statusMap?.true && (
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-bottom-${key}`}
                                            >
                                              <strong>
                                                Select orders for bulk payments
                                              </strong>
                                            </Tooltip>
                                          }
                                        >
                                          <input
                                            type="checkbox"
                                            className="cart_check"
                                            checked={selectedOrders.includes(
                                              record.orderRetrieveId
                                            )}
                                            onChange={(e) =>
                                              orderSelect(
                                                e,
                                                record.orderRetrieveId
                                              )
                                            }
                                          />
                                        </OverlayTrigger>
                                      )}
                                    </td>
                                    <td className="no_wrap">
                                      <b>
                                        {moment(record.invoiceDate).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </b>
                                    </td>
                                    <td className="no_wrap">
                                      <div>{record.invoiceNumber}</div>
                                    </td>
                                    <td className="no_wrap">
                       
                                      ₹{record.grandTotal}
                                    </td>
                                    <td className="no_wrap">
                                      {record.paidAmountMap?.true ? (
                                        <div
                                          className="link_tag"
                                          onClick={() =>
                                            setPaymentView({
                                              show: true,
                                              invoice: record.invoiceNumber,
                                            })
                                          }
                                        >
                                          ₹ {record.paidAmountMap["true"]}
                                        </div>
                                      ) : (
                                        "0"
                                      )}
                                    </td>
                                    <td className="no_wrap">
                                      {" "}
                                      {+record?.pendingAmount > 0 ? (
                                        <div
                                          className="link_tag"
                                          onClick={() =>
                                            setPastOrderCard({
                                              show: true,
                                              id: [record.orderRetrieveId],
                                            })
                                          }
                                        >
                                          ₹ {record.pendingAmount}
                                        </div>
                                      ) : (
                                       <>₹ {record.pendingAmount}</> 
                                      )}
                                    </td>
                                    <td>₹ {record.unverifiedSum}</td>
                                    {
                                      !invoiceFilter?.pending &&   <td>{record.state}</td>
                                    }
                                    <td className="no_wrap">
                                      {record.dueDate}
                                    </td>
                                    <td>{record.overDueByDays}</td>
                                    <td
                                      className="no_wrap color_dark_primary cursor_pointer"
                                      onClick={() =>
                                        openInvoicePage(
                                          "Invoice",
                                          "",
                                          record.orderRetrieveId,
                                          ""
                                        )
                                      }
                                    >
                                      {" "}
                                      <HiDownload className="color_dark_primary" />{" "}
                                      &nbsp; Download
                                    </td>
                                  </tr>
                                    }
                                  </>
                                 
                                );
                              }
                            )}
                          </>
                        );
                      }
                    )}
                  {ledgerRecords?.data && (
                    <tr>
                      <td colSpan={12} className="ledgerTablePageFooter">
                        <IoIosArrowBack
                          onClick={previousPage}
                          className="cursor_pointer"
                        />
                        &nbsp;&nbsp; Showing &nbsp;&nbsp; Page: {page + 1}{" "}
                        &nbsp;&nbsp;
                        <IoIosArrowForward
                          onClick={() => nextPage(true)}
                          className="cursor_pointer"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : selectedFilter === "Ledger" ? (
              <table>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>TYPE</th>
                    <th>VOUCHER NO.</th>
                    <th>INVOICE NO.</th>
                    <th>MODE</th>
                    <th>TRANSACTION</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ledgerRecords.loader && (
                    <tr>
                      <td className="animateShimmer" colSpan={6}></td>
                    </tr>
                  )}
                  {!ledgerRecords.loader &&
                    ledgerRecords?.data?.ledgerData &&
                    !Object.keys(ledgerRecords?.data?.ledgerData)?.length && (
                      <tr>
                        <td colSpan={6} className="text_center">
                          No records found
                        </td>
                      </tr>
                    )}
                  {!ledgerRecords.loader &&
                    ledgerRecords?.data?.ledgerData &&
                    Object.keys(ledgerRecords?.data?.ledgerData)?.map(
                      (data) => {
                        return (
                          <>
                            <tr>
                              <td
                                colSpan={8}
                                className="ledger_data_table_mnth"
                              >
                                {data.toUpperCase()}, TRANSACTIONS
                              </td>
                            </tr>
                            {ledgerRecords?.data?.ledgerData[data].map(
                              (record) => {
                                return (
                                  <tr>
                                    <td>
                                      <b>
                                        {moment(record.createdTimestamp).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </b>
                                    </td>
                                    <td>
                                      <div
                                        className={cx(
                                          {
                                            creditType:
                                              record.transactionType ===
                                              "CREDIT_NOTE",
                                          },
                                          {
                                            saleType:
                                              record.transactionType === "SALE",
                                          },
                                          {
                                            receiptType:
                                              record.transactionType ===
                                              "RECEIPT",
                                          }
                                        )}
                                      >
                                        {" "}
                                        {record.transactionType}
                                      </div>
                                    </td>
                                    <td>{record.transactionNumber}</td>
                                    <td>{record.adjustmentInvoice}</td>
                                    <td>{record.mode}</td>
                                    <td>₹ {record.transactionAmount}</td>
                                    {/* <td>{record.status}</td> */}
                                    <td
                                      className="no_wrap color_dark_primary cursor_pointer"
                                      onClick={() =>
                                        openInvoicePage(
                                          record.transactionType,
                                          record.transactionSubType,
                                          record.transactionSubTypeId,
                                          record.transactionNumber
                                        )
                                      }
                                    >
                                      {" "}
                                      <HiDownload className="color_dark_primary" />{" "}
                                      &nbsp; Download
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </>
                        );
                      }
                    )}
                  {ledgerRecords?.data?.totalCount > 0 && (
                    <tr>
                      <td colSpan={10} className="ledgerTablePageFooter">
                        <IoIosArrowBack
                          onClick={previousPage}
                          className="cursor_pointer"
                        />
                        &nbsp;&nbsp; Showing &nbsp;&nbsp;
                        {page * 10 > ledgerRecords?.data?.totalCount
                          ? ledgerRecords?.data?.totalCount
                          : page * 10 + 1}
                        -{" "}
                        {(page + 1) * 10 > ledgerRecords?.data?.totalCount
                          ? ledgerRecords?.data?.totalCount
                          : (page + 1) * 10}{" "}
                        of &nbsp;{ledgerRecords?.data?.totalCount}&nbsp;&nbsp;{" "}
                        <IoIosArrowForward
                          onClick={nextPage}
                          className="cursor_pointer"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : selectedFilter === "Credit Note" ? (
              <table>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>TYPE</th>
                    <th>CN No.</th>
                    <th>INVOICE NO.</th>
                    <th>CN Value</th>
                    <th>CN Consumed Amt.</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ledgerRecords.loader && (
                    <tr>
                      <td className="animateShimmer" colSpan={6}></td>
                    </tr>
                  )}
                  {!ledgerRecords.loader &&
                    ledgerRecords?.data?.ledgerData &&
                    !Object.keys(ledgerRecords?.data?.ledgerData)?.length && (
                      <tr>
                        <td colSpan={6} className="text_center">
                          No records found
                        </td>
                      </tr>
                    )}
                  {!ledgerRecords.loader &&
                    ledgerRecords?.data?.ledgerData &&
                    Object.keys(ledgerRecords?.data?.ledgerData)?.map(
                      (data) => {
                        return (
                          <>
                            <tr>
                              <td
                                colSpan={8}
                                className="ledger_data_table_mnth"
                              >
                                {data.toUpperCase()}, TRANSACTIONS
                              </td>
                            </tr>
                            {ledgerRecords?.data?.ledgerData[data].map(
                              (record) => {
                                return (
                                  <tr>
                                    <td>
                                      <b>
                                        {moment(record.createdTimestamp).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </b>
                                    </td>
                                    <td>
                                      <div className="creditType">
                                        {" "}
                                        {record.cnType}
                                      </div>
                                    </td>
                                    <td>{record.cnNumber}</td>
                                    <td>{record.sourceInvoiceNumber}</td>
                                    <td>₹ {record.cnValue}</td>
                                    <td>₹ {record.consumedAmount}</td>
                                    {
                                      record.cnId?.length ?<td
                                      className="no_wrap color_dark_primary cursor_pointer"
                                      onClick={() =>
                                        openInvoicePage(
                                          "CREDIT_NOTE",
                                          record.cnType,
                                          record.cnId,
                                          record?.transactionNumber
                                        )
                                      }
                                    >
                                      {" "}
                                      <HiDownload className="color_dark_primary" />{" "}
                                      &nbsp; Download
                                    </td>:<td></td>
                                    }
                                    
                                  </tr>
                                );
                              }
                            )}
                          </>
                        );
                      }
                    )}
                  {ledgerRecords?.data?.totalCount > 0 && (
                    <tr>
                      <td colSpan={10} className="ledgerTablePageFooter">
                        <IoIosArrowBack
                          onClick={previousPage}
                          className="cursor_pointer"
                        />
                        &nbsp;&nbsp; Showing &nbsp;&nbsp;
                        {page * 5 > ledgerRecords?.data?.totalCount
                          ? ledgerRecords?.data?.totalCount
                          : page * 5 + 1}
                        -{" "}
                        {(page + 1) * 5 > ledgerRecords?.data?.totalCount
                          ? ledgerRecords?.data?.totalCount
                          : (page + 1) * 5}{" "}
                        of &nbsp;{ledgerRecords?.data?.totalCount}&nbsp;&nbsp;{" "}
                        <IoIosArrowForward
                          onClick={nextPage}
                          className="cursor_pointer"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Ledger;
