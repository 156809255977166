
export const GetBrowserName=()=> {
    // Check if the navigator object is available
    if (typeof navigator !== 'undefined') {
      // Get the user agent string
      var userAgent = navigator.userAgent;
      
      // Check for popular browsers
      if (/Firefox/.test(userAgent)) {
        return 'Firefox';
      } else if (/Chrome/.test(userAgent)) {
        return 'Chrome';
      } else if (/Safari/.test(userAgent)) {
        return 'Safari';
      } else if (/Opera/.test(userAgent) || /OPR/.test(userAgent)) {
        return 'Opera';
      } else if (/Edge/.test(userAgent)) {
        return 'Microsoft Edge';
      } else if (/Trident/.test(userAgent)) {
        return 'Internet Explorer';
      }
    }
    
    // Return unknown if navigator is not available or browser is not recognized
    return 'Unknown';
  }


 export const isIOS = () => {
    const browserInfo = navigator.userAgent.toLowerCase();
    // iPhone / iPad
    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    // also check for sims/ems
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return true;
    }
    // default
    return false;
  };

  
  
  
  
  