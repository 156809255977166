import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    appVersionCode: variables.appVersionCode,
  },
};
const ADMIN_URL = variables.ADMIN_URL;
let onBehalfStatus=false
const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;


  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    onBehalfStatus=true
  }
  return true;
};

export const Cart_Actions = async (type, params, body) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "ADD_TO_CART":
        onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/addCart`, body, {
          params: params,
          headers: config.headers,
        });

      case "REMOVE_FROM_CART":
        onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/deleteMedicine`, body, {
          params: params,
          headers: config.headers,
        });

      case "VIEW_CART":
       onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/viewCart`, body, {
          params: params,
          headers: config.headers,
        });
      case "EDIT_CART":
        onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/editCart`, body, {
          params: params,
          headers: config.headers,
        });

      case "NOTIFY_OUT_OF_STOCK":
        onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/v2/notifyOutOfStock`, body, {
          params: params,
          headers: config.headers,
        });

      case "PLACE_ORDER":
        onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/v4/order`, body, {
          params: params,
          headers: config.headers,
        });
      case "CHANGE_DELIVERY_SLOT":
        onBehalfStatus? config.headers.source="pharmacyWebOnbehalf":config.headers.source="pharmacyWeb"
        return axios.post(`${ADMIN_URL}/home/changeDeliverySlot`, body, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return widgets;
};
