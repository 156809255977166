import React, { useEffect, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../HomePage/HomePage.css";
import "../../App.css";
import Companies from "../../Images/svg/500_companies.svg";
import SpecialOffers from "../../Images/svg/special_offers.svg";
import Factory from "../../Images/svg/factory.svg";
import Corona from "../../Images/svg/corona.svg";
import RegularSchemes from "../../Images/svg/regular_schemes.svg";
import Speciality from "../../Images/svg/speciality.svg";
import Generic from "../../Images/svg/generic.svg";
import Ayurvedic from "../../Images/svg/ayurvedic.svg";
import Surgical from "../../Images/svg/surgical.svg";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import SilvercrossLogo from "../../Images/svg/SilverCross_Icon_Logo.svg";
import NearExpiry from "../../Images/svg/near_expiry.svg";
const Categories = ({ appSectionRes, shimmer }) => {
  let navigate = useNavigate();
  useEffect(() => {
    //     active: true
    // apiEndPoint: "/home/company/viewAllCompanies"
    // categorySearchString: null
    // iconName: "companySection"
    // id: "5f5b4c62ae856346d8b2016d"
    // newSection: false
    // rank: 1
    // screenName: null
    // section_name: "500+ Companies"
    // console.log(appSectionRes);
  }, [appSectionRes]);
  return (
    <Container fluid className="categories_section">
      <div className="font_ubuntu font_weight_md">Explore Categories</div>
      {shimmer ? (
        <Container>
          <div className="skeletal_div animateShimmer"></div>
        </Container>
      ) : (
        <Container className="cat_list">
          {/* SilverCross Category */}
          <div
            className="cat_parent"
            onClick={async () => {
              Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "section_tapped",
                sectionId: "silvercross",
                screen_name: localStorage.getItem("currentScreen"),
              });
              navigate("/silvercross");
            }}
          >
            <div className="cat_item" style={{ backgroundColor: "#592C82" }}>
              <img src={SilvercrossLogo} alt="SilverCross" /> <br />
              <span className="new_tag_cat font_ubuntu">NEW</span>
            </div>

            <div className="txt_center cat_name">SilverCross</div>
          </div>

          {appSectionRes &&
            appSectionRes.map((cat, i) => {
              return (
                <Fragment key={i}>
                  {cat.section_name === "500+ Companies" && cat.active && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/companies");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Companies} alt="500+ Companies" /> <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name">
                        500+ <br /> Companies
                      </div>
                    </div>
                  )}
                  {cat.section_name === "Special Offers" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/specialOffers");
                      }}
                    >
                      <div className="cat_item">
                        <img src={SpecialOffers} alt="Special Offers" /> <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>
                      <div className="txt_center cat_name">
                        Special <br /> Offers
                      </div>
                    </div>
                  )}

                  {cat.section_name === "Regular Schemes" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/regularSchemes");
                      }}
                    >
                      <div className="cat_item">
                        <img src={RegularSchemes} alt="Regular Schemes" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name">
                        Regular <br /> Schemes
                      </div>
                    </div>
                  )}

                  {cat.section_name === "Corona Essentials" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/coronaEssentials");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Corona} alt="Corona Essentials" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name">
                        Corona <br /> Essentials
                      </div>
                    </div>
                  )}

                  {cat.section_name === "Surgical" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/Surgicals");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Surgical} alt="Surgical" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name padding_5">
                        Surgical
                      </div>
                    </div>
                  )}

                  {cat.section_name === "Generic" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/generic");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Generic} alt="Generic" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name padding_5">
                        Generic
                      </div>
                    </div>
                  )}

                  {cat.section_name === "Ayurvedic" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/Ayurvedic");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Ayurvedic} alt="Ayurvedic" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name padding_5">
                        Ayurvedic
                      </div>
                    </div>
                  )}

                  {cat.section_name === "Allopathy" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/Allopathy");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Speciality} alt="Allopathy" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name padding_5">
                        Allopathy
                      </div>
                    </div>
                  )}
                  {cat.section_name === "Specialty" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/Specialty");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Speciality} alt="Specialty" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name padding_5">
                        Speciality
                      </div>
                    </div>
                  )}
                  {cat.section_name === "Factory to Pharmacy" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/factorytopharmacy");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Factory} alt="Factory to Pharmacy" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name">
                        Factory to <br /> Pharmacy
                      </div>
                    </div>
                  )}
                  {cat.section_name === "OTC" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/categories/OTC");
                      }}
                    >
                      <div className="cat_item">
                        <img src={Speciality} alt="OTC" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name padding_5">OTC</div>
                    </div>
                  )}

                  {cat.section_name === "Deals_SE" && (
                    <div
                      className="cat_parent"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "section_tapped",
                          sectionId: cat.iconName,
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/productlist/nearExpiry");
                      }}
                    >
                      <div className="cat_item">
                        <img src={NearExpiry} alt="Near Expiry" />
                        <br />
                        {cat.newSection && (
                          <span className="new_tag_cat font_ubuntu">NEW</span>
                        )}
                      </div>

                      <div className="txt_center cat_name">
                        {cat.section_name}
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
        </Container>
      )}
    </Container>
  );
};

export default React.memo(Categories);
