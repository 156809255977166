export const AllLanguages = [
  { value: "English", label: "English" },
  { value: "Kannada", label: "ಕನ್ನಡ" },
  { value: "Telugu", label: "తెలుగు" },
  { value: "Tamil", label: "தமிழ்" },
  { value: "Bengali", label: "বাংলা" },
];

export const LanguagesData = {
  English: {
    buttonText: "Select Language",
    step1: {
      heading1: "Dear Retailer",
      subHeading: " I am here to help you with pharmaceutical queries",
    },
    step2: {
      heading1: "Try Speaking",
      subHeading: "Just Say Hi CAPSI",
      sampleMessages: [
        "Hi Capsi, What is the difference between Allopathy and Generics",
        "What all are the indications of Zerodol SP Tablet",
        "What compositions are better to suggest for cold & flu patients",
      ],
      terms:
        " The information generated by this AI tool is not provided by Saveo and is for general educational purposes only.",
    },
    setUpButtonText: "Continue to Set Up",
    setUpModalText: {
      header1: "Great.., You're all set.",
      subHeading:
        "Now you can ask anything related to pharmaceutical and learn more about it hands-free by just speaking",
      btnText: "Start Using",
    },
    step3: {
      header: "How may I assist you today ?",
      questionsList: [
        "What are the indications of Zerodol SP Tablet",
        "Help me to know more about omicron",
        "Tell me top generic medicines for cold",
      ],
    },
    languageCode: "en-IN",
  },
  Kannada: {
    buttonText: "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    step1: {
      heading1: "ಆತ್ಮೀಯ ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿ",
      subHeading: " ಔಷಧೀಯ ಪ್ರಶ್ನೆಗಳಿಗೆ ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ನಾನು ಇಲ್ಲಿದ್ದೇನೆ",
    },
    step2: {
      heading1: "ಮಾತನಾಡಲು ಪ್ರಯತ್ನಿಸಿ",
      subHeading: "ಕೇವಲ ಹಾಯ್ ಕ್ಯಾಪ್ಸಿ ಎಂದು ಹೇಳಿ",
      sampleMessages: [
        "ಹಾಯ್ ಕ್ಯಾಪ್ಸಿ, ಅಲೋಪತಿ ಮತ್ತು ಜೆನೆರಿಕ್ಸ್ ನಡುವಿನ ವ್ಯತ್ಯಾಸವೇನು?",
        "Zerodol SP Tablet (ಝೆರೊಡಾಲ್ SP Tablet) ಸೂಚನೆಗಳೇನು",
        "ಶೀತ ಮತ್ತು ಜ್ವರ ರೋಗಿಗಳಿಗೆ ಯಾವ ಸಂಯೋಜನೆಗಳನ್ನು ಶಿಫಾರಸು ಮಾಡುವುದು ಉತ್ತಮ",
      ],
      terms:
        " ಈ AI ಉಪಕರಣದಿಂದ ರಚಿಸಲಾದ ಮಾಹಿತಿಯನ್ನು Saveo ಒದಗಿಸಿಲ್ಲ ಮತ್ತು ಸಾಮಾನ್ಯ ಶೈಕ್ಷಣಿಕ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಮಾತ್ರ.",
    },
    setUpButtonText: "ಹೊಂದಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿ",
    setUpModalText: {
      header1: "ಅದ್ಭುತವಾಗಿದೆ.., ನೀವು ಸಿದ್ಧರಾಗಿರುವಿರಿ.",
      subHeading:
        "ಈಗ ನೀವು ಫಾರ್ಮಾಸ್ಯುಟಿಕಲ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದನ್ನಾದರೂ ಕೇಳಬಹುದು ಮತ್ತು ಮಾತನಾಡುವ ಮೂಲಕ ಹ್ಯಾಂಡ್ಸ್-ಫ್ರೀ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಬಹುದು",
      btnText: "ಬಳಸಲು ಪ್ರಾರಂಭಿಸಿ",
    },
    step3: {
      header: "ಇಂದು ನಾನು ನಿಮಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು ?",
      questionsList: [
        "Zerodol SP Tablet (ಝೆರೊಡಾಲ್ ಎಸ್ಪಿ) ಸೂಚನೆಗಳೇನು?",
        "ಓಮಿಕ್ರಾನ್ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ನನಗೆ ಸಹಾಯ ಮಾಡಿ",
        "ಶೀತಕ್ಕೆ ಟಾಪ್ ಜೆನೆರಿಕ್ ಔಷಧಗಳನ್ನು ಹೇಳಿ",
      ],
    },
    languageCode: "kn-IN",
  },
  Telugu: {
    buttonText: "భాషను ఎంచుకోండి",
    step1: {
      heading1: "ప్రియమైన రిటైలర్",
      subHeading:
        " ఫార్మాస్యూటికల్ ప్రశ్నలతో మీకు సహాయం చేయడానికి నేను ఇక్కడ ఉన్నాను",
    },
    step2: {
      heading1: "మాట్లాడటానికి ప్రయత్నించండి",
      subHeading: "హాయ్ క్యాప్సీ అని చెప్పండి",
      sampleMessages: [
        "హాయ్ క్యాప్సీ, అల్లోపతి మరియు జెనరిక్స్ మధ్య తేడా ఏమిటి",
        "Zerodol SP Tablet (సీరోడాల్ స్ప్) యొక్క సూచనలు ఏమిటి",
        "జలుబు & ఫ్లూ రోగులకు ఏ కూర్పులను సూచించడం మంచిది",
      ],
      terms:
        "ఈ AI సాధనం ద్వారా రూపొందించబడిన సమాచారం Saveo ద్వారా అందించబడలేదు మరియు సాధారణ విద్యా ప్రయోజనాల కోసం మాత్రమే. ",
    },
    setUpButtonText: "సెటప్ చేయడానికి కొనసాగించండి",
    setUpModalText: {
      header1: "గ్రేట్.., మీరు అంతా సిద్ధంగా ఉన్నారు.",
      subHeading:
        "ఇప్పుడు మీరు ఫార్మాస్యూటికల్‌కి సంబంధించిన ఏదైనా అడగవచ్చు మరియు మాట్లాడటం ద్వారా హ్యాండ్స్-ఫ్రీ గురించి మరింత తెలుసుకోవచ్చు",
      btnText: "ఉపయోగించడం ప్రారంభించండి",
    },
    step3: {
      header: "ఈరోజు నేను మీకు ఎలా సహాయం చేయగలను ?",
      questionsList: [
        "Zerodol SP Tablet (సీరోడాల్ స్ప్) యొక్క సూచనలు ఏమిటి",
        "ఓమిక్రాన్ గురించి మరింత తెలుసుకోవడానికి నాకు సహాయం చేయండి",
        "జలుబు కోసం టాప్ జెనరిక్ మందులు చెప్పండి",
      ],
    },
    languageCode: "te-IN",
  },
  Tamil: {
    buttonText: "மொழியை தேர்ந்தெடுங்கள்",
    step1: {
      heading1: "அன்புள்ள சில்லறை விற்பனையாளர்",
      subHeading:
        " மருந்து சம்பந்தமான கேள்விகளுக்கு உங்களுக்கு உதவ நான் இங்கு இருக்கிறேன்",
    },
    step2: {
      heading1: "பேச முயற்சிக்கவும்",
      subHeading: "ஹாய் கேப்சி என்று சொல்லுங்கள்",
      sampleMessages: [
        "ஹாய் கேப்சி, அலோபதிக்கும் ஜெனரிக்ஸுக்கும் என்ன வித்தியாசம்",
        "ஜெரோடோல் எஸ்பி மாத்திரை (Zerodol SP Tablet) மருந்துக்கான அறிகுறிகள் யாவை",
        "சளி மற்றும் காய்ச்சல் நோயாளிகளுக்கு என்ன கலவைகளை பரிந்துரைக்க சிறந்தது",
      ],
      terms:
        "இந்த AI கருவியால் உருவாக்கப்பட்ட தகவல்கள் Saveo ஆல் வழங்கப்படவில்லை மற்றும் பொதுவான கல்வி நோக்கங்களுக்காக மட்டுமே. ",
    },
    setUpButtonText: "அமைப்பதைத் தொடரவும்",
    setUpModalText: {
      header1: "அருமை.., தயாராகிவிட்டீர்கள்.",
      subHeading:
        "இப்போது நீங்கள் மருந்து தொடர்பான எதையும் கேட்கலாம் மற்றும் பேசுவதன் மூலம் அதைப் பற்றி மேலும் அறியலாம்",
      btnText: "பயன்படுத்தத் தொடங்குங்கள்",
    },
    step3: {
      header: "இன்று நான் உங்களுக்கு எப்படி உதவ முடியும் ?",
      questionsList: [
        "ஜெரோடோல் எஸ்பி மாத்திரை (Zerodol SP Tablet) மருந்துக்கான அறிகுறிகள் என்னென்ன",
        "ஓமிக்ரானைப் பற்றி மேலும் அறிய எனக்கு உதவுங்கள்",
        "ஜலதோஷத்திற்கு சிறந்த பொதுவான மருந்துகளை சொல்லுங்கள்",
      ],
    },
    languageCode: "ta-IN",
  },
  Bengali: {
    buttonText: "ভাষা নির্বাচন কর",
    step1: {
      heading1: "প্রিয় খুচরা বিক্রেতা",
      subHeading: "আমি ফার্মাসিউটিক্যাল প্রশ্নে আপনাকে সাহায্য করতে এখানে আছি",
    },
    step2: {
      heading1: "কথা বলার চেষ্টা করুন",
      subHeading: "শুধু হাই ক্যাপসি বলুন",
      sampleMessages: [
        "হাই ক্যাপসি, অ্যালোপ্যাথি এবং জেনেরিকের মধ্যে পার্থক্য কী?",
        "Zerodol SP Tablet এর সমস্ত লক্ষণ কি",
        "সর্দি ও ফ্লু রোগীদের জন্য কোন রচনাগুলি সুপারিশ করা ভাল",
      ],
      terms:
        "এই AI টুল দ্বারা উত্পন্ন তথ্য Saveo দ্বারা প্রদান করা হয় না এবং শুধুমাত্র সাধারণ শিক্ষাগত উদ্দেশ্যে।",
    },
    setUpButtonText: "সেট আপ চালিয়ে যান",
    setUpModalText: {
      header1: "দারুণ.., আপনি সম্পূর্ণ প্রস্তুত।",
      subHeading:
        "এখন আপনি ফার্মাসিউটিক্যাল সম্পর্কিত যেকোনো কিছু জিজ্ঞাসা করতে পারেন এবং শুধু কথা বলে হ্যান্ডস-ফ্রি সম্পর্কে আরও জানতে পারেন",
      btnText: "ব্যবহার শুরু করা",
    },
    step3: {
      header: "আজ আমি আপনাকে কিভাবে সহায়তা করতে পারি ?",
      questionsList: [
        "Zerodol SP Tablet এর ইঙ্গিত কি",
        "omicron সম্পর্কে আরও জানতে আমাকে সাহায্য করুন",
        "আমাকে ঠান্ডার জন্য সেরা জেনেরিক ওষুধ বলুন",
      ],
    },
    languageCode: "bn-IN",
  },
};
