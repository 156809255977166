import React from "react";
import "./LoadingDots.css";
const LoadingDots = () => {
  return (
    <div className="dots_container">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingDots;
