/* eslint-disable default-case */
import axios from "axios";
import qs from "qs";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    source: "pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const BASE = variables.ADMIN_URL;
const eagleUrl=variables.eagleUrl

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source = "pharmacyWebOnbehalf";
  }
  return true;
};

export const getFaqData = async (type, params) => {
  const data = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "EN";
    switch (type) {
      case "fetchFaqs":
        return axios.get(`${BASE}/home/fetchFaqs`, {
          params: params,
          headers: headers,
        });
    }
  });
  return data;
};

export const createTicket = async (body, params) => {
  const data = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "en";
    return axios.post(`${BASE}/helpNSupport/createRetailerTicketApp`, body, {
      params: params,
      paramsSerializer: (params) => {
        //   return qs.stringify(params)
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      headers: headers,
    });
  });
  return data;
};

export const getIssueWithOrderData = async (body, params) => {
  const data = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "en";
    return axios.get(`${BASE}/helpNSupport/issueAppFlow`, {
      params: params,
      headers: headers,
    });
  });
  return data;
};

export const getRetailerTickets = async (body, params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/helpNSupport/fetchRetailerTickets`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

//------------------------------------------- raise issue module apis starts -------------------------------------------------------------

export const getIssueStatus = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/home/issue/getAllIssueStatus`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const getIssuesByUser = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/home/issue/getIssuesByUser`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const createIssue = async (body, params) => {
  const data = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "en";
    return axios.post(`${BASE}/home/issue/raiseIssue`, body, {
      params: params,
      headers: headers,
    });
  });
  return data;
};

export const uploadMultiImages = async (body) => {
  const data = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "en";
    return axios.post(`${eagleUrl}/wholesale/uploadPhotosOne`, body, {
      "Content-Type": "multipart/form-data",
      headers: headers,
    });
  });
  return data;
};

export const getConversationByUser = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/home/issue/getConversationByUserIssueId`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const sendMsg = async (body) => {
  const data = getToken().then(() => {
    let headers = JSON.parse(JSON.stringify(config.headers))
    headers.locale = "en";
    return axios.post(`${BASE}/home/issue/createConversation`, body, {
      headers: headers,
    });
  });
  return data;
};

export const getIssuesCountByUser = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/home/issue/getIssuesCountByUser`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};
export const getIssueTypes = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/home/issue/getAllIssueTypes`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const updateTicketStatus = async (params) => {
  const data = getToken().then(() => {
    return axios.put(`${BASE}/home/issue/changeUserIssueStatus`, {}, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const searchByOrderId = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/admin/invoiceTrack/searchByOrderId`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

//------------------------------------------- raise issue module apis ends -------------------------------------------------------------

