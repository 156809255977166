import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "../../App.css";
import "./dialog.css";
import Error from "../../Images/gifs/error3.gif";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { variables } from "../../environments";
import { IoIosCopy } from "react-icons/io";
const ErrorModal = (props) => {
  const [onBehalfStatus, setOnBehalfStatus] = useState(false);

  const cancel = async () => {
    Common_Event_Hit("COMMON_EVENT", "cancel_tap");
    props.onHide();
  };

  const copyData = () => {
    navigator.clipboard.writeText(
      JSON.stringify({
        clientMessage: props.content,
        error: props?.apiData?.request?.responseText,
        url: props?.apiData?.config?.url,
        params: props?.apiData?.config?.params,
        screen: localStorage.getItem("currentScreen"),
        statusCode: props?.apiData?.status,
        retailerCode: onBehalfStatus
          ? localStorage.getItem("onBehalfRetCode")
          : JSON.parse(localStorage.getItem("userData"))?.retailerCode,
        version: variables.version,
      })
    );
  };

  useEffect(() => {
    let onBehalfCode = localStorage.getItem("onBehalfRetCode");
    if (onBehalfCode?.length) {
      setOnBehalfStatus(true);
    }
  }, []);

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className=" text_center margin_auto"
        >
          <img src={Error} className="delete_modal_svg" alt="Delete" />
          <div className="font_ubuntu color_primary_lt font_weight_md font_16 modal_content">
            {props.content}
          </div>
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
       
      </Modal.Body> */}
      <Modal.Footer className="modal_footer">
        <div className="display_flex space_btw" style={{ width: "100%" }}>
          {props.uploadToCart ? (
            <button
              className=" width_100 font_weight_md got_it_btn"
              onClick={cancel}
            >
              GOT IT
            </button>
          ) : (
            <>
              <div>
                <Button
                  onClick={() => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "negative_tap",
                      error_message: props.content,
                    });
                    cancel();
                  }}
                  className="bg_white brdr_grey cancel_btn color_grey font_weight_md"
                >
                  CANCEL
                </Button>
              </div>

              <Button
                onClick={props.tryAgain ? props.tryAgain : props.onHide}
                className="bg_primary brdr_none font_weight_md delete_btn"
              >
                TRY&nbsp;AGAIN
              </Button>
            </>
          )}
        </div>
        <div
          className="display_flex space_btw admin_data"
          style={{ width: "100%" }}
        >
          <p className="retCode">
            {onBehalfStatus
              ? localStorage.getItem("onBehalfRetCode")
              : JSON.parse(localStorage.getItem("userData"))?.retailerCode}{" "}
          </p>
          <p className="version">
            v{variables.version}{" "}
            {onBehalfStatus && (
              <span onClick={copyData}>
                &nbsp;&nbsp; <IoIosCopy className="copy_icon" />
              </span>
            )}
          </p>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ErrorModal);
