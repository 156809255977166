import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "../HomePage/HomePage.css";
import "../../App.css";
import { useNavigate } from "react-router";
import { Event_Actions } from "../../Actions/UserEvents";
import { MY_ACCOUNT_IMAGES } from "../../Images/svg";

const MyAccount = ({ categoryRes }) => {
  let navigate = useNavigate();

  const eventRecord = (cat) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let time = new Date().getTime();
    const body = {
      deviceId: "",
      eventName: "account_section_tap",
      eventProperties: {
        timestamp: time,
        section_id: cat.iconName,
        screens_visited: localStorage.getItem("screenCount"),
        session_number: localStorage.getItem("sessionCount"),
        enabled: userData.enabled,
        deviceName: navigator.userAgent,
        phoneNumber: userData.mobileNo,
        locale: "en",
        userId: userData.id,
      },
      mobileNo: userData.mobileNo,
      source: "pharmacyWeb",
      userId: userData.id,
      version: "",
    };

    Event_Actions("EVENT", {}, body);
  };

  useEffect(() => {
    console.log({ categoryRes });
  }, [categoryRes]);
  return (
    <Container fluid className="categories_section">
      <div className="font_ubuntu font_weight_md">MY ACCOUNT</div>

      <Container fluid className="myAccount_list">
        {/* {categoryRes.map((cat) => { */}
        {/* return cat.data.sectionName === "Your Orders" ? ( */}
        {categoryRes &&
          categoryRes?.data?.map((cat) => {
            if (cat.widgetName === "account_screen") {
              return (
                <>
                  {(cat.iconName === "yourOrders" ||
                    cat.iconName === "profile" ||
                    cat.iconName === "yourReturns" ||
                    cat.iconName === "yourBounces" ||
                    cat.iconName === "downloadCsv" ||
                    cat.iconName === "applyCredit" ||
                    cat.iconName === "qna" ||
                    cat.iconName === "reportIssue" ||
                    cat.iconName === "yourExpiry") && (
                    <div
                      onClick={() => {
                        eventRecord(cat);
                        if (cat.iconName === "yourBounces") {
                          navigate(`/products/yourBounces`);
                        } else if (
                          cat.iconName === "applyCredit" ||
                          cat.iconName === "downloadCsv" ||
                          cat.iconName === "yourExpiry"
                        ) {
                          navigate(`/${cat.iconName}`);
                        } else if (cat.iconName === "qna") {
                          navigate("/help-support/QandA");
                        } else if (cat.iconName === "reportIssue") {
                          navigate("/help-support/ReportIssue");
                        } else if (cat.iconName === "yourOrders") {
                          navigate("/account/yourOrders?type=live");
                        } else {
                          navigate(`/account/${cat.iconName}`, {
                            state: {
                              data: { pageId: cat.sectionName },
                            },
                          });
                        }
                      }}
                    >
                      <div className="account_item">
                        <img
                           src={MY_ACCOUNT_IMAGES[cat.iconName]}
                          alt={cat.iconName}
                        />{" "}
                        {cat.newSection && (
                          <>
                            <span className="new_tag font_ubuntu">NEW</span>
                          </>
                        )}
                      </div>

                      <div className="txt_center cat_name">
                        {cat.sectionName}
                      </div>
                    </div>
                  )}
                </>
              );
            }
          })}
      </Container>
    </Container>
  );
};

export default React.memo(MyAccount);
