import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  Container,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import "./Orders.css";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { usersData } from "../../App";
import OrderCard from "./OrderCard";
import EmptyOrders from "../../Images/png/empty_orders.jpg";
import ErrorModal from "../Modals/ErrorModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { useSearchParams } from "react-router-dom";
import { getOverDueOrderIds, getPastOrderData } from "../../Actions/Ledger";
import InfiniteScroll from "react-infinite-scroll-component";
import InfoModal from "../Modals/InfoModal";
import { Digital_Payment_Actions } from "../../Actions/DigitalPayment";
import { DigitalPaymentModal } from "../Modals/DigitalPaymentModal";
import { Button } from "@mui/material";

const OverDueOrders = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [homeShimmer, setHomeShimmer] = useState(false);
  const { userData, setErrorHandler,onBehalfStatus } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [orderIds, setOrderIds] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAllOrders, setSelectAllOrders] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [activePaymentModes, setActivePaymentModes] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState(null);
  const [resetPastOrder, setResetPastOrder] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  const fetchMoreData = () => {
    setPageNo(+pageNo + 1);
  };

  const getOrdersList = async () => {
    setHomeShimmer(true);
    let data = JSON.parse(localStorage.getItem("userData"));
    getOverDueOrderIds({ retailerCode: data.retailerCode })
      .then((res) => {
        setOrderIds(res.data.data.orderRetrieveIdList);
        getOrdersData(res.data.data.orderRetrieveIdList.slice(0, 9));
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "overdue_orders_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getOrdersList();
          },
        });
      });
  };

  const getOrdersData = (idList) => {
    if (idList.length) {
      let data = JSON.parse(localStorage.getItem("userData"));
      let body = {
        orderRetrieveIdList: idList,
      };
      let params = {
        retailerCode: data.retailerCode,
      };
      getPastOrderData(params, body)
        .then((res) => {
          let data = [...ordersData];
          data.push(...res.data.data);
          setOrdersData(data);
          setHomeShimmer(false);
        })
        .catch((error) => {
          setErrorHandler({
            show: true,
            screen: "overdue_orders_screen",
            errorList: [{ error: error, code: "" }],
            fn: function () {
              getOrdersData(idList);
            },
          });
        });
    } else {
      setHasMore(false);
    }
  };

  const refresh = () => {
    getOrdersList();
  };

  const resetOrderData = () => {
    let pastInvoiceDiv = document.getElementById("ordersData");
    pastInvoiceDiv.scrollTop = 0;
    window.scrollTo(0, 0);
    setPageNo(0);
    setOrdersData([]);
    setOrderIds([]);
    getOrdersList();
  };
  const orderSelect = (event, inv, selectAll = false) => {
    if (event.target.checked) {
      if (selectAll) {
        setSelectAllOrders(true);
        let selectedInv = [];
        ordersData.forEach((order) => {
          if (order.onlinePaymentEnabled) {
            selectedInv.push(order.invoiceNumber);
          }
        });
        setSelectedOrders(selectedInv);
      } else {
        let orders = [...selectedOrders];
        orders.push(inv);
        setSelectedOrders(orders);
      }
    } else {
      if (selectAll) {
        setSelectedOrders([]);
        setSelectAllOrders(false);
      } else {
        let orders = [...selectedOrders];
        let index = orders.indexOf(inv);
        orders.splice(index, 1);
        setSelectedOrders(orders);
      }
    }
  };
   // Get active payment modes & status
   const getActivePaymentModes = () => {
    const params = { 
      source: "WEB",
    };
    let body={
      invoiceNumber:selectedOrders,
    }
    Digital_Payment_Actions("ACTIVE_PAYMENT_MODES", params,body)
      .then((res) => {
        if (res.data.data.status === "ERROR") {
          setInfoMsg(res.data.data.message);
          setShowInfo(true);
        } else {
          setActivePaymentModes(res.data.data);
          document.body.className = "overflow-hidden";
          setOpenPayment(!openPayment);
        }

        document.body.className = "overflow-hidden";
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "past_orders_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getActivePaymentModes();
          },
        });
      });
  };

  // Handle Digital Payment Modal
  const handleDigitalPayment = (resetData) => {
    if (!openPayment) {
      getActivePaymentModes();
    } else {
      document.body.className = "";
      setOpenPayment(!openPayment);
      if (resetData) {
        resetOrderData();
      }
    }
  };

  const handleInfoReset = () => {
    setShowInfo(false);
    if (resetPastOrder) {
      setResetPastOrder(false);
      resetOrderData();
    }
  };
  useEffect(() => {
    let data = localStorage.getItem("userData");
    if (data && data.length) {
      setSelectedOrders([]);
      setSelectAllOrders(false);
      getOrdersList();
      localStorage.setItem("currentScreen", "overdues_screen");
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (pageNo) {
      getOrdersData(orderIds.slice(+pageNo * 9, +pageNo * 9 + 9));
    }
  }, [pageNo]);

  useEffect(()=>{
     if(ordersData?.length){
      orderSelect({target:{checked:true}}, "", true)
     }
  },[ordersData])

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
          // refresh();
        }}
      />
      <InfoModal
        show={showInfo}
        handleInfoReset={handleInfoReset}
        message={infoMsg}
      />
      {openPayment && (
        <DigitalPaymentModal
          show={openPayment}
          invoiceNumbers={selectedOrders}
          handleDigitalPayment={handleDigitalPayment}
          activePaymentModes={activePaymentModes}
          setInfoMsg={setInfoMsg}
          setShowInfo={setShowInfo}
          setResetPastOrder={setResetPastOrder}
        />
      )}

      <Container fluid className="orders_parent">
        <div className="orders_filter display_flex font_ubuntu">
          <div className="padding_5">
            <HiOutlineArrowNarrowLeft
              className="cursor_pointer goBack"
              onClick={() => navigate(-1)}
              style={{ marginTop: "0.5rem" }}
            />
          </div>

          <div
            className="go_back color_primary font_ubuntu font_weight_md no_wrap  padding_10"
            onClick={() => navigate(-1)}
          >
            Pending Orders (Payment OverDue)
          </div>
        </div>

        <div id="ordersData" className="orders_data_div">
          {homeShimmer ? (
            <>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
              <Container>
                <div className="skeletal_div_sm animateShimmer"></div>
              </Container>
            </>
          ) : (
            ordersData &&
            ordersData.length > 0 && (
              <>
                {selectedOrders?.length > 0 && !onBehalfStatus &&(
                  <div className="text_center bulkPaymentDiv space_btw">
                    <div>
                      <input
                        type="checkbox"
                        className="cart_check"
                        id="payBulkBtnRef"
                        checked={selectAllOrders}
                        onChange={(e) => orderSelect(e, "", true)}
                      />
                      <label for="cart_select_all" className="cursor_pointer">
                        &nbsp;&nbsp;Select All
                      </label>
                      &nbsp;&nbsp; &nbsp;&nbsp;
                      <label>
                        (Total <b>{selectedOrders.length}</b> orders selected)
                      </label>
                    </div>

                    <Button
                    variant="contained"
                      className="bg_primary"
                      onClick={handleDigitalPayment}
                    >
                      Bulk Payment
                    </Button>
                  </div>
                )}
                <InfiniteScroll
                  dataLength={ordersData.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  scrollThreshold="90%"
                  scrollableTarget="ordersData"
                  loader={
                    <Spinner
                      animation="border"
                      className="color_secondary spinner"
                      size="lg"
                    />
                  }
                >
                  {ordersData.map((order, key) => {
                    return (
                      <div className="order_card_outer_div">
                        {order?.onlinePaymentEnabled && !onBehalfStatus && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom-${key}`}>
                                <strong>Select orders for bulk payments</strong>
                              </Tooltip>
                            }
                          >
                            <input
                              type="checkbox"
                              className="cart_check"
                              checked={selectedOrders.includes(
                                order.invoiceNumber
                              )}
                              onChange={(e) =>
                                orderSelect(e, order.invoiceNumber)
                              }
                            />
                          </OverlayTrigger>
                        )}

                        <OrderCard
                          medData={order}
                          refresh={refresh}
                          filter="past"
                          resetOrderData={resetOrderData}
                        />
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </>
            )
          )}

          {ordersData && ordersData.length === 0 && (
            <div className="empty_list_sec">
              <img src={EmptyOrders} alt="Empty Orders" />
              <br />
              <span className="font_ubuntu font_weight_md color_grey">
                There are no orders yet
              </span>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default OverDueOrders;
