export const environment = "PROD";

export let variables, Moengage;

if (environment === "PROD") {
  variables = {
    ADMIN_URL: "https://gatewayprod.saveo.in/api",
    version: "4",
    mixPanelKey: "5c3d71d8969d07eade7a841605f12ebb",
    eagleUrl: "https://eagle.saveo.in/api",
    WHOLESALE_URL: "https://gatewayprod.saveo.in/api",
    appVersionCode: 191,
  };
  Moengage = window.moe({
    // app_id: "3AR8DRLKA6RERFNQ9FN4SPL4",
    debug_logs: 0,
    swPath: "/service-worker.js",
  });
} else {
  variables = {
    ADMIN_URL: "https://gatewaydev.clinn.in/api",
    version: "4",
    mixPanelKey: "5c3d71d8969d07eade7a841605f12ebb",
    eagleUrl: "https://eagledev.clinn.in/api",
    WHOLESALE_URL: "https://gatewaydev.clinn.in/api",
    appVersionCode: 191,
  };
  Moengage = window.moe({
    // app_id: "3AR8DRLKA6RERFNQ9FN4SPL4",
    debug_logs: 1,
    swPath: "/service-worker.js",
  });
}
Object.freeze(variables);
Object.freeze(Moengage);

export const Maps = {
  MAPS_API_KEY: "AIzaSyB4bv_dbewMr5lSjhJyWokBVWWgCvMs0uY",
};

export const updateDetails = {
  title: "what's new 🎉",
  description: "just some random update. nothing important!",
};
