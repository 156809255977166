import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { My_Account } from "../../Actions/MyAccount";
import { usersData } from "../../App";
import { MY_ACCOUNT_IMAGES } from "../../Images/svg";

const Agreements = () => {
  let navigate = useNavigate();
  const [loanTapAgreement, setLoanTapAgreement] = useState();
  const { setErrorHandler } = useContext(usersData);

  const getAgreement = (retcode) => {
    My_Account("LOANTAP_AGREEMENT", { retailerCode: retcode })
      .then((res) => {
        setLoanTapAgreement(res?.data?.data?.agreementLink);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "home_screen",

          errorList: [{ error: error, code: "" }],
          fn: function () {
            getAgreement(retcode);
          },
        });
      });
  };

  useEffect(() => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data) {
      let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");

      if (
        onBehalfRetCode &&
        onBehalfRetCode.length > 0 &&
        onBehalfRetCode !== "undefined" &&
        (data?.admin || data?.salesPerson)
      ) {
        getAgreement(onBehalfRetCode);
      } else {
        getAgreement(data.retailerCode);
      }
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <Container fluid className="bg_profile">
      <Container
        fluid
        className="productListHeader font_ubuntu display_flex profile_header"
      >
        <HiOutlineArrowNarrowLeft
          className="cursor_pointer goBack"
          onClick={() => navigate(-1)}
        />{" "}
        &nbsp;&nbsp;&nbsp;
        <div className="head_specialOffers">Agreements</div>
      </Container>

      <Container className="display_flex content_center">
        <div onClick={() => window.open(loanTapAgreement, "_blank")}>
          <div className="account_item bg_white store_account_item display_flex space_btw">
            <div>
              <span>&nbsp;&nbsp;LoanTap (T&C) &#x2a;</span>
            </div>
            <div className="display_flex store-new-tag-container">
              <img
                className="store_account_icon_md"
                src={MY_ACCOUNT_IMAGES["navigate"]}
                alt={"navigate"}
              />{" "}
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Agreements;
