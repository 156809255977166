import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../../App.css";
import "../HomePage/HomePage.css";
import "../../animate.css";
import Slider from "../Sliders/Slider";
import { common_sale } from "../../Actions/Widgets";

const TrendingProducts = ({ widgetData,renderWidget,widgetIndex }) => {
  const [shimmer, setShimmer] = useState(false);
  const [productsData, setProductsData] = useState(null);
  const  [page,setPage]=useState(0)

const fetchMoreData=()=>{
      setPage(page=>page+1)
}

  const getData = async () => {
    //update the shimmer state
    let api=widgetData?.data?.apiEndPoint
    let includedParams = new URLSearchParams(widgetData?.data?.apiEndPoint);
    if(includedParams.get('page') && page>0){
       api=widgetData?.data?.apiEndPoint.replace(`page=0`,`page=${page}`)
       console.log(page,api)
    }

   page===0 && setShimmer(true);
    try {
      const res = await common_sale(api);
      //update the products state
      let data=productsData?{...productsData}:null
      if(data?.medicinesResponsesList?.length){
           data.medicinesResponsesList.push(...res?.data?.data?.medicinesResponsesList)
           data.size=res?.data?.data?.size
      }else{
        data={...res?.data?.data}
      }

      setProductsData(data)

      //update the shimmer state
      renderWidget(widgetData,widgetIndex,false,null)
      setShimmer(false);

      if(includedParams.get('page') && data?.medicinesResponsesList.length<8 && data?.size>0 && page<6){
        fetchMoreData()
       }
    } catch (error) {
      //update the shimmer state  if any error
      setShimmer(false);
      renderWidget(widgetData,widgetIndex,true,error)
      // renderErrorWidget(widgetIndex)
      // console.log(error);
      // if (
      //   error?.response?.status == 502 ||
      //   error?.response?.status == 503 ||
      //   error?.response?.status == 504
      // ) {
      //   setShowMaintenance({
      //     status: true,
      //     apiData: error?.response,
      //   });
      // } else if (error?.response?.status == 500) {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: "Oops something went wrong !",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // } else if (error?.response?.status == 400) {
      //   setOrderMessage({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data?.error?.error,
      //     header: `Info`,
      //   });
      // } else {
      //   setErrorModal({
      //     show: true,
      //     apiData: error?.response,
      //     content: error?.response?.data.error?.error
      //       ? error.response.data.error.error
      //       : "Something went wrong!",
      //     tryAgain: function () {
      //       getData();
      //     },
      //   });
      // }
    }
  };

  useEffect(() => {
    //hit the api to fetch the products
    if (widgetData && !productsData) getData();
  }, [widgetData,widgetData.renderStatus]);

  useEffect(()=>{
    page>0 && getData()
  },[page])

  return (
    widgetData &&
    productsData?.medicinesResponsesList?.length > 0 && (
      <Container
        fluid
        className="commonSlider"
        style={{ backgroundColor: widgetData?.data?.backgroundColor }}
      >
        <div
          className=" font_weight_md product_name "
          style={{ color: widgetData?.data?.titleColor, marginLeft: "1%" }}
        >
          {widgetData?.data?.title}
        </div>

        <Slider
          shimmer={shimmer}
          screen={"home_screen"}
          widgetData={widgetData}
          ProductRes={productsData}
          footerColor={"#50409700"}
          id={widgetData?.data?.title}
          darkColor={widgetData?.data?.titleColor}
          lightColor={widgetData?.data?.titleColor}
          flagBg={"#FFD600"}
        />
      </Container>
    )
  );
};

export default React.memo(TrendingProducts);
