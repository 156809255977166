import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { updateCreditUserStatus } from "../../Actions/ApplyCredit";
import { refreshToken } from "../../Actions/Auth";
import ButtonLoading1 from "../Spinners/ButtonLoading1";
import "./ApplyCreditAgreementModal.css";
import { usersData } from "../../App";
import ErrorModal from "./ErrorModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";

function AcceptCreditAgreementModal({ creditUserData, ...props }) {
  const [publicIp, setPublicIp] = useState("");
  const [showSpinner, setshowSpinner] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const { userData, setShowMaintenance } = useContext(usersData);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const getPublicIp = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      //   console.log(res?.data?.IPv4);
      setPublicIp(res?.data?.IPv4);
    } catch (error) {
      console.error(error);
    }
  };

  //webRTC to get private client IP //DEPRECATED
  //   const getClientIpAddress = async () => {
  //     window.RTCPeerConnection =
  //       window.RTCPeerConnection ||
  //       window.mozRTCPeerConnection ||
  //       window.webkitRTCPeerConnection; //compatibility for Firefox and chrome
  //     var pc = new RTCPeerConnection({ iceServers: [] }),
  //       noop = function () {};
  //     pc.createDataChannel(""); //create a bogus data channel
  //     pc.createOffer(pc.setLocalDescription.bind(pc), noop); // create offer and set local description
  //     pc.onicecandidate = function (ice) {
  //       if (ice && ice.candidate && ice.candidate.candidate) {
  //         var myIP =
  //           /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(
  //             ice.candidate.candidate
  //           )[1];
  //         console.log("my IP: ", myIP);
  //         pc.onicecandidate = noop;
  //       }
  //     };
  //   };

  const callApiEvents = (eventName) => {
    let props = {
      eventName: eventName,
    };
    if (eventName === "agreement_accept_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    } else if (eventName === "agreement_cross_tap") {
      Common_Event_Hit("DYNAMIC_EVENT", props);
    }
  };

  //   get user lat lng
  const getLocation = () => {
    //get the coords from the user and update the respective state
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  //handler to close the modal
  const handleModalClose = () => {
    // call the close event tap
    callApiEvents("agreement_cross_tap");
    //close the modal
    props.onHide();
  };

  const handleAcceptAgreement = () => {
    //call the agreement accept tap when the button is clicked
    callApiEvents("agreement_accept_tap");
    //set spinner state to active
    setshowSpinner(true);
    //make the API call to accept agreement
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);

    let params = {
      retailerId: data?.id,
      desired: "AGREEMENT_ACCEPTED",
    };

    let headers = {
      ipAddress: publicIp,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      locale: "en",
    };

    updateCreditUserStatus(params, headers, {})
      .then((res) => {
        console.log(res);
        setshowSpinner(false);
      })
      .catch((error) => {
        console.log(error.response);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            handleAcceptAgreement();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance(true);
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            content: "Oops something went wrong!",
            tryAgain: function () {
              handleAcceptAgreement();
            },
          });
        } else {
          setErrorModal({
            show: true,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              handleAcceptAgreement();
            },
          });
        }
      });

    //hide the modal at the end
    props.onHide();
  };

  //onLoad get public IP of the user
  useEffect(() => {
    getPublicIp();
    getLocation();
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        onHide={() => {
          setErrorModal({
            show: false,
            content: "",
          });
        }}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
      />
      {creditUserData?.showPopUp && (
        <Modal
          centered
          show={props.show}
          className="agreement-modal"
          contentClassName="agreement-modal-content"
        >
          <Modal.Body>
            <div className="close-modal">
              <AiOutlineClose
                size="1.5rem"
                style={{ cursor: "pointer" }}
                onClick={handleModalClose}
              />
            </div>
            <div className="modal-body-info">
              <h5>{creditUserData?.message}</h5>
              <p>{creditUserData?.note}</p>
            </div>
          </Modal.Body>
          {creditUserData?.nextStatus && (
            <Modal.Footer className="agreement-modal-footer">
              <p>
                By tapping "I accept", you will be accepting &nbsp;
                <span>
                  <a href="RetailerAgreement.html" target="_blank">
                    agreement
                  </a>
                </span>{" "}
              </p>
              <button onClick={handleAcceptAgreement}>
                {showSpinner ? <ButtonLoading1 /> : "I, accept"}
              </button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
}

export default AcceptCreditAgreementModal;
