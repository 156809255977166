import axios from "axios";
import qs from "qs";
import { variables } from "../../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const BASE = variables.WHOLESALE_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = JSON.parse(localStorage.getItem("userData"));
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode != "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="wholesaleWebOnbehalf"
  }
  return true;
};

export const getWSProductPrice = async (params) => {
  const data = getToken().then(() => {
    config.headers.appVersionCode = 160;
    return axios.get(`${BASE}/wholesale/home/fetchProductPrice`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};
