import axios from "axios";
import qs from "qs";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
     "source":"pharmacyWeb",
     appVersionCode: variables.appVersionCode,
  },
};
const BASE = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="pharmacyWebOnbehalf"
  }
  return true;
};


export const getOnboardingData = async (type, params) => {
  const data = getToken().then(() => {
    switch (type) {
      case "getUserData":
        return axios.get(`${BASE}/onboarding/getUserObject`, {
          params: params,
          headers: config.headers,
        });

      case "getBusinessType":
        return axios.get(`${BASE}/onboarding/businessType`, {
          params: params,
          headers: config.headers,
        });
      
      case 'getUserDocument': 
        return axios.post(`${BASE}/onboarding/getUserDocument`, {},{
          params: params,
          headers: config.headers,
        });
    }
  });
  return data;
};

export const setOnboardingData = async (type, params, body) => {
  const sample = getToken().then(() => {
    switch (type) {
      case "setPincode":
        return axios.post(`${BASE}/onboarding/setPincode`, body, {
          params: params,
          headers: config.headers,
        });
      case "setBusinessType":
        return axios.post(`${BASE}/onboarding/setBusinessType`, body, {
          params: params,
          headers: config.headers,
        });
      case "setBusinessDetails":
        return axios.post(`${BASE}/onboarding/setUserStep1`, body, {
          params: params,
          headers: config.headers,
        });
      case "setStoreLocation":
        return axios.post(`${BASE}/onboarding/setUserStep2`, body, {
          params: params,
          headers: config.headers,
        });
      case "setBusinessDocuments":
        return axios.post(`${BASE}/onboarding/setUserStep3`, body, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          headers: config.headers,
        });

      case "uploadsSupportImage":
        return axios.post(`${BASE}/home/uploadsSupportImage`, body, {
          params: params,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: config.headers.Authorization,
          },
        });
      case "addUserCredentials":
        return axios.post(`${BASE}/authenticate/addUserCredentials`, body, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return sample;
};
