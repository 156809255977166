import React from "react";
import "./VoiceAssistance.css";
import { Button } from "@mui/material";
import SuccessLogo from "../../../Images/json/va_setupmodal_anime.json"
import Lottie from "react-lottie-player";

const VASetupModal = (props) => {
  return (
    <>
      {props.show && (
          <div className="va_setup_modal">
            <div>
            <Lottie
                className="capsi_wave"
                loop={false}
                animationData={SuccessLogo}
                play
                style={{width:'150px'}}
              />
            </div>
            <div className="">
              <b className="font_14">
                 {props.data.header1}
              </b>
              <p className="font_12 color_grey">
              {props.data.subHeading}
              </p>
            </div>

            <div className="">
               <Button onClick={props.startUsing} variant="contained" className="bg_primary" style={{width:'200px',borderRadius:'10px'}}>{props.data.btnText}</Button>
            </div>
          </div>
      )}
    </>
  );
};

export default VASetupModal;
