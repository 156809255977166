import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    source: "pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const ADMIN_URL = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;
  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source = "pharmacyWebOnbehalf";
  }
  return true;
};

export const getGamificationData = async (type, params, body) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "SHOW_CAMPAIGNS":
        return axios.get(`${ADMIN_URL}/home/showCampaigns`, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return widgets;
};
