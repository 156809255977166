import axios from "axios";
import { variables } from "../environments";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const BASE = variables.ADMIN_URL;

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;

  if (
    onBehalfRetCode &&
    onBehalfRetCode.length > 0 &&
    onBehalfRetCode !== "undefined" &&
    (userData.admin || userData.salesPerson)
  ) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source="pharmacyWebOnbehalf"
  }
  return true;
};

export const getExpiryData = (retailerId) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/expiry/retailer/viewCart/${retailerId}`, {
      // params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const getMedicineSuggestions = (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/expiry/retailer/getMedicineSuggestion`, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const addExpiryItem = (params, body) => {
  const data = getToken().then(() => {
    return axios.post(`${BASE}/expiry/retailer/cart`, body, {
      headers: config.headers,
    });
  });
  return data;
};

export const placeOrder = async (params) => {
  const data = getToken().then(() => {
    return axios.post(`${BASE}/expiry/retailer/placeOrder`, {}, {
      params: params,
      headers: config.headers,
    });
  });
  return data;
};

export const getLiveOrders = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/expiry/retailer/viewLiveOrders`, {
      params: params,
      headers: config.headers,
    })
  })
  return data;
}

export const getPastOrders = async (params) => {
  const data = getToken().then(() => {
    return axios.get(`${BASE}/expiry/retailer/viewPastOrders`, {
      params : params,
      headers : config.headers,
    })
  })
  return data;
}