import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./CartToolBar.css";
import { AiFillCloseSquare } from "react-icons/ai";
import DeleteProductModal from "../Modals/DeleteProductModal";
import { Common_Event_Hit } from "../../Actions/UserEvents";
import { Cart_Actions } from "../../Actions/Cart";
import { cartDataContext, usersData } from "../../App";
import { refreshToken } from "../../Actions/Auth";
import { Spinner } from "react-bootstrap";
import ProductCard6 from "../ProductCard/ProductCard6";

const CartToolBar = ({ setCartExist, medId }) => {
  let navigate = useNavigate();
  const { cartData, setCartData } = useContext(cartDataContext);
  const { setShowMaintenance } = useContext(usersData);
  const [cartOpen, setCartOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showClearDeleteModal, setShowClearDeleteModal] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [showSpinner, setShowSpinner] = useState(false);
  const [showClearCart, setShowClearCart] = useState(false);
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const [outOfStock, setOutOfStock] = useState();
  const [shipments, setShipments] = useState([]);
  const [shipmentsValueData, setShipmentsValueData] = useState();
  const [deleteItem, setDeleteItem] = useState(null);

  const removeFromCart = (item) => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "delete_from_cart",
      medicineId: item.medicineId,
    });
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setShowDeleteModal(false);
    const params = {};
    const body = {
      androidMap: {},
      medicineId: item.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };

    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setCartData(res.data.data);
        if (res?.data?.data?.cartMedicineDetailResponseList.length === 0) {
          setShowClearCart(false);
        } else {
          if (+medId === deleteItem.medicineId) {
            setCartExist(false);
          }
          setDeleteItem(null);
          delete deleteItem.spinner;
        }
      })
      .catch((error) => {
        setShowSpinner(false);

        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            removeFromCart(item);
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart(item);
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            tryAgain: function () {
              removeFromCart(item);
            },
          });
        }
      });
  };

  // Fetch Cart
  const cartView = () => {
    setShowSpinner(true);
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    const params = {};
    const body = {
      androidMap: {
        locale: "en",
        source: "pharmacyWeb",
        deviceId: null,
        appVersion: navigator.userAgent,
        deviceName: null,
        screen_name: "cart_screen",
        appVersionCode: "156",
      },
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };
    Cart_Actions("VIEW_CART", params, body)
      .then(async (res) => {
        setCartData(res.data.data);
        let products = res.data.data.cartMedicineDetailResponseList;
        products && products.length > 0
          ? sortShipments(products)
          : setShowSpinner(false);
        let medNames = await products.map((item) => {
          return item.medicineName;
        });

        let isItemExist = products.some((ele) => {
          return ele.medicineId === +medId;
        });
        if (isItemExist) {
          setCartExist(true);
        }
        setShowSpinner(false);

        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "cart_opened",
          number_of_products: res.data.data.size,
          medicine_names: medNames,
          price: res.data.data.subTotal,
        });
      })
      .catch((error) => {
        setShowSpinner(false);
        if (error?.response?.status === 401) {
          refreshToken().then((res) => {
            cartView();
          });
        } else if (
          error?.response?.status === 502 ||
          error?.response?.status === 503 ||
          error?.response?.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error?.response?.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              cartView();
            },
          });
        } else if (error.response.status === 400) {
          setOrderMessage({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error,
            header: `Info`,
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            tryAgain: function () {
              cartView();
            },
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
          });
        }
      });
  };

  const sortShipments = (products) => {
    let groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let groupByAvailableSlots = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[JSON.stringify(x[key])] = rv[JSON.stringify(x[key])] || []).push(x);
        return rv;
      }, {});
    };

    let groupBySlots = groupBy(products, "selectedSlotName");
    let arr = Object.keys(groupBySlots).filter((slot) => slot !== "null");
    setOutOfStock(groupBySlots[null]);
    let shipmentList = arr.map((key) => {
      let groupByAvailable = groupByAvailableSlots(
        groupBySlots[key],
        "availableSlots"
      );
      return groupByAvailable;
    });
    setShipments(shipmentList);
    getShipmentsValuesData(shipmentList);
  };

  const getShipmentsValuesData = (list) => {
    let arr = [];
    list.forEach((shipment) => {
      let invoiceVal = 0;
      let length = 0;
      Object.values(shipment).forEach((subShip) => {
        subShip.forEach((items) => {
          length = length + 1;
          invoiceVal = invoiceVal + items.actualPrice;
        });
      });
      arr.push({ invoiceVal, totalItems: length });
    });
    setShipmentsValueData(arr);
    setShowSpinner(false);
  };

  // Cancel Delete
  const cancelDelete = () => {
    const indx = cartData?.cartMedicineDetailResponseList.findIndex(
      (ele) => ele.medicineId === deleteItem.medicineId
    );
    delete cartData.cartMedicineDetailResponseList[indx].spinner;
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  // Clear Cart
  const clearCart = () => {
    setShowClearDeleteModal(false);
    setShowClearCart(true);
    cartData?.cartMedicineDetailResponseList?.forEach((element) => {
      removeFromCart(element);
    });
  };

  useEffect(() => {
    if (!cartData) {
      cartView();
    } else {
      setShowSpinner(true);
      let products = cartData.cartMedicineDetailResponseList;
      products && products.length > 0
        ? sortShipments(products)
        : setShowSpinner(false);
    }
  }, [cartData]);

  useEffect(() => {}, [shipments]);
  return (
    <>
      {showSpinner && !showClearCart ? (
        <>
          <div className="skeletal_div animateShimmer"></div>
        </>
      ) : (
        <div>
          <DeleteProductModal
            show={showDeleteModal}
            itemName={deleteItem?.medicineName}
            removeFromCart={() => removeFromCart(deleteItem)}
            onHide={cancelDelete}
          />

          <DeleteProductModal
            show={showClearDeleteModal}
            itemName="all items"
            removeFromCart={() => clearCart()}
            onHide={() => setShowClearDeleteModal(false)}
          />

          <div className="r_cart_top p_8">
            <div>
              <h6>
                Your Cart{" "}
                <span>
                  ({cartData?.size}{" "}
                  {cartData?.size > 1 ? "Products" : "Product"})
                </span>
              </h6>

              {cartOpen && (
                <AiFillCloseSquare onClick={() => setCartOpen(!cartOpen)} />
              )}
            </div>

            <p>
              {cartData?.size < 1 ? 0 : shipments?.length}{" "}
              {cartData?.size > 1 ? "Shipments" : "Shipment"}, ₹{" "}
              {cartData?.subTotal}
              {showClearCart ? (
                <span>
                  <Spinner
                    animation="border"
                    className="color_secondary spinner"
                    size="sm"
                    style={{ marginLeft: "-2px" }}
                  />
                </span>
              ) : (
                <span onClick={() => setShowClearDeleteModal(true)}>
                  Clear Cart
                </span>
              )}
            </p>
          </div>

          {/* Cart Cards */}
          {cartOpen && (
            <div className="r_cart_mid p_8">
              {cartData?.cartMedicineDetailResponseList?.map(
                (cartMed, indx) => {
                  return (
                    <ProductCard6
                      cartMed={cartMed}
                      key={indx + "_r_card"}
                      userData={JSON.parse(localStorage.getItem("userData"))}
                    />
                  );
                }
              )}
            </div>
          )}

          {!cartOpen ? (
            <div
              className="r_cart_bot p_8"
              onClick={() => setCartOpen(!cartOpen)}
            >
              <h6>VIEW MORE</h6>

              <p>
                ₹ {cartData?.subTotal}{" "}
                <span>
                  ({cartData?.size} {cartData?.size > 1 ? "ITEMS" : "ITEM"})
                </span>
              </p>
            </div>
          ) : (
            <div className="r_cart_bot p_8" onClick={() => navigate("/cart")}>
              <h6>CONTINUE</h6>

              <p>
                ₹ {cartData?.subTotal}{" "}
                <span>
                  ({cartData?.size} {cartData?.size > 1 ? "ITEMS" : "ITEM"})
                </span>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CartToolBar;
