import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'

const DeleteData = () => {
    const [showMessage,setShowMessage]=useState(false)

    const submit=()=>{
        setShowMessage(true)
    }
  return (
    <Container className='deleteData_cont'>
        <div>
            <br/>
            <br/>
             <h5>Request to delete data, Please enter your registered mobile number</h5>
             <br/>
             {
                showMessage?"Request Submitted Successfully": <> <input type="number" className='padding_5' maxLength={10}/> &nbsp;&nbsp; <Button onClick={submit}>Submit</Button></>
             }
            
        </div>
    </Container>
  )
}

export default DeleteData